import React from 'react';
import { Filter, TextInput, DataProviderContext } from 'react-admin';
import { resourceToWord } from '~/common/utils/resource';
import { DataProvider } from '~/Admin/data';

const filterFields = (field: any) => {
  if (field.type === 'boolean') return false;
  if (field.referenceTable) return false;
  if (field.format) {
    if (field.format.includes('timestamp')) return false;
    if (field.format === 'jsonb') return false;
    if (field.format === 'real') return false;
  }
  if (field.name === 'search') return false;
  return true;
};

const ListFilter = ({ resource, ...otherProps }: any) => {
  const dataProvider: DataProvider = React.useContext(DataProviderContext);
  const spec = dataProvider.getSpec(resource);
  const Resource = spec[resource];
  const fields: string[] = Resource.fields;

  return (
    <Filter resource={resource} {...otherProps}>
      {fields
        .filter(field => filterFields(field))
        .map((field: any) => (
          <TextInput
            key={field.name}
            label={resourceToWord({ resource: field.name })}
            source={field.name}
            defaultValue=""
          />
        ))}
    </Filter>
  );
};

export default ListFilter;
