// @ts-nocheck
import DataProvider from './DataProvider';

export default class RealtimeDataProvider extends DataProvider {
  constructor(config) {
    super(config);
    this.subscriptions = {};
  }

  isRT(resource: string) {
    return true;
  }
}
