import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import { parse as convertFromCSV } from 'papaparse';
import { useCreate } from 'react-admin';
import set from 'lodash.set';
import AuthContext from '~/Admin/keycloak/AuthContext';
import { SPEC_URL } from '~/common/constants';
import { sleep } from '~/common/utils/commonUtils';

interface HandleCSVInputType {
  data: any[];
  createResource: any;
  auth: any;
  addSnackBar: any;
  onComplete: any;
}

const DismissButton = styled(Button)`
  color: white;
`;

let failedImports: any[] = [];
let skippedImports: any[] = [];
let successfulImports: any[] = [];

const CSVToObjects = (data: any, keyMapper: any = null) => {
  const keys: string[] = data[0];
  const values = data.slice(1).map((row: any) => {
    const value: any = {};

    keys.forEach((key: string, index: number) => {
      if (keyMapper) {
        const [mapperKey, mapperValue] = keyMapper(key, index, value, row);
        if (mapperKey) {
          value[mapperKey] = mapperValue;
        }
      } else {
        value[key] = row[index];
      }
    });

    return value;
  });

  return values;
};

const handleCSV = async ({ data, createResource, addSnackBar, onComplete }: HandleCSVInputType) => {
  addSnackBar({ message: `Importing ${data.length - 1} resources` });
  const values = CSVToObjects(data);

  // loop over each entry in the CSV and create it.
  for (const value of values) {
    await new Promise((resolve: any) => {
      createResource(
        {
          payload: {
            data: value,
          },
        },
        {
          onSuccess: ({ data }: any) => {
            successfulImports.push(data.id);
            resolve();
          },
          onFailure: (err: any) => {
            addSnackBar({ message: `Creating resource failed: ${err.message}`, variant: 'error', persist: true });
            resolve();
          },
        }
      );
    });
    await sleep(100);
  }
  onComplete();
};

const handleSiteCSV = async ({ data, createResource, auth, addSnackBar, onComplete }: HandleCSVInputType) => {
  addSnackBar({ message: `Importing ${data.length - 1} sites` });
  const addressKeys = [
    'country',
    'address_line1',
    'address_line2',
    'city',
    'province',
    'postal_code',
    'time_zone',
    'longitude',
    'latitude',
  ];
  const siteKeys: string[] = data[0].filter((key: string) => !addressKeys.includes(key));

  const siteKeysMapper = (key: string, index: number, _value: any, row: any[]) => {
    if (!siteKeys.includes(key)) return [null, null];
    return [key, row[index]];
  };
  const addressMapper = (key: string, index: number, _value: any, row: any[]) => {
    if (!addressKeys.includes(key)) return [null, null];
    return [key, row[index]];
  };

  const siteValues = CSVToObjects(data, siteKeysMapper);
  const addressValues = CSVToObjects(data, addressMapper);

  let siteValueIndex = 0;
  for (const value of siteValues) {
    await new Promise((resolve: any) => {
      createResource(
        {
          payload: {
            data: {
              ...value,
              active: true,
              created_by: auth.username,
              modified_by: auth.username,
              address_line_1: addressValues[siteValueIndex].address_line1,
              city: addressValues[siteValueIndex].city,
              province_or_state: addressValues[siteValueIndex].province_or_state,
              postal_code: addressValues[siteValueIndex].postal_code_zip_code,
            },
          },
        },
        {
          onSuccess: async ({ data }: any) => {
            successfulImports.push(data.id);
            resolve();
          },
          onFailure: (err: any) => {
            console.error('site create Failed: ', err);
            if (err.message.includes('duplicate key value')) {
              skippedImports.push(value.uid);
              addSnackBar({
                message: `${value.uid} Skipped duplicate: ${err.message}`,
                variant: 'warning',
                key: value.uid,
              });
            } else {
              addSnackBar({
                message: `${value.uid} Failed: ${err.message}`,
                variant: 'error',
                key: value.uid,
                persist: true,
              });
            }
            resolve();
          },
        }
      );
    });
    siteValueIndex++;
    await sleep(10);
  }
  onComplete();
};

const handleProbeCSV = async ({ data, createResource, auth, addSnackBar, onComplete }: HandleCSVInputType) => {
  addSnackBar({ message: `Importing ${data.length - 1} probes` });
  const mapProbeKeysToConfig = (key: string, index: number, value: any, row: any[]) => {
    if (!key.includes('.')) {
      return [key, row[index]];
    }
    const dotNotations = key.split('.');
    let object = value[dotNotations[0]] || {};

    set(object, key.split('.').slice(1).join('.'), row[index]);

    return [dotNotations[0], object];
  };

  const probeValues = CSVToObjects(data, mapProbeKeysToConfig);

  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${auth.authToken}`,
    Prefer: 'return=representation',
  });

  for (const value of probeValues) {
    let sites: any = await fetch(`${SPEC_URL}/site?uid=eq.${value.site_uid}`, {
      headers,
    });

    // TODO handle site response status code.
    sites = await sites.json();
    if (!sites.length) {
      addSnackBar({
        message: `Cannot create probe: Site not found for ${value.site_uid}`,
        variant: 'error',
        key: value.site_uid,
        persist: true,
      });
    } else {
      const site_id = sites[0].id;
      delete value.site_uid;

      await new Promise((resolve: any) => {
        createResource(
          {
            payload: {
              data: {
                ...value,
                site_id,
              },
            },
          },
          {
            onSuccess: ({ data }: any) => {
              successfulImports.push(data.id);
              resolve();
            },
            onFailure: (err: any) => {
              console.info(`Create probe for site ${site_id} failed:`, err);
              addSnackBar({
                message: `Create probe for site ${site_id} failed: ${err}`,
                variant: 'error',
                key: site_id,
              });
              resolve();
            },
          }
        );
      });
    }

    await sleep(100);
  }
  onComplete();
};

const ImportButton = ({ resource }: any) => {
  const [createResource] = useCreate(resource);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const SnackBarAction = (key: string) => <DismissButton onClick={() => closeSnackbar(key)}>DISMISS</DismissButton>;
  const addSnackBar = ({ message, failure, variant, key, ...options }: any) => {
    if (variant === 'error' && failure !== false) {
      failedImports.push(key);
    }
    enqueueSnackbar(message, { key, variant, autoHideDuration: 500, ...options, action: SnackBarAction });
  };

  const onComplete = async () => {
    await sleep(100);
    addSnackBar({
      message: `Import finished`,
      persist: true,
    });
    if (successfulImports.length) {
      console.info('successful imports: ', successfulImports);
      addSnackBar({
        message: `Successfully created ${successfulImports.length} resources`,
        variant: 'success',
        persist: true,
      });
    }
    if (skippedImports.length) {
      console.info('skipped imports: ', skippedImports);
      addSnackBar({
        message: `Skipped ${skippedImports.length} resources`,
        variant: 'warning',
        persist: true,
      });
    }
    if (failedImports.length) {
      console.info('failed imports: ', failedImports);
      addSnackBar({
        message: `Failed to create ${failedImports.length} resources`,
        failure: false,
        variant: 'error',
        persist: true,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, auth: any) => {
    failedImports = [];
    skippedImports = [];
    successfulImports = [];

    const file = e.target.files && e.target.files[0];

    if (file) {
      convertFromCSV(file, {
        delimiter: ',',
        complete: ({ data }: any) => {
          const params = { data, createResource, auth, addSnackBar, onComplete };
          if (resource === 'site') {
            handleSiteCSV(params);
          } else if (resource === 'probe') {
            handleProbeCSV(params);
          } else {
            handleCSV(params);
          }
        },
      });
    }
  };

  return (
    <AuthContext.Consumer>
      {(auth: any) => (
        <>
          <input
            type="file"
            id="import-csv"
            style={{ display: 'none' }}
            accept=".csv"
            onChange={(e) => handleChange(e, auth)}
          />
        </>
      )}
    </AuthContext.Consumer>
  );
};

export default ImportButton;
