// @ts-nocheck
import React from 'react';
import { NumberInput } from 'react-admin';
import styled from 'styled-components';
import { useField } from 'react-final-form';

const StyledNumberInput = styled(NumberInput)`
    .MuiInputLabel-filled.MuiInputLabel-marginDense {
      font-size: 12px;
    }
    &::after {
        position: absolute;
        content: "${(props: any) => props.suffix}";
        color: #79849C;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
        font-size: 9px;
    }
`;

interface ByteInputPropsType {
  id?: string;
  label?: string;
  min?: number;
  max?: number;
  name: string;
  suffix?: string;
  source?: string;
}

const ByteInput = ({
  id,
  name,
  label,
  min = 0,
  max = 9999999999,
  suffix = 'Megabit(mbps)',
  source,
}: ByteInputPropsType) => {
  const {
    input,
    meta: { touched, error },
  } = useField(name);

  const handleChange = (event: any) => {
    const value = event.target.value;
    // if input is MB and output is in bytes
    // TODO: should use parse/format for this
    const valueInBytes = value * 1000000;
    input.onChange(valueInBytes);
  };

  return (
    <StyledNumberInput
      {...input}
      id={id}
      min={min}
      max={max}
      label={label || source}
      onChange={handleChange}
      value={input.value / 1000000}
      suffix={suffix}
      error={!!(touched && error)}
      helperText={touched && error}
    />
  );
};

export default ByteInput;
