const timezone = {
    'America/Adak': {
        master_timezone: 'America/Adak',
        UTC_Offset: -10,
        UTC_DST_Offset: -9
    },
    'America/Anchorage': {
        master_timezone: 'America/Anchorage',
        UTC_Offset: -9,
        UTC_DST_Offset: -8
    },
    'America/Juneau':{
        master_timezone: 'America/Anchorage',
        UTC_Offset: -9,
        UTC_DST_Offset: -8
    },
    'America/Metlakatla':{
        master_timezone: 'America/Anchorage',
        UTC_Offset: -9,
        UTC_DST_Offset: -8
    },
    'America/Nome':{
        master_timezone: 'America/Anchorage',
        UTC_Offset: -9,
        UTC_DST_Offset: -8
    },
    'America/Sitka':{
        master_timezone: 'America/Anchorage',
        UTC_Offset: -9,
        UTC_DST_Offset: -8
    },
    'America/Yakutat':{
        master_timezone: 'America/Anchorage',
        UTC_Offset: -9,
        UTC_DST_Offset: -8
    },
    'America/Los_Angeles': {
        master_timezone: 'America/Vancouver',
        UTC_Offset: -8,
        UTC_DST_Offset: -7
    },
    'America/Tijuana': {
        master_timezone: 'America/Vancouver',
        UTC_Offset: -8,
        UTC_DST_Offset: -7
    },
    'America/Vancouver':{
        master_timezone: 'America/Vancouver',
        UTC_Offset: -8,
        UTC_DST_Offset: -7
    },
    'America/Creston': {
        master_timezone: 'America/Phoenix',
        UTC_Offset: -7,
        UTC_DST_Offset: -7
    },
    'America/Dawson':{
        master_timezone: 'America/Phoenix',
        UTC_Offset: -7,
        UTC_DST_Offset: -7
    },
    'America/Dawson_Creek':{
        master_timezone: 'America/Phoenix',
        UTC_Offset: -7,
        UTC_DST_Offset: -7
    },
    'America/Fort_Nelson':{
        master_timezone: 'America/Phoenix',
        UTC_Offset: -7,
        UTC_DST_Offset: -7
    },
    'America/Hermosillo':{
        master_timezone: 'America/Phoenix',
        UTC_Offset: -7,
        UTC_DST_Offset: -7
    },
    'America/Phoenix':{
        master_timezone: 'America/Phoenix',
        UTC_Offset: -7,
        UTC_DST_Offset: -7
    },
    'America/Whitehorse':{
        master_timezone: 'America/Phoenix',
        UTC_Offset: -7,
        UTC_DST_Offset: -7
    },
    
    'America/Boise': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Cambridge_Bay': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Chihuahua': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Denver': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Edmonton': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Inuvik': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Mazatlan': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Ojinaga': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Yellowknife': {
        master_timezone: 'America/Edmonton',
        UTC_Offset: -7,
        UTC_DST_Offset: -6
    },
    'America/Belize': {
        master_timezone: 'America/Costa_Rica',
        UTC_Offset: -6,
        UTC_DST_Offset: -6
    },
    'America/Costa_Rica': {
        master_timezone: 'America/Costa_Rica',
        UTC_Offset: -6,
        UTC_DST_Offset: -6
    },
    'America/El_Salvador': {
        master_timezone: 'America/Costa_Rica',
        UTC_Offset: -6,
        UTC_DST_Offset: -6
    },
    'America/Guatemala': {
        master_timezone: 'America/Costa_Rica',
        UTC_Offset: -6,
        UTC_DST_Offset: -6
    },
    'America/Managua': {
        master_timezone: 'America/Costa_Rica',
        UTC_Offset: -6,
        UTC_DST_Offset: -6
    },
    'America/Regina': {
        master_timezone: 'America/Costa_Rica',
        UTC_Offset: -6,
        UTC_DST_Offset: -6
    },
    'America/Swift_Current': {
        master_timezone: 'America/Costa_Rica',
        UTC_Offset: -6,
        UTC_DST_Offset: -6
    },
    'America/Tegucigalpa': {
        master_timezone: 'America/Costa_Rica',
        UTC_Offset: -6,
        UTC_DST_Offset: -6
    },
    'America/Bahia_Banderas': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Chicago': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Indiana/Knox': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Indiana/Tell_City': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Matamoros': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Menominee': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Merida': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Mexico_City': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Monterrey': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/North_Dakota/Beulah': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/North_Dakota/Center': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/North_Dakota/New_Salem': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Rainy_River': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Rankin_Inlet': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Resolute': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Winnipeg': {
        master_timezone: 'America/Chicago',
        UTC_Offset: -6,
        UTC_DST_Offset: -5
    },
    'America/Atikokan': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Boa_Vista': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Bogota': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Cancun': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Cayman': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Eirunepe': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Guayaquil': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Jamaica': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Lima': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Panama': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Rio_Branco': {
        master_timezone: 'America/Panama',
        UTC_Offset: -5,
        UTC_DST_Offset: -5
    },
    'America/Detroit': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Grand_Turk': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Havana': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Indiana/Indianapolis': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Indiana/Marengo': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Indiana/Petersburg': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Indiana/Vevay': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Indiana/Vincennes': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'Ameirca/Indiana/Winamac': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Iqaluit': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Kentucky/Louisville': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Kentucky/Monticello': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Nassau': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/New_York': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Nipigon': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Pangnirtung': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Port-au-Prince': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Thunder_Bay': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Toronto': {
        master_timezone: 'America/Toronto',
        UTC_Offset: -5,
        UTC_DST_Offset: -4
    }, 
    'America/Port_of_Spain': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Antigua': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Aruba': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Curacao': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Barbados': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Blanc-Sablon': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Campo_Grande': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Caracas': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Cuiaba': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Dominica': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Grenada': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Guadeloupe': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Guyana': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Kralendijk': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/La_Paz': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Lower_Princes': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Manaus': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Marigot': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Martinique': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Montserrat': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Porto_Velho': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Puerto_Rico': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Santo_Domingo': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/St_Barthelemy': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/St_Kitts': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/St_Lucia': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/St_Thomas': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/St_Vincent': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Tortola': {
        master_timezone: 'America/Aruba',
        UTC_Offset: -4,
        UTC_DST_Offset: -4
    },
    'America/Asuncion': {
        master_timezone: 'America/Halifax',
        UTC_Offset: -4,
        UTC_DST_Offset: -3
    },
    'America/Glace_Bay': {
        master_timezone: 'America/Halifax',
        UTC_Offset: -4,
        UTC_DST_Offset: -3
    },
    'America/Goose_Bay': {
        master_timezone: 'America/Halifax',
        UTC_Offset: -4,
        UTC_DST_Offset: -3
    },
    'America/Halifax': {
        master_timezone: 'America/Halifax',
        UTC_Offset: -4,
        UTC_DST_Offset: -3
    },
    'America/Moncton': {
        master_timezone: 'America/Halifax',
        UTC_Offset: -4,
        UTC_DST_Offset: -3
    },
    'America/Santiago': {
        master_timezone: 'America/Halifax',
        UTC_Offset: -4,
        UTC_DST_Offset: -3
    },
    'America/Thule': {
        master_timezone: 'America/Halifax',
        UTC_Offset: -4,
        UTC_DST_Offset: -3
    },
    'America/St_Johns': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3.5,
        UTC_DST_Offset: -2.5
    },
    'America/Argentina/Buenos_Aires': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Araguaina': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/Catamarca': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/Cordoba': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/ArgentinaJujuy': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/La_Rioja': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/Medoza': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/Rio_Gallegos': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/Salta': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/San_Juan': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/San_Luis': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/Tucuman': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Argentina/Ushuaia': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Bahia': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Belem': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Cayenne': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Fortaleza': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Maceio': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Montevideo': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Paramaribo': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Punta_Arenas': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Recife': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Santarem': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Sao_Paulo': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -3
    },
    'America/Miquelon': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -2
    },
    'America/Nuuk': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -3,
        UTC_DST_Offset: -2
    },
    'America/Noronha': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -2,
        UTC_DST_Offset: -2
    },
    'America/Scoresbysund': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: -1,
        UTC_DST_Offset: 0
    },
    'America/Danmarkshavn': {
        master_timezone: 'America/St_Johns',
        UTC_Offset: 0,
        UTC_DST_Offset: 0
    },
    'Pacific/Auckland': {
        master_timezone: 'Pacific/Auckland',
        UTC_Offset: 12,
        UTC_DST_Offset: 13
    },
    }
export default timezone
