import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-admin';
import Iframe from './Iframe';
import PortalPreview from './Themes';
import { Select, Tooltip, Button, MenuItem } from '@material-ui/core';

import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import TabletMacIcon from '@material-ui/icons/TabletMac';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9fc;
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 32px;
  padding-right: 32px;

  .orientation-button {
    margin-right: 16px;
  }

  .toggle-menu-button {
    margin-left: 16px;

    @media (max-width: 1280px) {
      margin-left: auto;
    }
  }

  @media (max-width: 700px) {
    padding: 16px;
  }
`;

const PreviewMenu = styled.div`
  margin-bottom: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PreviewTitle = styled.h1`
  text-transform: capitalize;
  font-size: 24px;
  font-weight: medium;
  margin: 0;

  @media (max-width: 1380px) {
    font-size: 1.2rem;
  }

  @media (max-width: 1280px) {
    display: none;
  }
`;

const PreviewActions = styled.div`
  display: flex;

  @media (max-width: 1280px) {
    width: 100%;
  }
`;

const PreviewWindow = styled.div`
  background: white;
  border-radius: 4px;
  border: 1px solid #edeff6;
  padding: 32px;
  width: ${({ resolution, orientation }: any) =>
    Array.isArray(resolution) ? resolution[orientation === 'portrait' ? 0 : 1] + 'px' : 'calc(100% - 64px)'};
  max-width: calc(100% - 64px);
  height: ${({ resolution, orientation }: any) =>
    Array.isArray(resolution) ? resolution[orientation === 'portrait' ? 1 : 0] + 'px' : 'calc(100% - 128px)'};
  max-height: calc(100% - 138px);
  box-sizing: content-box;
  transition: all 0.2s;

  @media (max-width: 700px) {
    padding: 16px;
    width: ${({ resolution, orientation }: any) =>
    Array.isArray(resolution) ? resolution[orientation === 'portrait' ? 0 : 1] + 'px' : 'calc(100% - 16px)'};
    max-width: calc(100% - 32px);
    height: ${({ resolution, orientation }: any) =>
    Array.isArray(resolution) ? resolution[orientation === 'portrait' ? 1 : 0] + 'px' : 'calc(100% - 32px)'};
    max-height: calc(100% - 32px);
  }
`;

const ActualPreview = styled.div`
  box-sizing: border-box;
  background: #f9f9fc;
  border-radius: 2px;
  border: 1px solid #edeff6;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
`;

const StyledSelect = styled(Select)`
  margin: 0 16px;
  min-width: 80px;
`

const RESOLUTION_MAP: { [key: string]: number[] } = {
  desktop: [1680, 915],
  iphone5: [320, 568],
  iphone6: [375, 667],
  iphone6PLUS: [414, 736],
  iphoneX: [375, 812],
  galaxyS5: [360, 640],
  pixel2: [411, 731],
  pixel2XL: [411, 823],
  ipad: [768, 1024],
  ipadPRO: [1024, 1366],
};

const mobiles = [
  { value: 'iphone5', label: 'Iphone 5/SE' },
  { value: 'iphone6', label: 'Iphone 6/7/8' },
  { value: 'iphone6PLUS', label: 'Iphone Iphone 6/7/8 Plus' },
  { value: 'iphoneX', label: 'Iphone X/11/11 Pro' },
  { value: 'galaxyS5', label: 'Galaxy S5' },
  { value: 'pixel2', label: 'Pixel 2' },
  { value: 'pixel2XL', label: 'Pixel 2 XL' },
];

interface PreviewPropsType {
  record: any;
  menuCollapsed?: boolean;
  setMenuCollapsed: any;
  locale: string;
  setLocale: any;
}

const Preview = ({ record = { portal_config: {} }, menuCollapsed, setMenuCollapsed, locale, setLocale }: PreviewPropsType) => {
  const t = useTranslate();
  const [resolution, setResolution]: any = useState('responsive');
  const [orientation, setOrientation]: any = useState('portrait');
  const [device, setDevice]: any = useState('responsive');

  if (!record.portal_config) return null;

  const handleChange = (e: any) => {
    setDevice(e.target.value);
    setResolution(RESOLUTION_MAP[e.target.value] || 'responsive');
  };

  const handleOrientation = () => {
    setOrientation(orientation === 'portrait' ? 'landscape' : 'portrait');
  };

  const handleLocaleChange = (e: any) => setLocale(e.target.value)

  return (
    <PreviewContainer>
      <PreviewMenu>
        <PreviewTitle>{t('cloudFi.portal_builder.captive_portal_builder')}</PreviewTitle>
        <PreviewActions>
          {resolution[0] && resolution[0] < 1200 ? (
            <Tooltip placement="top-start" title="Switch device orientation" arrow>
              <Button className="orientation-button" onClick={handleOrientation}>
                <Rotate90DegreesCcwIcon />
              </Button>
            </Tooltip>
          ) : null}
          <Select defaultValue="responsive" style={{ width: 200 }} value={device} onChange={handleChange}>
            <MenuItem value="responsive">
              <AspectRatioIcon /> {t('cloudFi.portal_builder.responsive')}
            </MenuItem>
            <MenuItem value="desktop">
              <DesktopWindowsIcon />
              MacBook 15"
            </MenuItem>
            <MenuItem value="ipad">
              <TabletMacIcon />
              Ipad
            </MenuItem>
            <MenuItem value="ipadPRO">
              <TabletMacIcon />
              Ipad Pro
            </MenuItem>
            {mobiles.map(mobile => (
              <MenuItem value={mobile.value} key={mobile.value}>
                <PhoneIphoneIcon />
                {mobile.label}
              </MenuItem>
            ))}
          </Select>
          <StyledSelect defaultValue="en" style={{ width: 60 }} value={locale} onChange={handleLocaleChange}>
            <MenuItem value="en">
              EN
            </MenuItem>
            <MenuItem value="fr">
              FR
            </MenuItem>
          </StyledSelect>
          <Tooltip placement="top-end" title="Toggle Menu" arrow>
            <Button variant="outlined" className="toggle-menu-button" onClick={() => setMenuCollapsed(!menuCollapsed)}>
              <MenuOpenIcon /> {t('common.menu')}
            </Button>
          </Tooltip>
        </PreviewActions>
      </PreviewMenu>
      <PreviewWindow
        // @ts-ignore
        resolution={resolution}
        orientation={orientation}
      >
        <ActualPreview>
          <Iframe resolution={resolution} orientation={orientation}>
            <PortalPreview record={record} locale={locale} />
          </Iframe>
        </ActualPreview>
      </PreviewWindow>
    </PreviewContainer>
  );
};

export default Preview;
