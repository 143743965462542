import React, { useState } from 'react';
import { useTranslate } from 'react-admin';
import styled from 'styled-components';
import compose from 'recompose/compose';
import { sha256 } from 'js-sha256';
import { addField, translate } from 'react-admin';
import getAuthHeader from '~/common/utils/getAuthHeader';
import FileInput from './FileInput';

const UPLOADS_URL = 'https://uploads.colonynetworks.net';
const makeFileUrl = (filePath: string) => `${UPLOADS_URL}/${filePath}`;

const ImagePlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px;
`;

const ImagePlaceholder = () => {
  const t = useTranslate();
  return (
    <ImagePlaceholderContainer>
      <span>{t('action.drag_and_drop_image')}</span>
    </ImagePlaceholderContainer>
  );
};

const imageExists = (imageUrl: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = resolve;
    img.onerror = reject;
    img.src = imageUrl;
  });

const ImageInput = (props: any) => {
  const [uploading, setUploading] = useState(false);

  const onUpload = (filePath: string) => {
    props.input.onChange(`${UPLOADS_URL}/${filePath}`);
    setUploading(false);
  };

  const onClear = () => {
    props.input.onChange('')
  }

  const uploadImageFiles = (files: any) => {
    setUploading(true);
    const transformFile = (file: { preview: any;[key: string]: any }) => {
      if (!(file instanceof File)) {
        return file;
      }

      const transformedFile = {
        rawFile: file,
        [props.source]: file.preview,
      };

      return transformedFile;
    };

    const transformedFiles = files.map(transformFile);
    // using the simple media upload as per the spec here: https://cloud.google.com/storage/docs/json_api/v1/how-tos/simple-upload
    const BUCKET = 'cloudfi-images';
    const GOOGLE_STORAGE_URL = `https://www.googleapis.com/upload/storage/v1/b/${BUCKET}/o?uploadType=media`;
    fetch(`https://api.colonynetworks.com/v3/uploads/image-upload-token`, {
      method: 'GET',
      // @ts-ignore
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: getAuthHeader(),
      }),
    })
      .then(resp => resp.text())
      .then(TOKEN => {
        transformedFiles.forEach((file: any) =>
          new Response(file.rawFile).arrayBuffer().then(fileAb => {
            // check if file content already exists using hash
            const filePath = sha256(fileAb);
            // file exists! just use existing
            imageExists(makeFileUrl(filePath))
              // exists (if it already exists, don't bother uploading a new one)
              .then(() => onUpload(filePath))
              // doesn't exist
              .catch(() =>
                // upload to Google Storage
                fetch(`${GOOGLE_STORAGE_URL}&name=${filePath}`, {
                  method: 'POST',
                  headers: new Headers({
                    'Content-Type': file.rawFile.type,
                    'Content-Length': file.rawFile.size,
                    Authorization: `Bearer ${TOKEN}`,
                  }),
                  body: file.rawFile,
                })
                  .then(response => response.json())
                  .then(resp => onUpload(resp.name))
                  .catch(err => console.error('GC storage upload error', err))
              );
          })
        );
      });
  };

  return (
    <FileInput
      {...props}
      Placeholder={props.Placeholder ? props.Placeholder : ImagePlaceholder}
      onUpload={uploadImageFiles}
      onClear={onClear}
      uploading={uploading}
    />
  );
};

export default compose(addField, translate)(ImageInput);
