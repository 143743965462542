import React from 'react';
import { Resource, DataProviderContext } from 'react-admin';
import ListView from './ListView';
import Edit from './Edit';
import Create from './Create';
import Show from './Show';
import { createResourceIcon } from '~/ui-components/Icons/ResourceIcon';
import { DataProvider } from '~/Admin/data';

import CaptivePortalEdit from '~/Admin/Edit/CaptivePortal';
import CaptivePortalCreate from '~/Admin/Create/CaptivePortal';
import CaptivePortalList from '~/Admin/List/CaptivePortal';

// resources should rerender minimally
const ColonyResource = React.memo(({ name, list, edit, create, show, empty = false, ...props }: any) => {
  const dataProvider: DataProvider = React.useContext(DataProviderContext);
  // don't render resources we're not allowed to access
  // but make sure to render dummy resources for tree menu
  if ((!props.options || !props.options.isMenuParent) && (!dataProvider || !dataProvider.hasResource(name)))
    return null;

  // custom views
  if (name === 'captive_portal_v3') {
    edit = edit === undefined ? CaptivePortalEdit : edit;
    list = list === undefined ? CaptivePortalList : list;
    create = create === undefined ? CaptivePortalCreate : create;
  }

  if (dataProvider.isReadOnly(name)) {
    create = false;
    edit = false;
  }

  if (empty) {
    // empty flag sets all to false, just registers resource without routes
    create = false;
    edit = false;
    list = false;
    show = false;
  }

  return (
    <Resource
      {...props}
      name={name}
      create={create || create === false ? create : Create}
      edit={edit || edit === false ? edit : Edit}
      list={list || list === false ? list : ListView}
      show={show || show === false ? show : Show}
      icon={createResourceIcon(name)}
    />
  );
});

export default ColonyResource;
