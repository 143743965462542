import { hasLocalStorage } from '../featureTests';

const getAuthHeader = () => {
  // TODO create token fallback if sessionStorage is not available.
  const token = hasLocalStorage ? sessionStorage.getItem('access_token') : null;
  if (!token) return {};

  return `Bearer ${token}`;
};

export default getAuthHeader;
