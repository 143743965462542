import React from 'react';

const AuthContext = React.createContext(null);

export class AuthProvider extends React.Component {
  state = { authToken: null, roles: [], username: null };
  render() {
    return (
      <AuthContext.Provider
        // @ts-ignore
        value={{
          authToken: this.state.authToken,
          roles: this.state.roles,
          username: this.state.username,
          setAuthToken: (authToken: String) => {
            this.setState({ authToken });
          },
          setUsername: (username: String) => this.setState({ username }),
          setRoles: (roles: string[]) => this.setState({ roles }),
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContext;
