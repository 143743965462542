import get from 'lodash.get';
import uniq from 'lodash.uniq';
import { OpenAPIV3 } from 'openapi-types';
import { Field } from '@api-platform/api-doc-parser/lib/Field';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';

export const removeTrailingSlash = (url: string): string => {
  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }
  return url;
};

const ALLOWED_VIEWER_RESOURCES = [
  'dns_service',
  'device_v3',
  'site',
  'site_type', // HACK to make site creation work.
  'captive_portal_v3',
  'wifi_service_v3',
  'company',
  'company_id',
  'probe',
  'service_firewall',
  // 'users',
  'events',
  'ssid',
].filter((resource: string) => {
  // TODO find global way to support env variables without TS errors.
  if (process.env.HIDDEN_RESOURCES) {
    // @ts-ignore env var
    const hiddenResources: string[] = process.env.HIDDEN_RESOURCES.split(',');
    return !hiddenResources.includes(resource);
  }
  return true;
});

const filterResources = (pathName: string, roles: string[]) => {
  if (roles.includes('superadmin')) {
    return true;
  }

  let allowedResources: string[] = ALLOWED_VIEWER_RESOURCES;

  return allowedResources.includes(pathName.replace('/', ''));
};

export default function(response: OpenAPIV3.Document, entrypointUrl: string, roles: string[]): Resource[] {
  // @ts-ignore
  const baseUrl = removeTrailingSlash((response.servers[0] && response.servers[0].url) || entrypointUrl);
  const paths = uniq(Object.keys(response.paths).map(item => item.replace(`/{id}`, ``))).filter((pathName: string) =>
    filterResources(pathName, roles)
  );

  const resources = paths.map((item: string) => {
    const name = item.replace(`/`, ``);
    const url = baseUrl + item;
    const readSchema = get(
      response,
      [
        // pull single item out of array schema of list request
        'paths',
        item,
        'get',
        'responses',
        '200',
        'content',
        'application/json',
        'schema',
        'items',
      ],
      {}
    );
    const writeSchema = get(
      response,
      ['paths', item, 'post', 'requestBody', 'content', 'application/json', 'schema'],
      {}
    );

    const [readableFields, writableFields] = [readSchema, writeSchema].map(schema => {
      const properties = schema.properties ? schema.properties : {};

      const fieldNames = Object.keys(properties);
      // required fields are not nullable
      // @ts-ignore
      const requiredFields: string[] = Object.keys(properties).filter(p => p['required'] || p['x-nullable'] !== true);
      return fieldNames.map(fieldName => {
        return new Field(fieldName, {
          id: null,
          range: null,
          reference: null,
          required: !!requiredFields.find(value => value === fieldName),
          description: get(properties[fieldName], `description`, ``),
        });
      });
    });
    return new Resource(name, url, {
      id: null,
      title: name,
      fields: readableFields,
      readableFields,
      writableFields,
    });
  });
  return resources;
}
