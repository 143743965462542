import React, { useState } from 'react';
import styled from 'styled-components';
import * as ReactColor from 'react-color';

import { TextField, InputAdornment } from '@material-ui/core';
import { useField } from 'react-final-form';

const ColorInputContainer = styled.div`
  position: relative;
  .ColorInput-popup {
    margin: 16px 0 8px;
  }
  .chrome-picker {
    width: 100% !important;
    max-width: 360px;
  }

  .ColorInput-cover {
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const StyledInput = styled(TextField)`
  padding-right: 0;
`;

const ColorPreview = styled.div`
  height: 20px;
  width: 20px;
  background: ${props => props.color};
  border-radius: 4px;
  border: 1px solid #eee;
`;

interface ColorInputPropsType {
  label: string;
  id?: string;
  name: string;
  options?: any;
  placeholder?: string;
  helperText?: string;
  className?: string;
  picker?: string;
  isRequired?: boolean;
  resource?: any;
  record?: any;
  source?: string;
  initialValue?: string;
}

const ColorInput = ({
  label,
  id,
  className,
  placeholder = '#000000',
  options = { disableAlpha: true },
  picker = 'Chrome',
  record,
  source,
  name,
  isRequired,
  initialValue,
  ...rest
}: ColorInputPropsType) => {
  const {
    input,
    meta: { touched, error },
  } = useField(name);

  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleChange = ({ hex }: { hex: string }) => {
    input.onChange(hex);
  };

  // @ts-ignore
  const Picker: any = ReactColor[`${picker}Picker`];
  if (!Picker) return null;

  return (
    <ColorInputContainer>
      <StyledInput
        {...input}
        onFocus={handleOpen}
        placeholder={placeholder}
        className={className}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ColorPreview
                // @ts-ignore
                color={input.value}
              />
            </InputAdornment>
          ),
        }}
        error={!!(touched && error)}
        helperText={touched && error}
        defaultValue={initialValue}
        {...rest}
        variant="filled"
      />
      {show ? (
        <div className="ColorInput-popup">
          <div className="ColorInput-cover" onClick={handleClose} />
          <Picker {...options} color={input.value} onChange={handleChange} />
        </div>
      ) : null}
    </ColorInputContainer>
  );
};

export default ColorInput;
