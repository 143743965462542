import { css } from 'styled-components'

const footerMixin = css`
  .footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    height: ${(p: any) => p.record.portal_config.footer?.height || 100}px;
    color: ${(p: any) => p.record.portal_config.footer?.color || 'inherit'};
    font-family: ${(p: any) => p.record.portal_config.footer?.font_family || 'inherit'};
    font-size: ${(p: any) => p.record.portal_config.footer?.font_size || 16}px;
    font-weight: ${(p: any) => p.record.portal_config.footer?.font_weight || 'inherit'};
    background-color: ${(p: any) => p.record.portal_config.footer?.background_color || 'transparent'};
    background-image: url(${(p: any) => p.record.portal_config.footer?.background_image || ''});
    background-repeat: no-repeat;
    background-size: ${(p: any) => p.record.portal_config.header?.background_size || 'cover'};
    background-position: ${(p: any) => p.record.portal_config.footer?.background_position || 'bottom center'};
  }
`

export default footerMixin