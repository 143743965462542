// @ts-nocheck
import * as React from 'react';
import { useState, Children } from 'react';
import { useTranslate } from 'react-admin';
import classnames from 'classnames';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormWithRedirect } from 'react-admin';

const TabbedForm = props => {
  return <FormWithRedirect {...props} redirect="/" render={formProps => <TabbedFormView {...formProps} />} />;
};

const useStyles = makeStyles(
  theme => ({
    errorTabButton: { color: theme.palette.error.main },
    content: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }),
  { name: 'RaTabbedForm' }
);

export const TabbedFormView = props => {
  const {
    basePath,
    children,
    className,
    classes: classesOverride,
    form,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    record,
    redirect,
    resource,
    saving,
    setRedirect,
    submitOnEnter,
    tabs,
    toolbar,
    translate,
    // undoable,
    value,
    variant,
    margin,
    ...rest
  } = props;
  const t = useTranslate();
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles(props);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const undoable = false;

  return (
    <form className={classnames('tabbed-form', className)} {...sanitizeRestProps(rest)}>
      <Tabs value={activeTab} onChange={handleChange} variant="fullWidth">
        <Tab label={t('common.settings')} id="0" className="form-tab" />
        <Tab label={t('cloudFi.portal_builder.settings.policies')} id="1" className="form-tab" />
        <Tab label={t('common.content')} id="2" className="form-tab" />
        <Tab label={t('common.style')} id="3" className="form-tab" />
      </Tabs>
      <Divider />
      <div className={classes.content}>
        {/* All tabs are rendered (not only the one in focus), to allow validation
                on tabs not in focus. The tabs receive a `hidden` property, which they'll
                use to hide the tab using CSS if it's not the one in focus.
                See https://github.com/marmelab/react-admin/issues/1866 */}
        {Children.map(children, (tab, index) => {
          return React.cloneElement(tab, {
            intent: 'content',
            resource,
            record,
            basePath,
            hidden: activeTab !== index,
            variant: tab.props.variant || variant,
            margin: tab.props.margin || margin,
          });
        })}
      </div>
      {toolbar &&
        React.cloneElement(toolbar, {
          basePath,
          className: 'toolbar',
          handleSubmitWithRedirect,
          handleSubmit,
          invalid,
          pristine,
          record,
          redirect,
          resource,
          saving,
          submitOnEnter,
          undoable,
        })}
    </form>
  );
};

TabbedFormView.defaultProps = {
  submitOnEnter: true,
};

const sanitizeRestProps = ({
  anyTouched,
  array,
  asyncBlurFields,
  asyncValidate,
  asyncValidating,
  autofill,
  blur,
  change,
  clearAsyncError,
  clearFields,
  clearSubmit,
  clearSubmitErrors,
  destroy,
  dirty,
  dirtyFields,
  dirtyFieldsSinceLastSubmit,
  dirtySinceLastSubmit,
  dispatch,
  form,
  handleSubmit,
  hasSubmitErrors,
  hasValidationErrors,
  initialize,
  initialized,
  initialValues,
  modifiedSinceLastSubmit,
  modifiedsincelastsubmit,
  pristine,
  pure,
  redirect,
  reset,
  resetSection,
  save,
  staticContext,
  submit,
  submitAsSideEffect,
  submitError,
  submitErrors,
  submitFailed,
  submitSucceeded,
  submitting,
  touch,
  translate,
  triggerSubmit,
  undoable,
  untouch,
  valid,
  validate,
  validating,
  __versions,
  ...props
}) => props;

export default TabbedForm;
