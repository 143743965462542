import React from 'react';
import { useRedirect, useTranslate } from 'react-admin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resourceToWord } from '~/common/utils/resource';
import ActionButton from './ActionButton';

const RefreshButton = ({ resource }: { resource: string }) => {
  const t = useTranslate();
  const redirect = useRedirect();

  return (
    <ActionButton
      tooltip={`${t('action.create')} ${resourceToWord({ resource })}`}
      color="primary"
      onClick={() => redirect(`/${resource}/create`)}
    >
      <FontAwesomeIcon icon="plus" />
    </ActionButton>
  );
};

export default RefreshButton;
