import React, { useState } from 'react';
import styled from 'styled-components';
import {
  TextInput,
  SelectInput,
  // CheckboxGroupInput,
  required,
  useTranslate,
  Edit,
  SimpleForm,
  SaveButton,
  Toolbar,
  showNotification,
  // SelectArrayInput
} from 'react-admin';
import CollapseableSection from '../CollapseableSection';
import { TabContainer, InputsContainer } from './styles';

interface SettingsTabProps {
  wifiServiceId?: number;
}

const StyledSelectInput = styled(SelectInput)`
  .MuiSelect-root {
    text-transform: capitalize;
  }
  .MuiFormHelperText-root {
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const StyledTextInput = styled(TextInput)`
  .MuiFormHelperText-root {
    &:first-letter {
      text-transform: uppercase;
    }
  } 
`;

const SettingsTab = React.memo(({ wifiServiceId }: SettingsTabProps) => {
  const t = useTranslate();
  const [openSections, setOpenSections]: any = useState({
    portal: true,
    post_auth_url: false,
    // languages: false,
    // hardware: false
  });

  // const AVAILABLE_LANGUAGES = [
  //   { id: 'en_CA', name: t('language.english') },
  //   { id: 'fr_CA', name: t('language.french') },
  // ];

  const handleExpandToggle = (type: string) => {
    const newSections = { ...openSections };
    newSections[type] = !openSections[type];
    setOpenSections(newSections);
  };

  // const languageOptions = [
  //   { id: 'en-US', name: t('cloudFi.portal_builder.settings.choices.english') }, 
  //   { id: 'fr-FR', name: t('cloudFi.portal_builder.settings.choices.french') }, 
  //   { id: 'es', name: t('cloudFi.portal_builder.settings.choices.spanish') }
  // ]

  return (
    <TabContainer>
      <CollapseableSection
        open={openSections.portal}
        label={t('cloudFi.portal')}
        id="portal"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <StyledSelectInput
            source="portal_config.type"
            label={t('cloudFi.portal_builder.settings.captive_portal_type')}
            placeholder={t('cloudFi.portal_builder.settings.captive_portal_type')}
            validate={[required()]}
            choices={[{ id: 'cloudfi-click-terms', name: t('cloudFi.portal_builder.settings.click_to_accept') }]}
            initialValue="cloudfi-click-terms"
          />
          <StyledSelectInput
            source="portal_config.cp_mac_auth"
            name="portal_config.cp_mac_auth"
            choices={[
              { id: true, name: t('status.enabled') },
              { id: false, name: t('status.disabled') },
            ]}
            initialValue={false}
            label={t('cloudFi.portal_builder.settings.cp_mac_auth')}
            helperText={t('cloudFi.portal_builder.settings.cp_mac_auth_help_text')}
          />
        </InputsContainer>
      </CollapseableSection>
      {wifiServiceId ? (
        <CollapseableSection
          open={openSections.ssid}
          label={t('cloudFi.ssid')}
          id="ssid"
          handleToggle={handleExpandToggle}
        >
          <Edit
            id={`${wifiServiceId}/guest`}
            resource="ssid"
            basePath="/"
            undoable={false}
            onSuccess={() => showNotification('cloudFi.portal_builder.settings.change_ssid_success')}
          >
            <SimpleForm
              redirect={false}
              toolbar={
                <Toolbar>
                  <SaveButton
                    fullWidth
                    label="cloudFi.portal_builder.settings.change_ssid_settings"
                    redirect={false}
                    submitOnEnter={false}
                  />
                </Toolbar>
              }
            >
              <TextInput
                source="name"
                label="cloudFi.portal_builder.settings.network_name"
                placeholder={t('cloudFi.portal_builder.settings.placeholders.network_name')}
              />
              <StyledSelectInput
                source="enabled"
                name="enabled"
                choices={[
                  { id: true, name: t('status.enabled') },
                  { id: false, name: t('status.disabled') },
                ]}
                label="status.enabled"
              />
            </SimpleForm>
          </Edit>
        </CollapseableSection>
      ) : null}
      <CollapseableSection
        open={openSections.post_auth_url}
        label={t('cloudFi.portal_builder.settings.post_auth_url')}
        id="post_auth_url"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <StyledTextInput
            source="portal_config.post_auth_url"
            label={t('cloudFi.portal_builder.settings.post_auth_url')}
            helperText={t('cloudFi.portal_builder.settings.post_auth_url_help_text')}
            placeholder="https://google.com"
          />
        </InputsContainer>
      </CollapseableSection>

      {/* <CollapseableSection
        open={openSections.language_support}
        label={t('cloudFi.portal_builder.settings.language_support')}
        id="language_support"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          {/* <SelectArrayInput 
            source="portal_config.languages"
            label={t('cloudFi.portal_builder.settings.language_support')} 
            placeholder={t('cloudFi.portal_builder.settings.placeholders.language_support')}
            choices={languageOptions} 
            defaultValue={["en-US"]}
          /> */}

      {/* <CollapseableSection
        open={openSections.languages}
        label={t('cloudFi.portal_builder.settings.languages')}
        id="languages"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <CheckboxGroupInput
            label={t('cloudFi.portal_builder.settings.available_languages')}
            initialValue={['en_CA']}
            source="portal_config.locale_available"
            choices={AVAILABLE_LANGUAGES}
          />
          <SelectInput
            label={t('cloudFi.portal_builder.settings.default_language')}
            initialValue="en_CA"
            placeholder={t('cloudFi.portal_builder.settings.default_language')}
            source="portal_config.locale_default"
            id="default-language"
            choices={AVAILABLE_LANGUAGES}
          />
          <BooleanInput
            label={t('cloudFi.portal_builder.settings.browser_language_detect')}
            source="portal_config.locale_browser_detect"
            defaultChecked
          />
        </InputsContainer>
      </CollapseableSection> */}
      {/* <CollapseableSection
        open={openSections.hardware}
        label={t('cloudFi.portal_builder.settings.advanced_hardware_settings')}
        id="hardware"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <TextInput
            label={t('cloudFi.portal_builder.settings.override_callback_hostname')}
            placeholder={t('cloudFi.portal_builder.settings.placeholders.host_name')}
            source="portal_config.override_callback_hostname"
          />
          <TextInput
            label={t('cloudFi.portal_builder.settings.default_preauth_role_name')}
            placeholder={t('cloudFi.portal_builder.settings.preauth_role_name')}
            source="radius_policy.default_preauth_role_name"
          />
          <TextInput
            label={t('cloudFi.portal_builder.settings.default_postauth_role_name')}
            placeholder={t('cloudFi.portal_builder.settings.postauth_role_name')}
            source="radius_policy.default_postauth_role_name"
          />
          <TextInput
            label={t('cloudFi.portal_builder.settings.preauth_role_name')}
            source={`radius_policy.login_tier.free.preauth_role_name`}
          />
          <TextInput
            label={t('cloudFi.portal_builder.settings.postauth_role_name')}
            source={`radius_policy.login_tier.free.postauth_role_name`}
          />
        </InputsContainer>
      </CollapseableSection> */}
    </TabContainer >
  );
});

export default SettingsTab;
