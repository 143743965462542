import React from 'react';
import styled from 'styled-components';
import { shallowEqual, useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { Sidebar, MenuItemLink, getResources } from 'react-admin';
import { resourceToTitle } from '~/common/utils/resource';
import { isBrowser } from '~/common/featureTests';
import AuthContext from '~/Admin/keycloak/AuthContext';

const StyledSidebar = styled(Sidebar)` // must be false
  && {
    margin-top: 24px;
    .MuiDrawer-paperAnchorDockedLeft {
      margin-top: 0;
    }
    .MuiDrawer-paper {
      display: ${(props: any) => (!props.isHomePage ? 'flex' : 'none')};
    }
    .MuiBackdrop-root {
      display: ${(props: any) => (!props.isHomePage ? 'flex' : 'none')};
      position: ${(props: any) => (!props.isHomePage ? 'fixed' : 'initial')};
    }
  }
`;

const StyledMenu = styled.div`
  height: 100%;
  max-height: calc(100vh - 64px - 48px);
  a {
    position: relative;
    border-top-right-radius: ${(props: any) => (props.isXSmall ? '0' : '4')}px;
    border-bottom-right-radius: ${(props: any) => (props.isXSmall ? '0' : '4')}px;
    border-left: 8px solid transparent;
    padding: 8px 12px;
    height: ${(props: any) => (props.dense ? '48px' : '64px')};
    width: 100%;
    display: flex;
    justify-content: flex-start;
    &:hover {
      color: ${props => props.theme.palette.text.primary};
      svg {
        color: ${props => props.theme.palette.text.primary};
      }
    }
  }

  a[aria-current='page'] {
    border-left: 8px solid ${props => props.theme.palette.primary.main};
    background: ${props => props.theme.palette.grey[300]};
    color: ${props => props.theme.palette.primary.main};
    svg {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`;

const SidebarMenu = ({ onMenuClick, logout, auth }: any) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'));
  // @ts-ignore
  let open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources: any = useSelector(getResources, shallowEqual);
  const isHomePage = isBrowser && window.location.pathname === '/';
  if (isHomePage ) {
    open = false
    return open;
  } 

  const hasManyResources = resources.length >= 25;
  const showRealNames = auth.roles.includes('superadmin');

  return (
    // @ts-ignore
    <StyledMenu dense={hasManyResources} isXSmall={isXSmall}>
      {resources
        .filter(({ hasList }: any) => hasList)
        .map((resource: any) => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={showRealNames ? resource.name : resourceToTitle({ resource: resource.name, plural: true })}
            leftIcon={<resource.icon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}

      {isXSmall && logout}
    </StyledMenu>
  );
};

const ColonySidebar = ({ hasDashboard,  ...props }: any) => {
  // @ts-ignore
  let open = useSelector(state => state.admin.ui.sidebarOpen);
  const isHomePage = isBrowser && window.location.pathname === '/';

  if (isHomePage ) {
    open = false
    return open;
  } 

 return(
  <AuthContext.Consumer>
    {(auth: any) => (
      <StyledSidebar {...props} >
         <SidebarMenu logout={props.logout} hasDashboard={hasDashboard} auth={auth} />
      </StyledSidebar>
    )}
  </AuthContext.Consumer>
)};

export default ColonySidebar;
