import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { useTranslate } from 'react-admin';

const DropZone = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  color: #999;
  padding: 0;
  border: 2px dashed #cfdbe6;
  width: 100%;
  ${(props: any) => (props.height ? `height: ${props.height}px` : '')};
  max-height: ${(props: any) => (props.maxHeight ? props.maxHeight : 400)}px;
  padding: 8px;
  outline: none;
  overflow: hidden;

  i {
    margin-bottom: 16px;
    font-size: 3rem;
  }

  p,
  span {
    margin: 0;
  }

  &:hover {
    border-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: ${(props: any) => (props.actionsPosition === 'left' ? 'flex-start' : 'center')};
  margin-top: 16px;
  margin-bottom: 16px;
  button {
    margin: ${(props: any) => (props.actionsPosition === 'left' ? '0 16px 0 0' : '0 8px')};
  }
`;

const Thumb = styled.img`
  height: 100%;
  max-height: calc(${(props: any) => (props.maxHeight ? props.maxHeight : 400)}px - 22px);
  border-radius: 4px;
`;

const FilePlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px;
`;

const Button = styled.button`
  text-transform: capitalize;
  padding: 8px 16px;
  border: 1px solid #ddd;
  margin-top: 8px;
`

const PlaceholderDefault = () => (
  <FilePlaceholderContainer>
    {/* <Icon type="file" /> */}
    <p>Drag and drop file, or browse</p>
  </FilePlaceholderContainer>
);

const FileInput = ({
  onUpload,
  onClear,
  uploading,
  height,
  maxHeight,
  input,
  clickToUpload = false,
  uploadText = 'Upload file',
  removeText = 'Clear file',
  Placeholder = PlaceholderDefault,
  //   id,
  //   label,
  actionsPosition = 'center',
}: //   meta,
  //   isRequired,
  any) => {
  const t = useTranslate();
  const [files, setFiles]: any = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      const newFiles = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setFiles(newFiles);
      if (!clickToUpload) onUpload(newFiles);
    },
  });

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  let preview = files.length === 1 ? files[0].preview : undefined;
  if (!preview && input.value && input.value !== "None") {
    preview = input.value
  }

  const onClearFiles = () => {
    onClear()
    setFiles([])
  }

  return (
    <>
      <DropZone
        // @ts-ignore
        {...getRootProps({ className: 'dropzone' })}
        // @ts-ignore
        preview={preview}
        height={height}
        maxHeight={maxHeight}
      >
        {preview ? (
          <Thumb
            src={preview}
            // @ts-ignore
            maxHeight={maxHeight}
          />
        ) : (
            <>
              {/* TODO this input needs to be a react-admin piece with a source */}
              <input {...getInputProps()} />
              <Placeholder className="file-placeholder" />
            </>
          )}
      </DropZone>
      {clickToUpload && (
        <ActionContainer
          // @ts-ignore
          actionsPosition={actionsPosition}
        >
          <Button
            disabled={!files.length}
            // @ts-ignore
            // type="primary"
            // @ts-ignore
            // loading={uploading}
            onClick={(e: any) => {
              e.preventDefault();
              onUpload(files);
            }}
          >
            {uploadText}
          </Button>
          <Button
            disabled={!files.length || uploading}
            onClick={(e: any) => {
              e.preventDefault();
              setFiles([]);
            }}
          >
            {removeText}
          </Button>
        </ActionContainer>
      )}
      {!!preview && <Button onClick={onClearFiles}>{t('cloudFi.portal_builder.content.remove_image')}</Button>}
    </>
  );
};

export default FileInput;
