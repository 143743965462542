import { css } from 'styled-components'

const buttonMixin = css`
  .button {
    background: ${(p: any) => p.record.portal_config.main_content?.connect_button?.background_color || '#4269E1'};
    color: ${(p: any) => p.record.portal_config.main_content?.connect_button?.color || '#FFFFFF'};
    padding: 12px 16px;
    width: 100%;
    margin-top: 16px;
    border-radius: 4px;
    border: none;
    font-family: ${(p: any) => p.record.portal_config.main_content?.connect_button?.font_family || 'inherit'};
    font-size: ${(p: any) => p.record.portal_config.main_content?.connect_button?.font_size || 16}px;
    font-weight: ${(p: any) => p.record.portal_config.main_content?.connect_button?.font_weight || 'inherit'};
    transition: all 0.1s;
  }
  
  .button:hover:not([disabled]) {
    opacity: 0.8;
    cursor: pointer;
  }
  
  .button:active {
    opacity: 1;
  }
  
  .button[disabled] {
    opacity: 0.5;
  }
`

export default buttonMixin