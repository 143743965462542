import React from 'react';
import { CardContentInner } from 'react-admin';
import Card from '@material-ui/core/Card';
import { Form } from 'react-final-form';

const StepForm = ({ children, onSubmit, submitText }: any) => {
  return (
    <Card>
      <CardContentInner>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {children}
              <button type="submit">{submitText}</button>
            </form>
          )}
        />
      </CardContentInner>
    </Card>
  );
};

export default StepForm;
