import React from 'react';

const initDataProvider = ({
  dataProviderType,
  specUrl,
  auth,
  children,
  onLoad,
  cubeApi,
  cache = true,
  cacheDuration = 5 * 60 * 1000 /* 5min */,
}: any) => {
  const [dataProvider, setDataProvider] = React.useState(null);

  React.useEffect(() => {
    // use introspect methods to handle loading up specs, wait for all complete before loading page
    // check loading as well to make sure we only perform once on first page load
    if (auth.authToken && !dataProvider) {
      // handle overrides and dataProvider setup only when auth changes
      let overrides = {};
      if (children) {
        // pass in dataProvider overrides as a mapping of { [resource]: dataprovider }
        overrides = React.Children.toArray(children).reduce(
          (acc: any, child: any) => ({
            ...acc,
            // if an explicit dataProvider prop is passed, use that
            [child.props.name]: child.props.dataProviderType
              ? new child.props.dataProviderType({
                specUrl: child.props.specUrl || specUrl,
                auth,
                cache,
                cacheDuration,
                cubeApi,
              })
              : // if a specUrl prop is passed, use that to create a default dataProvider
              child.props.specUrl
                ? new dataProviderType({
                  specUrl: child.props.specUrl,
                  auth,
                  cache,
                  cacheDuration,
                  cubeApi,
                })
                : new dataProviderType({
                  specUrl,
                  auth,
                  cache,
                  cacheDuration,
                  cubeApi,
                }),
          }),
          {}
        );
      }
      const _dataProvider = new dataProviderType({
        specUrl,
        auth,
        overrides,
        cache,
        cacheDuration,
        cubeApi,
      });
      _dataProvider.introspect().then(() => {
        // handle any async usage of dataprovider after
        // it is first initialized
        onLoad && onLoad(_dataProvider);
        setDataProvider(_dataProvider);
      });
    }
  }, [auth.authToken]);
  return dataProvider;
};

export default initDataProvider;
