// @ts-nocheck
import React from 'react';
import bulletTrain from 'bullet-train-client/isomorphic';

export default bulletTrain;

// global queue for listeners, bulletTrain lib is global so makes sense to have vs. scoping
export const LISTENERS = [];
// store last set of args from onChange to call upon function registry
// to handle race conditions gracefully
let _lastChangeArgs = undefined;

// based on: https://stackoverflow.com/a/19101235/3281436
// converts a nested object to a flattened object with
// jsonpath keys, useful for setting traits
export const flattenObject = data => {
  const result = {};
  function recurse(cur, prop) {
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      result[prop] = cur.join(',');
      result[prop + '[*]'] = cur.join(',');
      const l = cur.length;
      for (let i = 0; i < l; i++) {
        recurse(cur[i], prop + '[' + i + ']');
      }
    } else {
      let isEmpty = true;
      for (let p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '.' + p : p);
      }
      if (isEmpty && prop) result[prop] = '{}';
    }
  }
  recurse(data, '');
  return result;
};


// helper to initialize with pubsub onChange and default settings
export const init = (auth, environmentID = process.env.BULLET_TRAIN_ENV, api = process.env.BULLET_TRAIN_API) => {
  // smart defaults
  api = api || 'https://bullet-train-api.colonynetworks.com/api/v1/';
  environmentID = environmentID || 'BegY4wqGrJCgAVUvMi6F9H';

  // use KC token to set identity and traits
  bulletTrain.identify(auth.sub);
  bulletTrain.init({
    environmentID,
    preventFetch: true, // preventFetch as we'll be setting traits shortly
    cacheFlags: true,
    onChange: (...args) => {
      _lastChangeArgs = args;
      LISTENERS.forEach(fn => fn(...args));
    },
    // needs trailing slash
    api: api.replace(/\/?$/, '/'),
  });
  bulletTrain.setTraits(flattenObject(auth));
  bulletTrain.startListening(5 * 1000); // update every 5s
};

// pub/sub
export const unsubscribe = fn => {
  const index = LISTENERS.indexOf(fn);
  if (index <= -1) return;
  LISTENERS.splice(index, 1);
};

export const subscribe = fn => {
  LISTENERS.push(fn);
  if (_lastChangeArgs) {
    // onChange has been called already, call fn with last args to
    // paper over any race conditions
    fn(_lastChangeArgs);
  }
  return () => unsubscribe(fn);
};

// functional interfaces
export const checkFlag = (name, fallback = null, enabled = true) => (bulletTrain.hasFeature(name) ? enabled : fallback);
export const checkVariants = (name, fallback = null, variants) => variants[bulletTrain.getValue(name)] || fallback;
export const checkFlagValue = (name, fallback) => bulletTrain.getValue(name) || fallback;

// react hooks
const subscribeHook = checkFn => (name, fallback = null, arg /* enabled | variants | undefined */) => {
  // generic decorator to setup react-hooks for a given `check` function
  const [value, setValue] = React.useState(checkFn(name, fallback, arg));
  React.useEffect(
    // IMPORTATNT: return to handle cleanup
    () => subscribe(() => setValue(checkFn(name, fallback, arg))),
    [name, fallback, arg]
  );
  return value;
};

export const useFlag = subscribeHook(checkFlag);
export const useVariants = subscribeHook(checkVariants);
export const useFlagValue = subscribeHook(checkFlagValue);

// Component
export const Flag = ({ name, fallback, children, ...props }) => {
  const Render = useFlag(name, fallback, children);
  return Render;
};
export const Variants = ({ name, fallback, variants, children = null, ...props }) => {
  const Render = useVariants(name, fallback || children, variants);
  return Render;
};
export const FlagValue = ({ name, fallback, children = null, ...props }) => {
  const Render = useFlagValue(name, fallback, children);
  return Render;
};

// aliases
export const BulletTrain = FlagValue;
export const RemoteConfig = FlagValue;
export const RemoteValue = FlagValue;
export const Feature = Flag;
export const Permission = Flag;
export const Experiment = Variants;
export const ABTest = ({ name, a, b }) => <Variants
  name={name}
  variants={{ a, b, A: a, B: b }}
  fallback={a}
/>;

// HOCs
export const withFeature = (name, Fallback) => (Component) => props => (
  <Feature name={name} fallback={<Fallback {...props} />} children={<Component {...props} />} />
);
export const withExperiment = (name, variants) => Component => props => (
  <Variants
    name={name}
    variants={Object.entries(variants)
      .map(([k, Variant]) => [k, <Variant {...props} />])
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {})
    }
    fallback={<Component {...props} />}
  />
);
