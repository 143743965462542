import React from 'react';
import styled from 'styled-components';
import { Button, Tooltip } from '@material-ui/core';

const StyledButton = styled(Button)`
  min-width: auto;
  transition: all 0.2s;
  height: 36px;
  width: 36px;

  svg {
    color: ${props => (props.color ? props.theme.palette[props.color].main : 'rgb(163, 175, 183)')};
    font-size: 16px;
  }

  &:hover {
    border-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.primary.main};

    svg {
      color: ${props => props.theme.palette.primary.main};
    }
  }
`;

const Container = styled.div`
  ${StyledButton} {
    min-width: auto;
    padding: 0;
  }
`;

interface ActionButtonPropsType {
  tooltip?: string;
  children: any;
  [key: string]: any;
}

const ActionButton = ({ tooltip, children, ...props }: ActionButtonPropsType) => (
  <Container>
    {tooltip ? (
      <Tooltip title={tooltip} arrow>
        <StyledButton variant="outlined" {...props}>
          {children}
        </StyledButton>
      </Tooltip>
    ) : (
      <StyledButton variant="outlined" {...props}>
        {children}
      </StyledButton>
    )}
  </Container>
);

export default ActionButton;
