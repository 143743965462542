import React, { useState } from 'react';
import { SelectInput, useTranslate } from 'react-admin';
import styled from 'styled-components';
import CollapseableSection from '../CollapseableSection';
import { TabContainer, InputsContainer } from './styles';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import { ColorInput, ImageInput } from '~/ui-components';

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  i {
    margin-bottom: 16px;
    font_size: 3rem;
  }
`;

const StyledLabel = styled.p`
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const StyledPlaceholderText = styled.p`
  &:first-letter {
    text-transform: uppercase;
  }
`;

const StyledSelectInput = styled(SelectInput)`
  text-transform: capitalize;
  .MuiSelect-root {
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const fontSizeOptions = [
  { id: 4, name: '4' },
  { id: 6, name: '6' },
  { id: 8, name: '8' },
  { id: 10, name: '10' },
  { id: 12, name: '12' },
  { id: 16, name: '16' },
  { id: 18, name: '18' },
  { id: 20, name: '20' },
  { id: 22, name: '22' },
  { id: 24, name: '24' },
  { id: 26, name: '26' },
  { id: 28, name: '28' },
  { id: 30, name: '30' },
  { id: 32, name: '32' },
  { id: 34, name: '34' },
  { id: 36, name: '36' },
  { id: 38, name: '38' },
  { id: 40, name: '40' },
]

const heightOptions = [
  { id: 40, name: '40px' },
  { id: 60, name: '60px' },
  { id: 80, name: '80px' },
  { id: 100, name: '100px' },
  { id: 120, name: '120px' },
  { id: 180, name: '180px' },
  { id: 240, name: '240px' },
  { id: 300, name: '300px' },
  { id: 360, name: '360px' },
  { id: 420, name: '420px' },
  { id: 480, name: '480px' },
]

const StyleTab = () => {
  const t = useTranslate();
  const [openSections, setOpenSections]: any = useState({
    logo: false,
    portal: false,
    button: false,
  });

  const fontWeightOptions = [
    { id: 'normal', name: t('cloudFi.portal_builder.style.choices.normal') },
    { id: 'bold', name: t('cloudFi.portal_builder.style.choices.bold') },
    { id: 'bolder', name: t('cloudFi.portal_builder.style.choices.bolder') },
    { id: 'lighter', name: t('cloudFi.portal_builder.style.choices.lighter') }
  ]

  const backgroundPositionOptions = [
    { id: 'left top', name: t('cloudFi.portal_builder.style.choices.top_left') },
    { id: 'center top', name: t('cloudFi.portal_builder.style.choices.top_center') },
    { id: 'right top', name: t('cloudFi.portal_builder.style.choices.top_right') },
    { id: 'center center', name: t('cloudFi.portal_builder.style.choices.center') },
    { id: 'left bottom', name: t('cloudFi.portal_builder.style.choices.bottom_left') },
    { id: 'center bottom', name: t('cloudFi.portal_builder.style.choices.bottom_center') },
    { id: 'right bottom', name: t('cloudFi.portal_builder.style.choices.bottom_right') },
  ]

  const backgroundSizeOptions = [
    { id: 'cover', name: t('cloudFi.portal_builder.style.choices.fill') },
    { id: 'contain', name: t('cloudFi.portal_builder.style.choices.contain') },
  ]

  const fontFamilyOptions = [
    { id: 'inherit', name: t('cloudFi.portal_builder.style.choices.inherit') },
    { id: 'arial', name: 'Arial' },
    { id: 'courier_new', name: 'Courier New' },
    { id: 'georgia', name: 'Georgia' },
    { id: 'impact', name: 'Impact' },
    { id: 'lucida_console', name: 'Lucida Console' },
    { id: 'tahoma', name: 'Tahoma' },
    { id: 'times_new_roman', name: 'Times New Roman' },
    { id: 'verdana', name: 'Verdana' },
    // { id: 'helvetica', name: 'Helvetica' },  //Font-family not working
    // { id: 'comic_sans', name: 'Comic Sans' }, //Font-family not working
    // { id: 'palatino_linotype', name: 'Palatino Linotype' }, //Font-family not working
    // { id: 'trebuchet_MS', name: 'Trebuchet MS' }, //Font-family not working
  ]

  const handleExpandToggle = (type: string) => {
    const newSections = { ...openSections };
    newSections[type] = !openSections[type];
    setOpenSections(newSections);
  };

  return (
    <TabContainer>
      <CollapseableSection open={openSections.logo} label="Logo" id="logo" handleToggle={handleExpandToggle}>
        <InputsContainer>
          <SelectInput
            source="portal_config.logo.max_height"
            name="portal_config.logo.max_height"
            choices={[{ id: '100%', name: t('cloudFi.portal_builder.style.actual_image_size') }, ...heightOptions]}
            initialValue={60}
            label={t('cloudFi.portal_builder.style.height')}
          />
          <ImageInput
            source="portal_config.logo.image"
            uploadText={t('cloudFi.portal_builder.style.upload_logo')}
            removeText={t('cloudFi.portal_builder.style.remove_logo')}
            height={160}
            initialValue="https://uploads.colonynetworks.net/colony_logo_horizontal.svg"
            Placeholder={() => (
              <Placeholder>
                <WallpaperIcon />
                <StyledPlaceholderText>{t('cloudFi.portal_builder.style.upload_logo_help_text')}</StyledPlaceholderText>
              </Placeholder>
            )}
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection open={openSections.portal} label={t('cloudFi.portal_builder.style.portal')} id="portal" handleToggle={handleExpandToggle}>
        <InputsContainer>
          <ImageInput
            source="portal_config.portal.background_image"
            uploadText={t('cloudFi.portal_builder.style.upload_background_image')}
            removeText={t('cloudFi.portal_builder.style.remove_background_image')}
            height={160}
            initialValue="None"
            Placeholder={() => (
              <Placeholder>
                <WallpaperIcon />
                <StyledPlaceholderText>{t('cloudFi.portal_builder.style.upload_background_help_text')}</StyledPlaceholderText>
              </Placeholder>
            )}
          />
        </InputsContainer>
        <InputsContainer>
          <StyledSelectInput
            source="portal_config.portal.background_size"
            name="portal_config.portal.background_size"
            choices={backgroundSizeOptions}
            initialValue={'cover'}
            label={t('cloudFi.portal_builder.style.background_size')}
          />
          <StyledSelectInput
            source="portal_config.portal.background_position"
            name="portal_config.portal.background_position"
            choices={backgroundPositionOptions}
            initialValue="center top"
            label={t('cloudFi.portal_builder.style.background_position')}
          />
        </InputsContainer>
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.background_color')}</StyledLabel>
          <ColorInput
            source="portal_config.portal.background_color"
            name="portal_config.portal.background_color"
            label={t('cloudFi.portal_builder.style.background_color')}
            initialValue="#f6f7f9"
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.header}
        label={t('common.header')}
        id="header"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <SelectInput
            source="portal_config.header.height"
            name="portal_config.header.height"
            choices={heightOptions}
            initialValue={100}
            label={t('cloudFi.portal_builder.style.height')}
          />
          <ImageInput
            source="portal_config.header.background_image"
            uploadText={t('cloudFi.portal_builder.style.upload_background_image')}
            removeText={t('cloudFi.portal_builder.style.remove_background_image')}
            height={160}
            initialValue="None"
            Placeholder={() => (
              <Placeholder>
                <WallpaperIcon />
                <StyledPlaceholderText>{t('cloudFi.portal_builder.style.upload_background_help_text')}</StyledPlaceholderText>
              </Placeholder>
            )}
          />
        </InputsContainer>
        <InputsContainer>
          <StyledSelectInput
            source="portal_config.header.background_size"
            name="portal_config.header.background_size"
            choices={backgroundSizeOptions}
            initialValue={'cover'}
            label={t('cloudFi.portal_builder.style.background_size')}
          />
          <StyledSelectInput
            source="portal_config.header.background_position"
            name="portal_config.header.background_position"
            choices={backgroundPositionOptions}
            initialValue="center"
            label={t('cloudFi.portal_builder.style.background_position')}
          />
        </InputsContainer>
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.background_color')}</StyledLabel>
          <ColorInput
            source="portal_config.header.background_color"
            name="portal_config.header.background_color"
            label={t('cloudFi.portal_builder.content.header.background_color')}
            initialValue="transparent"
          />
        </InputsContainer>
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.text_color')}</StyledLabel>
          <ColorInput
            source="portal_config.header.color"
            name="portal_config.header.color"
            label={t('cloudFi.portal_builder.content.header.color')}
            initialValue="#000"
          />
          <SelectInput
            source="portal_config.header.font_size"
            name="portal_config.header.font_size"
            choices={fontSizeOptions}
            initialValue={16}
            label={t('cloudFi.portal_builder.style.font_size')}
          />
          <StyledSelectInput
            source="portal_config.header.font_weight"
            name="portal_config.header.font_weight"
            choices={fontWeightOptions}
            initialValue="normal"
            label={t('cloudFi.portal_builder.style.font_weight')}
          />
          <StyledSelectInput
            source="portal_config.header.font_family"
            name="portal_config.header.font_family"
            choices={fontFamilyOptions}
            initialValue="inherit"
            label={t('cloudFi.portal_builder.style.font_family')}
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.title}
        label={t('common.title')}
        id="title"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.text_color')}</StyledLabel>
          <ColorInput
            source="portal_config.main_content.title.color"
            name="portal_config.main_content.title.color"
            label={t('cloudFi.portal_builder.mainContent.link_color')}
            initialValue="#000"
          />
        </InputsContainer>
        <InputsContainer>
          <SelectInput
            source="portal_config.main_content.title.font_size"
            name="portal_config.main_content.title.font_size"
            choices={fontSizeOptions}
            initialValue={32}
            label={t('cloudFi.portal_builder.style.font_size')}
          />
          <StyledSelectInput
            source="portal_config.main_content.title.font_weight"
            name="portal_config.main_content.title.font_weight"
            choices={fontWeightOptions}
            initialValue="bold"
            label={t('cloudFi.portal_builder.style.font_weight')}
          />
          <StyledSelectInput
            source="portal_config.main_content.title.font_family"
            name="portal_config.main_content.title.font_family"
            choices={fontFamilyOptions}
            initialValue="inherit"
            label={t('cloudFi.portal_builder.style.font_family')}
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.main_content}
        label={t('cloudFi.portal_builder.content.main_content')}
        id="main_content"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <ImageInput
            source="portal_config.main_content.background_image"
            uploadText={t('cloudFi.portal_builder.style.upload_background_image')}
            removeText={t('cloudFi.portal_builder.style.remove_background_image')}
            height={160}
            initialValue="None"
            Placeholder={() => (
              <Placeholder>
                <WallpaperIcon />
                <StyledPlaceholderText>{t('cloudFi.portal_builder.style.upload_background_help_text')}</StyledPlaceholderText>
              </Placeholder>
            )}
          />
        </InputsContainer>
        <InputsContainer>
          <StyledSelectInput
            source="portal_config.main_content.background_size"
            name="portal_config.main_content.background_size"
            choices={backgroundSizeOptions}
            initialValue={'cover'}
            label={t('cloudFi.portal_builder.style.background_size')}
          />
          <StyledLabel>{t('cloudFi.portal_builder.style.background_color')}</StyledLabel>
          <ColorInput
            source="portal_config.main_content.background_color"
            name="portal_config.main_content.background_color"
            label={t('cloudFi.portal_builder.style.background_color')}
            initialValue="#4269E1"
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.mainContentText}
        label={t('cloudFi.portal_builder.style.content_text')}
        id="mainContentText"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.text_color')}</StyledLabel>
          <ColorInput
            source="portal_config.main_content.content_text.color"
            name="portal_config.main_content.content_text.color"
            label={t('cloudFi.portal_builder.style.color')}
            initialValue="#FFFFFF"
          />
        </InputsContainer>
        <InputsContainer>
          <SelectInput
            source="portal_config.main_content.content_text.font_size"
            name="portal_config..main_content.content_text.font_size"
            choices={fontSizeOptions}
            initialValue={16}
            label={t('cloudFi.portal_builder.style.font_size')}
          />
          <StyledSelectInput
            source="portal_config.main_content.content_text.font_weight"
            name="portal_config.main_content.content_text.font_weight"
            choices={fontWeightOptions}
            initialValue="normal"
            label={t('cloudFi.portal_builder.style.font_weight')}
          />
          <StyledSelectInput
            source="portal_config.main_content.content_text.font_family"
            name="portal_config.main_content.content_text.font_family"
            choices={fontFamilyOptions}
            initialValue="inherit"
            label={t('cloudFi.portal_builder.style.font_family')}
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.button}
        label={t('common.button')}
        id="button"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.text_color')}</StyledLabel>
          <ColorInput
            source="portal_config.main_content.connect_button.color"
            name="portal_config.main_content.connect_button.color"
            label={t('cloudFi.portal_builder.style.color')}
            initialValue="FFFFFF"
          />
        </InputsContainer>
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.background_color')}</StyledLabel>
          <ColorInput
            source="portal_config.main_content.connect_button.background_color"
            name="portal_config.main_content.connect_button.background_color"
            label={t('cloudFi.portal_builder.style.background_color')}
            initialValue="#4269E1"
          />
        </InputsContainer>
        <InputsContainer>
          <SelectInput
            source="portal_config.main_content.connect_button.font_size"
            name="portal_config.main_content.connect_button.font_size"
            choices={fontSizeOptions}
            initialValue={16}
            label={t('cloudFi.portal_builder.style.font_size')}
          />
          <StyledSelectInput
            source="portal_config.main_content.connect_button.font_weight"
            name="portal_config.main_content.connect_button.font_weight"
            choices={fontWeightOptions}
            initialValue="normal"
            label={t('cloudFi.portal_builder.style.font_weight')}
          />
          <StyledSelectInput
            source="portal_config.main_content.connect_button.font_family"
            name="portal_config.main_content.connect_button.font_family"
            choices={fontFamilyOptions}
            initialValue="inherit"
            label={t('cloudFi.portal_builder.style.font_family')}
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.legal}
        label={t('cloudFi.portal_builder.content.legal')}
        id="legal"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.content.link_color')}</StyledLabel>
          <ColorInput
            source="portal_config.main_content.legal.link_color"
            name="portal_config.main_content.legal.link_color"
            label={t('cloudFi.portal_builder.content.link_color')}
            initialValue="#4269E1"
          />
        </InputsContainer>
        <InputsContainer>
          <StyledSelectInput
            source="portal_config.main_content.legal.font_family"
            name="portal_config.main_content.legal.font_family"
            choices={fontFamilyOptions}
            initialValue="inherit"
            label={t('cloudFi.portal_builder.style.font_family')}
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.footer}
        label={t('common.footer')}
        id="footer"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <SelectInput
            source="portal_config.footer.height"
            name="portal_config.footer.height"
            choices={heightOptions}
            initialValue={100}
            label={t('cloudFi.portal_builder.style.height')}
          />
          <ImageInput
            source="portal_config.footer.background_image"
            uploadText={t('cloudFi.portal_builder.style.upload_background_image')}
            removeText={t('cloudFi.portal_builder.style.remove_background_image')}
            height={160}
            initialValue="None"
            Placeholder={() => (
              <Placeholder>
                <WallpaperIcon />
                <StyledPlaceholderText>{t('cloudFi.portal_builder.style.upload_background_help_text')}</StyledPlaceholderText>
              </Placeholder>
            )}
          />
          <StyledSelectInput
            source="portal_config.header.background_size"
            name="portal_config.header.background_size"
            choices={backgroundSizeOptions}
            initialValue={'cover'}
            label={t('cloudFi.portal_builder.style.background_size')}
          />
          <StyledSelectInput
            source="portal_config.footer.background_position"
            name="portal_config.footer.background_position"
            choices={backgroundPositionOptions}
            initialValue="bottom_center"
            label={t('cloudFi.portal_builder.style.background_position')}
          />
        </InputsContainer>
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.background_color')}</StyledLabel>
          <ColorInput
            source="portal_config.footer.background_color"
            name="portal_config.footer.background_color"
            label={t('cloudFi.portal_builder.content.footer.background_color')}
            initialValue="transparent"
          />
        </InputsContainer>
        <InputsContainer>
          <StyledLabel>{t('cloudFi.portal_builder.style.text_color')}</StyledLabel>
          <ColorInput
            source="portal_config.footer.color"
            name="portal_config.footer.color"
            label={t('cloudFi.portal_builder.content.footer.color')}
            initialValue="#000"
          />
        </InputsContainer>
        <InputsContainer>
          <SelectInput
            source="portal_config.footer.font_size"
            name="portal_config.footer.font_size"
            choices={fontSizeOptions}
            initialValue={16}
            label={t('cloudFi.portal_builder.style.font_size')}
          />
          <StyledSelectInput
            source="portal_config.footer.font_weight"
            name="portal_config.footer.font_weight"
            choices={fontWeightOptions}
            initialValue="normal"
            label={t('cloudFi.portal_builder.style.font_weight')}
          />
          <StyledSelectInput
            source="portal_config.footer.font_family"
            name="portal_config.footer.font_family"
            choices={fontFamilyOptions}
            initialValue="inherit"
            label={t('cloudFi.portal_builder.style.font_family')}
          />
        </InputsContainer>
      </CollapseableSection>
    </TabContainer>
  );
};

export default StyleTab;
