import React from 'react';

import { SnackbarProvider } from 'notistack';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { AuthProvider } from './keycloak/AuthContext';
import GlobalStyles from '~/common/styles/GlobalStyles';
import defaultTheme, { overrideTheme } from '~/common/theme';

const Providers = ({ themeOverrides, children }: any) => {
  const theme = overrideTheme(defaultTheme, themeOverrides);

  return (
    <>
      <GlobalStyles />
      <SnackbarProvider maxSnack={100} autoHideDuration={4000}>
        {/* Save authToken, roles & username in Context for use in other components */}
        <AuthProvider>
          {/* Theming */}
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>{children({ providersTheme: theme })}</ThemeProvider>
          </MuiThemeProvider>
        </AuthProvider>
      </SnackbarProvider>
    </>
  );
};

export default Providers;
