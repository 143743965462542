import React, { useContext } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Notification, setSidebarVisibility } from 'react-admin';
import { isBrowser } from '~/common/featureTests';
import AuthContext from '~/Admin/keycloak/AuthContext';
import AppBar from './AppBar';
import Sidebar from './Sidebar';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  height: 100%;
  background-color: ${props => props.theme.palette.background.default};
  position: relative;
`;

const AppFrame = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100%;
`;

const ContentWithSideBar = styled.main`
  display: flex;
  flex-grow: 1;
  margin-top: 64px;
  height: 100%;

  .MuiDrawer-root {
    display: ${(props: any) => (props.isHomePage ? 'none' : 'block')};
  }
`;

const Content = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    margin-top: 0;
    height: 100%;
    max-height: calc(100vh - 64px);
    max-width: 100%;
    overflow: auto;
    padding: ${theme.spacing(3)}px;
    ${theme.breakpoints.down('sm')} { {
      padding: 0px;
    }
`}
`;

const MyNotification = (props: any) => <Notification {...props} autoHideDuration={100000} />;

const Layout = ({ Navbar = AppBar }: any) => ({ children, dashboard, logout, title }: any) => {
  const auth: any = useContext(AuthContext);
  const dispatch = useDispatch();
  // @ts-ignore - admin not in default State
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const isHomePage = isBrowser && (window.location.pathname === '/' || window.location.pathname.startsWith('/events'));

  React.useEffect(() => {
    dispatch(setSidebarVisibility(true));
  }, [setSidebarVisibility]);

  return (
    <Root>
      <AppFrame>
        <Navbar title={title} open={open} logout={logout} />
        {/* @ts-ignore */}
        <ContentWithSideBar isHomePage={isHomePage}>
          {auth.roles.includes('viewer') && <Sidebar logout={logout} hasDashboard={!!dashboard} />}
          <Content>{children}</Content>
        </ContentWithSideBar>
        <MyNotification />
      </AppFrame>
    </Root>
  );
};

export default Layout;
