import React from 'react';
import styled from 'styled-components';
import { Edit } from 'react-admin';
import PortalBuilder from '~/Components/PortalBuilder';

const StyledEdit = styled(Edit)`
  height: 100%;
  margin: 0;
  > div {
    height: 100%;
    margin: 0;
    > .MuiPaper-root {
      box-shadow: none;
    }
  }
`;

export const PortalEditor = ({ resource, ...props }: any) => {
  // const onCancel = () => props.history.push(`/site`);
  return (
    <StyledEdit resource={resource} actions={null} undoable={false} {...props}>
      <PortalBuilder onCancel={() => props.history.push(`/captive_portal_v3`)} />
    </StyledEdit>
  );
};

export default PortalEditor;
