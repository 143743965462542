// @ts-nocheck
import { Field } from '@api-platform/api-doc-parser/lib/Field';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';
import keycloak from '../../../keycloak/index';

interface DefinitionType {
  properties: {
    [key: string]: {
      format: string;
      type: string;
      maxLength?: number;
      description?: string;
    };
  };
  required: string[];
}

interface SchemaType {
  definitions: {
    [key: string]: DefinitionType;
  };
}

export const removeTrailingSlash = (url: string): string => {
  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }
  return url;
};

const ALLOWED_VIEWER_RESOURCES = [
  'dns_service',
  'device_v3',
  'site',
  'site_type', // HACK to make site creation work.
  'captive_portal_v3',
  'signaltest_test',
  'wifi_service_v3',
  'company',
  'company_id',
  'probe',
  'service_firewall',
  'events',
  'notify_rule',
  'threshold_rule',
  // 'ssid',
].filter((resource: string) => {
  // TODO find global way to support env variables without TS errors.
  if (process.env.HIDDEN_RESOURCES) {
    // @ts-ignore env var
    const hiddenResources: string[] = process.env.HIDDEN_RESOURCES.split(',');
    return !hiddenResources.includes(resource);
  }
  return true;
});

const filterResources = (pathName: string, roles: string[]) => {
  if (roles.includes('superadmin')) {
    return true;
  }

  let allowedResources: string[] = ALLOWED_VIEWER_RESOURCES;
  // DIRTY HACK too make signaltest show up for CTC users.
  if (pathName === 'signaltest_test' && keycloak.tokenParsed.colony_has_signaltest) {
    return true;
  } else if (pathName === 'signaltest_test') {
    return false;
  }

  return allowedResources.includes(pathName);
};

export default function (response: SchemaType, entrypointUrl: string, roles: string[]): Resource[] {
  const paths: string[] = Object.keys(response.definitions).filter((pathName: string) =>
    filterResources(pathName, roles)
  );

  const resources = paths.map((pathName: string) => {
    const definition: DefinitionType = response.definitions[pathName] ? response.definitions[pathName] : {};
    const url = `${removeTrailingSlash(entrypointUrl)}/${pathName}`;

    const fields = Object.keys(definition.properties)
      .map((fieldName: string) => {
        if (!definition.properties) return null;
        const property = definition.properties[fieldName];

        let referenceTable;
        if (property.description && property.description.includes('Foreign Key')) {
          referenceTable = property.description.split("'")[1];
        }

        // DIRTY HACK TO MEET DEADLINE... Please give me time to do things properly 😢
        if (pathName === 'signaltest_test') {
          if (
            fieldName !== 'date' &&
            fieldName !== 'site_id' &&
            fieldName !== 'signal' &&
            fieldName !== 'score' &&
            fieldName !== 'bw_down' &&
            fieldName !== 'bw_up' &&
            fieldName !== 'connection_type' &&
            fieldName !== 'noise'
          ) {
            return null;
          }
        }

        const field = new Field(fieldName, {
          // id: 'id',
          // range: null,
          reference: null, // the default reference only works with Hydra.
          // @ts-ignore
          referenceTable,
          required: definition.required ? definition.required.includes(fieldName) : false,
          // @ts-ignore format not on FieldOptionsType
          format: property.format,
          type: property.type,
          maxLength: property.maxLength,
          description: property.description,
        });

        return field;
      })
      .filter(a => a);

    return new Resource(pathName, url, {
      id: null,
      title: pathName,
      fields: fields,
      readableFields: fields,
      writableFields: fields,
      parameters: [],
      getParameters: () => {
        return Promise.resolve();
      },
    });
  });

  return resources;
}
