import React from 'react';
import styled from 'styled-components';
import { sanitizeEmptyValues } from 'react-admin';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
// import { CardContent, Typography, Box } from '@material-ui/core';

const Container = styled.div`
  padding: 24px;
`;

const defaultSubscription = {
  submitting: true,
  pristine: true,
  valid: true,
  invalid: true,
};

// the parent component (Edit or Create) injects these props to their child
const ColonyForm = ({ record, save, version, children }: any) => {
  const submit = (values: any) => {
    // React-final-form removes empty values from the form state.
    // To allow users to *delete* values, this must be taken into account
    save(sanitizeEmptyValues(record, values));
  };

  return (
    <Container>
      <Form
        initialValues={record}
        onSubmit={submit}
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
          ...arrayMutators,
        }} // necessary for ArrayInput
        subscription={defaultSubscription} // don't redraw entire form each time one field changes
        key={version} // support for refresh button
        keepDirtyOnReinitialize
        render={({ form }: any) => {
          return <div>{React.cloneElement(children, { form })}</div>;
        }}
      />
    </Container>
  );
};

export default ColonyForm;
