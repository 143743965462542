import React from 'react';
// import styled from 'styled-components';
import { TextInput, BooleanInput, NumberInput, ReferenceInput, AutocompleteInput, useTranslate, regex } from 'react-admin';
// import { resourceToWord } from '~/common/utils/resource';
import { macAddressRegex } from '~/common/regex';

interface FieldType {
  description?: string;
  id: string;
  name: string;
  range: any;
  reference: null;
  referenceTable: string;
  required: boolean;
  format?: string;
  type: string;
}

interface FieldOverridesType {
  [key: string]: {
    [key: string]: {
      [key: string]: string | boolean;
    };
  };
}

export const FIELD_OVERRIDES: FieldOverridesType = {
  edit: {
    probe: {
      remote_id: false,
    },
    company: {
      site_access_level_id: false,
      parent_company_uid: false,
    },
    device_v3: {
      type: 'disabled',
      serial_number: 'disabled',
      hostname: 'disabled',
      mac: 'disabled',
      nas_id: 'disabled',
    },
    site: {
      site_type_id: false,
      description: false,
      notes: false,
      parent_name: false,
      subscription_bundle_id: false,
    },
  },
  create: {},
};

const ALWAYS_HIDDEN_FIELDS = ['search'];
const GENERIC_HIDDEN_FIELDS = ['created_by', 'modified_by', 'created_date', 'modified_date', 'utc_offset'];

export const filterFields = (field: FieldType, resource: string, auth: any, type: string) => {
  if (ALWAYS_HIDDEN_FIELDS.includes(field.name)) {
    return false;
  }
  if (auth.roles.includes('superadmin')) return true;

  if (FIELD_OVERRIDES[type][resource] && typeof FIELD_OVERRIDES[type][resource][field.name] === 'boolean') {
    return FIELD_OVERRIDES[type][resource][field.name];
  }

  if (GENERIC_HIDDEN_FIELDS.includes(field.name)) {
    return false;
  }

  return true;
};

export const createInput = (field: FieldType, spec: any, resource: string, type: string) => {
  const t = useTranslate();
  // The id field is manually added in createInputs so it's always first.
  if (field.name === 'id') return null;
  const disabled =
    FIELD_OVERRIDES[type][resource] &&
    FIELD_OVERRIDES[type][resource][field.name] &&
    FIELD_OVERRIDES[type][resource][field.name] === 'disabled';

  if (field.referenceTable && spec[field.referenceTable]) {
    const referenceFieldNames = spec[field.referenceTable].fields.map(
      (referenceField: FieldType) => referenceField.name
    );
    let referenceSource: string = 'id'; // default source if nothing better exists.
    // if name exists on the other table use name.
    if (referenceFieldNames.includes('name')) {
      referenceSource = 'name';
    }
    // else if (referenceFieldNames.includes('ssid_name')) {
    //   referenceSource = 'ssid_name';
    // }

    if (referenceSource === 'id') {
      return <NumberInput key={field.name} source={field.name} disabled={disabled} />;
    }

    // if (referenceSource !== 'name') return null;

    return (
      <ReferenceInput
        key={field.name}
        source={field.name}
        reference={field.referenceTable}
        disabled={disabled}
        filterToQuery={(searchText: string) => {
          if (!searchText) return {};

          const filter = {};
          // @ts-ignore
          filter[referenceSource] = searchText;
          return filter;
        }}
        allowEmpty
      >
        <AutocompleteInput optionsText={referenceSource} />
      </ReferenceInput>
    );
  }

  if (field.type === 'integer') return <NumberInput key={field.name} source={field.name} disabled={disabled} />;
  if (field.type === 'boolean') return <BooleanInput key={field.name} source={field.name} disabled={disabled} />;
  
  const validateMacAddress = regex(macAddressRegex, t('message.validation.must_be_a_valid_mac_address'));

  if (field.type === 'string' && field.name === 'mac_address') return <TextInput key="mac_address" source="mac_address" validate={validateMacAddress}/>;

  return <TextInput key={field.name} source={field.name} disabled={disabled} />;
};

const createInputs = ({
  spec,
  resource,
  auth,
  type = 'edit',
}: {
  spec: any;
  resource: string;
  auth: any;
  type?: string;
}) => {
  const t = useTranslate();
  const { fields } = spec[resource];
  const inputFields = [
    fields
      .filter((field: any) => filterFields(field, resource, auth, type))
      .map((field: FieldType) => createInput(field, spec, resource, type)),
  ];
  const validateMacAddress = regex(macAddressRegex, t('message.validation.must_be_a_valid_mac_address'));

  if (type === 'edit' && fields.filter((field: any) => field.name === 'id').length) {
    // add id field to the beginning of the Array so it's always first.
    inputFields.unshift(<TextInput key="id" source="id" disabled />);
  }

  if (resource === 'probe' && type === 'create' || type === 'edit') {
    // add mac address validation 
    inputFields.push(<TextInput key="mac_address" source="config.mac_address" validate={validateMacAddress}/>);
  }

  return inputFields;
};

export default createInputs;


