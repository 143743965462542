import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-admin';

import { Card, Button, Typography, Step, Stepper, StepLabel } from '@material-ui/core';
// import NextStepButton from './NextStepButton';
import StepForm from './StepForm';

interface stepType {
  name: string;
  label: string;
  content: any;
  contentIsForm?: boolean;
  defaultValues?: (a: any) => any;
}

interface WizardType {
  completeText: string;
  steps: stepType[];
  redirect: string;
  onSubmit: (values: any) => any;
}

const NavContainer = styled.div`
  padding: 24px;
`;

// const buildStepForm = () => () => <StepForm />;

const Wizard = ({ completeText, steps, onSubmit }: WizardType) => {
  const t = useTranslate();
  const [stepValues, setStepValues] = useState({});
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const currentStep = steps[activeStepIndex];
  const Content = currentStep && currentStep.content;

  const handleSubmit = () => {
    onSubmit(stepValues);
    setIsSubmitting(true);
  };

  const handleNext = () => {
    if (activeStepIndex === steps.length - 1) {
      handleSubmit();
    }
    setActiveStepIndex(activeStepIndex + 1);
  };

  const handleBack = () => setActiveStepIndex(activeStepIndex - 1);

  const onStepFormSubmit = (values: any) => {
    const newStepValues: any = {
      ...stepValues,
    };
    newStepValues[steps[activeStepIndex].name] = values;
    setStepValues(newStepValues);
    handleNext();
  };

  return (
    <>
      <Card>
        <Stepper activeStep={activeStepIndex}>
          {steps.map((step: any) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStepIndex < steps.length ? (
            <div style={{ height: '100%' }}>
              {currentStep.contentIsForm ? (
                <Content
                  values={stepValues}
                  onSave={onStepFormSubmit}
                  submitText={activeStepIndex === steps.length - 1 ? completeText : 'Next'}
                />
              ) : (
                <StepForm
                  onSubmit={onStepFormSubmit}
                  submitText={activeStepIndex === steps.length - 1 ? completeText : 'Next'}
                >
                  <Content values={stepValues} />
                </StepForm>
              )}
            </div>
          ) : (
            <div style={{ margin: '2em' }}>
              <Typography>{t('status.all_steps_completed')}.</Typography>
            </div>
          )}
        </div>
      </Card>
      <NavContainer>
        {activeStepIndex < steps.length ? (
          <>
            <Button disabled={activeStepIndex === 0 || isSubmitting} onClick={handleBack}>
              {t('action.back')}
            </Button>
            {/* <NextStepButton
              isSubmitting={isSubmitting}
              activeStepIndex={activeStepIndex}
              totalSteps={steps.length}
              completeText={completeText}
              handleNext={handleNext}
            /> */}
          </>
        ) : null}
      </NavContainer>
    </>
  );
};

export default Wizard;
