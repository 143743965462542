import React from 'react';
import styled from 'styled-components';
import { List, EditButton } from 'react-admin';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import PortalPreviewThumbnail from '~/Components/PortalBuilder/Preview/Thumbnail';

const StyledEditButton = styled(EditButton)``;
const StyledGridListTileBar = styled(GridListTileBar)`
  padding-right: 8px;

  ${StyledEditButton} {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const StyledList = styled(List)`
  margin: 0;
  > div {
    margin: 0;
    > .MuiPaper-root {
      box-shadow: none;
      background: none;
    }
  }
`;

const CPGrid = ({ ids = [], data = {}, basePath }: any) => (
  <GridList cellHeight={320} spacing={32}>
    {ids.map((id: string) => {
      if (!id) return null;

      return (
        <GridListTile key={id} style={{ width: '352px' }}>
          <PortalPreviewThumbnail zoom={0.7} key={id} record={data[id]} />
          <StyledGridListTileBar
            title={data[id].ssid_name}
            actionIcon={
              <StyledEditButton aria-label="edit portal" resource="company" basePath={basePath} record={data[id]} />
            }
          />
        </GridListTile>
      );
    })}
  </GridList>
);

export const CaptivePortalList = (props: any) => (
  <StyledList title="Captive portals" {...props}>
    <CPGrid />
  </StyledList>
);

export default CaptivePortalList;
