// @ts-nocheck
import PQueue from 'p-queue';
import fetchRetryDeco from 'fetch-retry';
// import LZString from 'lz-string';

// simple hash for cache
// const hashstr = s => {
//   let hash = 0;
//   if (s.length == 0) return hash;
//   for (let i = 0; i < s.length; i++) {
//     let char = s.charCodeAt(i);
//     hash = (hash << 5) - hash + char;
//     hash = hash & hash; // Convert to 32bit integer
//   }
//   return hash;
// };

// exponential backoff and global request concurrency conrtol
const fetchRetry = typeof fetch === 'function' ? fetchRetryDeco(fetch) : () => 0;
const MAX_CONCURRENT = 15;
const MAX_RETRIES = 10;
const queue = new PQueue({
  concurrency: MAX_CONCURRENT,
  carryoverConcurrencyCount: true,
  interval: 5 * 1000, // 5s
  intervalCap: MAX_CONCURRENT * 2,
});
const fetchQueuedRetry = (url: any, options: any) =>
  queue.add(() =>
    fetchRetry(url, {
      retryDelay: function(attempt /*error, response*/) {
        return Math.pow(2, attempt) * 1000; // exponential backoff
      },
      retryOn: function(attempt, error, response) {
        if (error) {
          console.error(error);
        }
        if (attempt > MAX_RETRIES) return false;
        // retry on any network error, or 4xx or 5xx status codes
        // @ts-ignore
        if (error !== null || response.status >= 500) {
          // validation shouldn't retry
          console.error(`retrying, attempt number ${attempt + 1}`);
          return true;
        }
      },
      ...options,
    }).then(response => {
      // simulate response like `fetchutils.fetchJson`
      return response
        .text()
        .then(text => ({
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        }))
        .then(({ status, statusText, headers, body }) => {
          let json;
          try {
            json = JSON.parse(body);
          } catch (e) {
            // not json, no big deal
          }
          if (status < 200 || status >= 300) {
            return Promise.reject(new Error((json && json.message) || statusText, status, json));
          }
          return Promise.resolve({
            status,
            headers,
            body,
            json,
          });
        });
    })
  );

// function roundToHour(date) {
//   if (!date) return;
//   const p = 60 * 60 * 1000; // milliseconds in an hour
//   return new Date(Math.floor(new Date(date).getTime() / p) * p); // round down always as we're looking forward in time from now
// }

export default fetchQueuedRetry;
