// @ts-nocheck
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TextInput, SelectInput, useTranslate} from 'react-admin';
import CollapseableSection from '../CollapseableSection';
import { TabContainer, InputsContainer } from './styles';
import RichTextInput from 'ra-input-rich-text';

const StyledLabel = styled.p`
  text-transform: capitalize;
  color: rgba(0, 0, 0, 0.54);
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const StyledSelectInput = styled(SelectInput)`
  text-transform: capitalize;
  .MuiSelect-root {
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;

const Button = styled.button`
  text-transform: capitalize;
  padding: 8px 16px;
  border: 1px solid #ddd;
`
interface ContentTabType {
  locale: string
}

const ContentTab = ({ locale }: ContentTabType) => {
  const t = useTranslate();

  const maxTitleLength = (max, message = t('cloudFi.portal_builder.content.max_40_characters')) =>
    value => value && value.length > max ? message : undefined;

  const maxMessageLength = (max, message = t('cloudFi.portal_builder.content.max_200_characters')) =>
    value => value && value.length > max ? message : undefined;

  const maxButtonLength = (max, message = t('cloudFi.portal_builder.content.max_25_characters')) =>
    value => value && value.length > max ? message : undefined;

  const validateTitleLength = [maxTitleLength(40)]
  const validateMessageLength = [maxMessageLength(200)]
  const validateButtonLength = [maxButtonLength(25)]

  const [privacyVisible, setPrivacyVisible] = useState(false)
  const [termsVisible, setTermsVisible] = useState(false)
  // TODO replace with UI lib dropdown sections.
  const [openSections, setOpenSections]: any = useState({
    mainContent: true,
    footer: false,
  });

  useEffect(() => {
    setPrivacyVisible(false)
    setTermsVisible(false)
  }, [locale])

  const handleExpandToggle = (type: string | number) => {
    const newSections = { ...openSections };
    newSections[type] = !openSections[type];
    setOpenSections(newSections);
  };


  return (
    <TabContainer>
      <CollapseableSection
        open={openSections.header}
        label={t('common.header')}
        id="header"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <TextInput
            source={`portal_config.locale.${locale}.header_text`}
            placeholder={t('cloudFi.portal_builder.content.placeholders.header_text')}
            label={t('common.header_text')}
            initialValue=""
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.mainContent}
        label={t('cloudFi.portal_builder.content.main_content')}
        id="mainContent"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <TextInput
            source={`portal_config.locale.${locale}.title`}
            placeholder={t('cloudFi.portal_builder.content.placeholders.title')}
            label={t('common.welcome_header')}
            validate={validateTitleLength}
            validateTrigger="onBlur"
            inputProps={{ maxLength: 41 }}
          />
          <StyledLabel>{t('common.welcome')}</StyledLabel>
          <TextInput
            source={`portal_config.locale.${locale}.content_text`}
            placeholder={t('cloudFi.portal_builder.content.placeholders.subtitle')}
            label={t('common.welcome_message')}
            validate={validateMessageLength}
            validateTrigger="onBlur"
            inputProps={{ maxLength: 201 }}
          />
          <StyledLabel>{t('common.connect')}</StyledLabel>
          <TextInput
            source={`portal_config.locale.${locale}.connect_button_label`}
            placeholder={t('cloudFi.portal_builder.content.placeholders.button_label')}
            label={t('cloudFi.portal_builder.content.connect_button_label')}
            validate={validateButtonLength}
            validateTrigger="onBlur"
            inputProps={{ maxLength: 26 }}
          />
        </InputsContainer>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.legal}
        label={t('cloudFi.portal_builder.content.legal')}
        id="legal"
        handleToggle={handleExpandToggle}
      >
        <CollapseableSection
          open={openSections.privacy_policy}
          label={t('common.privacy_policy')}
          id="privacy_policy"
          handleToggle={handleExpandToggle}
        >
          <InputsContainer>
            <StyledSelectInput
              source="portal_config.main_content.legal.privacy_policy_enabled"
              name="portal_config.main_content.legal.privacy_policy_enabled"
              choices={[
                { id: true, name: t('status.enabled') },
                { id: false, name: t('status.disabled') },
              ]}
              initialValue={false}
              label={t('cloudFi.portal_builder.content.enabled')}
            />
          </InputsContainer>
          <InputsContainer>
            <TextInput
              source={`portal_config.locale.${locale}.privacy_policy_name`}
              placeholder={t('common.privacy_policy')}
              label={t('cloudFi.portal_builder.content.privacy_link_text')}
            />
            {privacyVisible && <RichTextInput
              source={`portal_config.locale.${locale}.privacy_policy`}
              label={t('cloudFi.portal_builder.content.privacy_label')}
              placeholder={t('common.privacy_policy')}
            />}
            {!privacyVisible && <Button onClick={() => setPrivacyVisible(true)}>{t('cloudFi.portal_builder.content.edit_privacy_policy')}</Button>}
          </InputsContainer>
        </CollapseableSection>
        <CollapseableSection
          open={openSections.terms_and_conditions}
          label={t('common.terms_and_conditions')}
          id="terms_and_conditions"
          handleToggle={handleExpandToggle}
        >
          <InputsContainer>
            <StyledSelectInput
              source="portal_config.main_content.legal.terms_checkbox_required"
              name="portal_config.main_content.legal.terms_checkbox_required"
              choices={[
                { id: true, name: t('status.enabled') },
                { id: false, name: t('status.disabled') },
              ]}
              initialValue={true}
              label={t('cloudFi.portal_builder.content.require_acknowledgement')}
              helperText={t('cloudFi.portal_builder.content.terms_and_conditions_enabled')}
            />
          </InputsContainer>
          <InputsContainer>
            <TextInput
              source={`portal_config.locale.${locale}.terms_and_conditions_name`}
              placeholder={t('common.terms_and_conditions')}
              label={t('cloudFi.portal_builder.content.terms_link_text')}
            />
            {termsVisible && <RichTextInput
              source={`portal_config.locale.${locale}.terms_and_conditions`}
              label={t('cloudFi.portal_builder.content.terms_label')}
              placeholder={t('common.terms_and_conditions')}
            />}
            {!termsVisible && <Button onClick={() => setTermsVisible(true)}>{t('cloudFi.portal_builder.content.edit_terms_and_conditions')}</Button>}
          </InputsContainer>
        </CollapseableSection>
      </CollapseableSection>
      <CollapseableSection
        open={openSections.footer}
        label={t('common.footer')}
        id="footer"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <TextInput
            source={`portal_config.locale.${locale}.footer_text`}
            placeholder={t('cloudFi.portal_builder.content.placeholders.footer')}
            label={t('cloudFi.portal_builder.content.footer_label')}
          />
        </InputsContainer>
      </CollapseableSection>
    </TabContainer>
  );
};

export default ContentTab;
