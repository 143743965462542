import omit from 'lodash.omit';
import { stringify } from 'query-string';

import DataProvider, { ParamsType, ManyParamsType, ResponseType } from './DataProvider';

export default class PostgrestDataProvider extends DataProvider {
  buildRequestOptions(resource: string, params: ParamsType | ManyParamsType, method: string): any {
    const req = super.buildRequestOptions(resource, params, method);
    // tell postgrest to return exact count for get requests, and
    // to return the created/updated object for mutations
    req.headers.set('Prefer', 'count=exact,return=representation');
    return req;
  }

  buildUrl(resource: string, params: ParamsType | ManyParamsType, method: string) {
    const pagination = params.pagination ? params.pagination : { perPage: 10, page: 1 };
    const sort = params.sort ? params.sort : { field: undefined, order: undefined };
    // exclude our filters for now
    const filter = params.filter ? omit(params.filter, ['$colony']) : {};

    const { entrypoint } = this.apiSchema;

    if (['getOne', 'update', 'delete'].includes(method)) {
      // methods targetting a single ID, no query
      return `${entrypoint}/${resource}?id=eq.${params.id}`;
    }

    if (method.endsWith('Many')) {
      // methods targetting a many IDs, , no query
      const ids = (params as ManyParamsType).ids.join(',');
      const query = {
        id: `in.(${ids})`,
        select: params.select,
        limit: 100000,
      };
      return `${entrypoint}/${resource}?${stringify(query)}`;
    }

    if (method === 'create') {
      return `${entrypoint}/${resource}`;
    }

    if (method === 'getList') {
      const { page, perPage } = pagination;
      const { field, order } = sort;
      const filterKeys = Object.keys(filter);

      const query = {
        limit: isNaN(perPage) ? undefined : perPage,
        offset: (page - 1) * perPage,
        order: field && order ? `${field}.${order.toLowerCase()}` : undefined,
      };

      filterKeys.forEach((key: string) => {
        // you can't do searches on ids in postgrest. only strict equal
        if (key === 'id' || key.includes('_id') || key === 'active') {
          // @ts-ignore
          query[key] = `eq.${filter[key]}`;
        } else if (key === 'search') {
          // @ts-ignore
          const { fields, searchTerm } = filter[key];
          // @ts-ignore
          query['or'] = `(${fields.map((field: string) => `${field}.ilike.*${searchTerm}*`).join(',')})`;
        } else {
          // @ts-ignore
          query[key] = `ilike.*${filter[key]}*`;
        }
      });

      return `${entrypoint}/${resource}?${stringify(query)}`;
    }

    if (method === 'getManyReference') {
      const { page, perPage } = pagination;
      const { field, order } = sort;
      const query = {
        limit: isNaN(perPage) ? undefined : perPage,
        offset: (page - 1) * perPage,
        order: field && order ? `${field}.${order.toLowerCase()}` : undefined,
        ...Object.keys(filter).reduce(
          (acc: any, k: string) => ({
            ...acc,
            // @ts-ignore
            [k]: `eq.${filter[k]}`,
          }),
          { [params.target]: `eq.${params.id}` }
        ),
      };
      return `${entrypoint}/${resource}?${stringify(query)}`;
    }
  }

  transformResponse(resource: string, params: ParamsType | ManyParamsType, method: string, response: ResponseType) {
    const transformedResponse: any = super.transformResponse(resource, params, method, response);
    if (method.startsWith('get')) {
      const contentRange = response.headers.get('content-range');
      if (contentRange) {
        transformedResponse.total = parseInt(contentRange.split('/').pop(), 10);
      }
    }
    if (['update', 'create', 'getOne'].includes(method)) {
      // postgrest returns arrays vs. single obj
      transformedResponse.data = transformedResponse.data[0];
    }
    return transformedResponse;
  }
}
