// @ts-nocheck
import * as React from 'react';
import MuiTab from '@material-ui/core/Tab';
import classnames from 'classnames';
import { FormInput, useTranslate } from 'react-admin';

const sanitizeRestProps = ({ contentClassName, label, icon, value, translate, ...rest }) => rest;

const hiddenStyle = { display: 'none' };

const FormTab = ({
  basePath,
  className,
  contentClassName,
  children,
  hidden,
  icon,
  intent,
  label,
  margin,
  record,
  resource,
  variant,
  value,
  ...rest
}) => {
  const translate = useTranslate();

  const renderHeader = () => (
    <MuiTab
      key={label}
      label={translate(label, { _: label })}
      value={value}
      icon={icon}
      className={classnames('form-tab', className)}
      onChange={onClick}
      {...sanitizeRestProps(rest)}
    />
  );

  const renderContent = () => (
    <span style={hidden ? hiddenStyle : null} className={contentClassName}>
      {React.Children.map(
        children,
        input =>
          input && (
            <FormInput
              basePath={basePath}
              input={input}
              record={record}
              resource={resource}
              variant={input.props.variant || variant}
              margin={input.props.margin || margin}
            />
          )
      )}
    </span>
  );

  return intent === 'header' ? renderHeader() : renderContent();
};

FormTab.displayName = 'FormTab';

export default FormTab;
