import React from 'react';
import styled from 'styled-components';
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  EditButton,
  // need to access context vs. useDataProvider as "use" returns a proxy
  DataProviderContext,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { RealTimeShow } from '@react-admin/ra-realtime';

import { DataProvider } from '~/Admin/data';
import { resourceToWord } from '~/common/utils/resource';

const StyledShow = styled(Show)`
  .ra-field {
    margin-bottom: 16px;
    min-height: 52px;
    padding: 8px 16px;
    border-radius: 4px;
    transition: all 0.2s;

    &:hover {
      background-color: ${props => props.theme.palette.grey[150]};
    }
    > div {
      margin-top: 0;
      margin-bottom: 0;
      .MuiInputLabel-shrink {
        transform: translate(0, -1px) scale(0.75);
      }
      > div {
        padding: 0;

        .MuiChip-root {
          margin: 0;
          padding: 8px;
        }
      }
    }
  }
`;

const RealTimeStyledShow = styled(RealTimeShow)`
  .ra-field {
    margin-bottom: 16px;
    min-height: 52px;
    padding: 8px 16px;
    border-radius: 4px;
    transition: all 0.2s;

    &:hover {
      background-color: ${props => props.theme.palette.grey[150]};
    }
    > div {
      margin-top: 0;
      margin-bottom: 0;
      .MuiInputLabel-shrink {
        transform: translate(0, -1px) scale(0.75);
      }
      > div {
        padding: 0;

        .MuiChip-root {
          margin: 0;
          padding: 8px;
        }
      }
    }
  }
`;

const Actions = ({ auth, basePath, data, readOnly }: any) => (
  <TopToolbar>
    <ListButton />
    {!readOnly && auth.roles.includes('editor') ? <EditButton basePath={basePath} record={data} /> : null}
  </TopToolbar>
);

const Header = ({ resource }: any) => (
  <Typography variant="h6">{resourceToWord({ resource, plural: false })}</Typography>
);

const ShowView = ({ layoutProps = {}, resource, actions, addHeader = false, className, ...props }: any) => {
  const dataProvider: DataProvider = React.useContext(DataProviderContext);
  let ShowController = StyledShow;
  if (dataProvider.isRT(resource)) {
    ShowController = RealTimeStyledShow;
  }
  return (
    <ShowController
      resource={resource}
      actions={
        actions === undefined ? (
          <Actions readOnly={dataProvider.isReadOnly(resource)} auth={dataProvider.auth} />
        ) : (
          actions
        )
      }
      {...props}
    >
      <SimpleShowLayout className={className} {...layoutProps}>
        {addHeader ? <Header resource={resource} /> : null}
        {dataProvider.createFields(resource, props)}
      </SimpleShowLayout>
    </ShowController>
  );
};

export default ShowView;
