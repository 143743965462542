import { createGlobalStyle } from 'styled-components';
import cssReset from './cssReset';
import typography from './typography';

const GlobalStyles = createGlobalStyle`
${cssReset}
${typography}

html, body {
  height: 100%;
  margin: 0;
}

* {
  box-sizing: border-box;
}

.MuiInputLabel-root  {
  text-transform: capitalize;
}

#root {
  height: 100%;
  > div {
    height: 100%;
  }
}
`;

export default GlobalStyles;
