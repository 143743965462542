import React from 'react';
import { useRefresh, useTranslate } from 'react-admin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionButton from './ActionButton';

const RefreshButton = () => {
  const t = useTranslate();
  const refresh = useRefresh();
  return (
    <ActionButton tooltip={t('action.refresh')} onClick={refresh}>
      <FontAwesomeIcon icon="sync-alt" />
    </ActionButton>
  );
};

export default RefreshButton;
