// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import PortalPreview from './Themes/ClickToAccept';
import backwardsCompatibilityTransformer from '../backwardsCompatibilityTransformer'

const ThumbnailContainer = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  zoom: ${p => p.zoom};
  height: ${p => p.height}px;
  width: 100%;
  overflow: hidden;
`;

const PortalPreviewThumbnail = ({ zoom = 0.4, height = 400, record, ...props }) => {
  if (!record || !record.portal_config) {
    return null
  }

  const portalRecord = backwardsCompatibilityTransformer(record);

  return (
    <ThumbnailContainer zoom={zoom} height={height}>
      <PortalPreview record={portalRecord} {...props} locale="en" />
    </ThumbnailContainer>
  )
};

export default PortalPreviewThumbnail;
