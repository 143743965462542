import { css } from 'styled-components'

const contentMixin = css`
    .content {
      padding: 24px;
      width: 100%;
      max-width: 480px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color:  ${(p: any) => p.record.portal_config.main_content?.background_color || '#ffffff'};
      background-image: url(${(p: any) => p.record.portal_config.main_content?.background_image || ''});
      background-repeat: no-repeat;
      background-size: ${(p: any) => p.record.portal_config.main_content?.background_size || 'cover'};
      border-radius: 4px;
      border: 1px solid #f0f0f0;
      box-shadow: rgba(99, 99, 99, 0.05) 0px 2px 8px 0px;
    }
    
    .content-title {
      color: ${(p: any) => p.record.portal_config.main_content?.title?.color || 'inherit'};
      font-family: ${(p: any) => p.record.portal_config.main_content?.title?.font_family || 'inherit'};
      font-size: ${(p: any) => p.record.portal_config.main_content?.title?.font_size || 32}px;
      font-weight: ${(p: any) => p.record.portal_config.main_content?.title?.font_weight || 'inherit'};
      margin-bottom: 16px;
      text-align: center;
    }
    
    .content-text {
      color: ${(p: any) => p.record.portal_config.main_content?.content_text?.color || 'inherit'};
      font-family: ${(p: any) => p.record.portal_config.main_content?.content_text?.font_family || 'inherit'};
      font-size: ${(p: any) => p.record.portal_config.main_content?.content_text?.font_size || 16}px;
      font-weight: ${(p: any) => p.record.portal_config.main_content?.content_text?.font_weight || 'inherit'}};
      margin: 0;
      margin-bottom: 32px;
      text-align: center;
    }
    
    .accept-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 8px;
    }

    .privacy-section {
      margin-bottom: 8px;
    }
    
    .privacy-section-hidden {
      display: none;
    }
    
    .legal-modal-link {
      color: ${(p: any) => p.record.portal_config.main_content?.legal?.link_color || 'blue'};
    }
`

export default contentMixin