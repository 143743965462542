import React, { useState } from 'react';
import styled from 'styled-components';
import {
  portalMixin,
  buttonMixin,
  contentMixin,
  checkboxMixin,
  headerMixin,
  logoMixin,
  footerMixin,
  modalMixin,
} from './css'

interface RecordType {
  detect_lang?: boolean | null;
  id: number;
  portal_config: {
    [key: string]: any;
  };
  radius_policy?: any;
  realm: string;
  ssid_name: string;
}

const PreviewContainer = styled.div`
    font-family: montserrat, sans-serif;
    width: 100%;
    ${portalMixin}
    ${headerMixin}
    ${logoMixin}
    ${contentMixin}
    ${checkboxMixin}
    ${buttonMixin}
    ${footerMixin}
    ${modalMixin}
`;


interface PreviewType {
  record: RecordType
  locale: string
}


// @ts-ignore
const Preview = ({ record = { portal_config: {} }, locale }: PreviewType) => {
  const [termsAcknowledged, setTermsAcknowledged]: any = useState(true);
  const [termsModalVisible, setTermsModalVisible] = useState(false)
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false)
  const portal_config = record.portal_config || {};

  const toggleCheckbox = () => setTermsAcknowledged(!termsAcknowledged)
  const toggleTermsModal = (e: any) => {
    e.preventDefault()
    setTermsModalVisible(!termsModalVisible)
  }
  const togglePrivacyModal = (e: any) => {
    e.preventDefault()
    setPrivacyModalVisible(!privacyModalVisible)
  }
  const closeModal = (e: any) => {
    e.preventDefault()
    setPrivacyModalVisible(false)
    setTermsModalVisible(false)
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    alert('connected to wifi');
  };

  return (
    <PreviewContainer
      // @ts-ignore
      record={record}
      // @ts-ignore
      id={record.id + window.location.pathname}
      key={record.id + window.location.pathname}
    >
      <div id="kc-form">
        <div id="kc-form-wrapper">
          <form id="kc-form-login" onSubmit={handleSubmit}>
            <div className="body">
              <header className="header">
                <p>{portal_config.locale[locale].header_text}</p>
              </header>
              <main className="main">
                <img
                  className="main-logo"
                  src={portal_config.logo.image}
                />
                <div className="content">
                  <h1 className="content-title">{portal_config.locale[locale].title || 'Free Wifi'}</h1>
                  <p className="content-text">{portal_config.locale[locale].content_text || ''}</p>
                  <span id="privacy-section" className={!portal_config.main_content?.legal?.privacy_policy_enabled ? "privacy-section-hidden" : 'privacy-section'}>
                    <a
                      id="privacy-link"
                      className="legal-modal-link"
                      href="#privacy_policy"
                      onClick={togglePrivacyModal}
                    >{portal_config.locale[locale].privacy_policy_name || "Privacy Policy"}</a>
                  </span>
                  <div className="accept-container">
                    <div onClick={toggleCheckbox} id="checkbox" className={`checkbox ${termsAcknowledged ? 'checked' : ''}`}>
                      <svg
                        width="12"
                        height="12"
                        fill="#FFFFFF"
                        stroke="#FFFFFF"
                        stroke-width="3"
                        viewBox="0 0 24 24"
                      >
                        <g data-name="Layer 2">
                          <g data-name="checkmark">
                            <rect width="24" height="24" opacity="0"></rect>
                            <path
                              d="M9.86 18a1 1 0 0 1-.73-.32l-4.86-5.17a1 1 0 1 1 1.46-1.37l4.12 4.39 8.41-9.2a1 1 0 1 1 1.48 1.34l-9.14 10a1 1 0 0 1-.73.33z"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="accept-text">
                      <label htmlFor="checkbox">{portal_config.locale[locale].accept_terms_text || 'Accept '}</label>
                      <a
                        id="terms-link"
                        className="legal-modal-link"
                        href="#terms_and_conditions"
                        onClick={toggleTermsModal}
                      >{portal_config.locale[locale].terms_and_conditions_name || 'Terms and Conditions'}</a>
                    </span>
                  </div>
                  <div className="${properties.kcFormGroupClass!}">
                    <input tabIndex={1} id="username" className="${properties.kcInputClass!}" name="username" value="cloudfi" type="hidden" />
                  </div>
                  <div className="${properties.kcFormGroupClass!}">
                    <input tabIndex={2} id="password" className="${properties.kcInputClass!}" name="password" type="hidden" value="cloudfi" autoComplete="off" />
                  </div>
                  <button
                    className="button connect-button"
                    disabled={!termsAcknowledged}
                    name="login"
                    id="kc-login"
                    type="submit"
                  >{portal_config.locale[locale].connect_button_label || "Connect"}</button>
                </div>
              </main>
              <footer className="footer">
                <p>{portal_config.locale[locale].footer_text || ''}</p>
              </footer>
              <div id="modal-mask" className={`modal-mask ${!termsModalVisible && !privacyModalVisible ? 'modal-hidden' : ''}`}>
                <div className="modal">
                  <div className="modal-header">
                    <h4 id="modal-title" className="modal-title">
                      {termsModalVisible ? portal_config.locale[locale].terms_and_conditions_name || 'Terms and Conditions' : ''}
                      {privacyModalVisible ? portal_config.locale[locale].privacy_policy_name || 'Privacy Policy' : ''}
                    </h4>
                    <button className="modal-close-button" onClick={closeModal}>X</button>
                  </div>
                  <div id="modal-content" className="modal-content">
                    {termsModalVisible ? portal_config.locale[locale].terms_and_conditions : ''}
                    {privacyModalVisible ? portal_config.locale[locale].privacy_policy : ''}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </PreviewContainer>
  );
};

export default Preview;
