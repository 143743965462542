import React from 'react';
import { useGetList, ListContextProvider } from 'react-admin';

// import { useSubscribeToRecordList } from '@react-admin/ra-realtime';
import { StyledDataGrid } from './ListView';


const ColonyTable = ({
    resource,
    pagination = { page: 1, perPage: 10 },
    sort = { field: 'id', order: 'desc' },
    filter = {},
    children
}: any) => {
    // tell dataProvider to subscribe to our queries, and dispatch
    // redux actions to update data
    // useSubscribeToRecordList(resource, event => {
    //     console.log('UPDATE: ', resource, event);
    //     refresh();
    // });
    const listContext = useGetList(resource, pagination, sort, filter);
    const total = Object.keys(listContext.data || {}).length;
    return (
        // provide ListContext manually to allow including multiple instances on one page,
        // for instance on the dashboard or a custom route
        <ListContextProvider
            value={{
                ...listContext,
                loaded: !!listContext.error || !listContext.loading,
                total,
                page: pagination.page,
                perPage: pagination.perPage,
                setPage: () => null,
                currentSort: sort,
                resource,
                selectedIds: []
            }}
        >
            <StyledDataGrid>
                {children}
            </StyledDataGrid>
        </ListContextProvider>
    );
}

export default ColonyTable;
