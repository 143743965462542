export const hasLocalStorage = (function() {
  try {
    localStorage.setItem('mod', 'mod');
    localStorage.removeItem('mod');
    return true;
  } catch (exception) {
    console.warn('localStorage not available');
    return false;
  }
})();

export const isBrowser = (function() {
  return typeof window !== 'undefined' && window.location;
})();
