// @ts-nocheck
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-admin';
import get from 'lodash/get';
import pure from 'recompose/pure';
import { Typography, Chip } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import compose from 'recompose/compose';

import { FieldProps, InjectedFieldProps } from './types';
import sanitizeRestProps from './sanitizeRestProps';

interface Props extends FieldProps {
  valueLabelTrue?: string;
  valueLabelFalse?: string;
}

const ActiveChip = styled(Chip)`
  && {
    position: relative;
    background-color: ${props => props.theme.palette[props.value ? 'success' : 'error'].lighter};
    border-color: ${props => props.theme.palette[props.value ? 'success' : 'error'].main};
    color: ${props => props.theme.palette[props.value ? 'success' : 'error'].main};
  }
`;

const Indicator = styled.div`
  &&& {
    height: 8px;
    width: 8px;
    background: ${props => props.theme.palette[props.value ? 'success' : 'error'].main};
    border-radius: 4px;
    margin-left: 4px;
  }
`;

export const ActiveField: FunctionComponent<Props & InjectedFieldProps & TypographyProps> = ({
  className,
  classes: classesOverride,
  emptyText,
  source,
  record = {},
  valueLabelTrue,
  valueLabelFalse,
  ...rest
}) => {
  const t = useTranslate();
  // @ts-ignore
  const value = get(record, source);

  if (value === false || value === true) {
    return (
      <Typography component="span" variant="body2" className={className} {...sanitizeRestProps(rest)}>
        <ActiveChip
          variant="outlined"
          size="small"
          value={value}
          label={value ? t('status.active') : t('status.inactive')}
          icon={<Indicator value={value} />}
        />
      </Typography>
    );
  }

  return (
    <Typography component="span" variant="body2" className={className} {...sanitizeRestProps(rest)}>
      {emptyText}
    </Typography>
  );
};

// @ts-ignore
const EnhancedActiveField = compose<Props & InjectedFieldProps & TypographyProps, Props & TypographyProps>(pure)(
  ActiveField
);

EnhancedActiveField.defaultProps = {
  addLabel: true,
};

EnhancedActiveField.displayName = 'EnhancedActiveField';

export default EnhancedActiveField;
