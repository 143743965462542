import { OpenAPIV2 } from 'openapi-types';
import { Api } from '@api-platform/api-doc-parser/lib/Api';
import handleJson, { removeTrailingSlash } from './handleJson';
import fetchQueuedRetry from '~/Admin/data/utils/fetchQueuedRetry';

export interface ParsedSwaggerDocumentation {
  api: Api;
  response: OpenAPIV2.Document;
  status: string;
}

export default function parseSwaggerDocumentation(
  entrypointUrl: string,
  auth: any
): Promise<ParsedSwaggerDocumentation> {
  entrypointUrl = removeTrailingSlash(entrypointUrl);

  const options = {
    headers: {
      Authorization: `Bearer ${auth.authToken}`,
    },
  };

  return fetchQueuedRetry(entrypointUrl, options)
    .then(({ json }) => {
      const title = json.info.title;
      const resources = handleJson(json, entrypointUrl, auth.roles);
      const baseUrl = (json.servers && json.servers[0] && json.servers[0].url) || entrypointUrl;

      return Promise.resolve({
        api: new Api(baseUrl, { title, resources }),
        response: json,
        status: json.status,
      });
    })
    .catch(err => {
      console.error('swagger fetch', err);
      return Promise.reject({ api: new Api(entrypointUrl, { resources: [] }), response: {}, status: 0 });
    });
}
