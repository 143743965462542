import React from 'react';
import StyledFrame from 'react-styled-frame';
import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    html,body, .frame-root, .frame-content {
        margin: 0;
        height: 100%;
    }
`;

interface IframePortalPropsType {
  children: any;
  resolution: any;
  orientation: string;
}

export const Portal = ({ children, resolution, orientation }: IframePortalPropsType) => (
  <StyledFrame
    initialContent='<!DOCTYPE html><html><head></head><body><div id="mountHere"></div></body></html>'
    mountTarget="#mountHere"
    style={{
      width: Array.isArray(resolution) ? resolution[orientation === 'portrait' ? 0 : 1] + 'px' : '100%',
      height: Array.isArray(resolution) ? resolution[orientation === 'portrait' ? 1 : 0] + 'px' : '100%',
      margin: 0,
      transition: 'all 0.2s',
    }}
  >
    {children}
    <GlobalStyles />
  </StyledFrame>
);

export default Portal;
