import { css } from 'styled-components'

const checkboxMixin = css`
.checkbox {
  height: 16px;
  width: 16px;
  background: #e4e9f2;
  border-radius: 4px;
  margin-right: 8px;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkbox svg {
  display: none;
}

.checkbox:hover {
  box-shadow: 0 0 0 6px #f6f7f9;
  border: 2px solid #3266ff;
  cursor: pointer;
}

.checkbox.checked {
  background: #3266ff;
}
.checkbox.checked svg {
  display: block;
}
`

export default checkboxMixin