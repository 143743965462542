import React, { useState } from 'react';
import styled from 'styled-components';
import isEqual from 'lodash.isequal';

import backwardsCompatibilityTransformer from './backwardsCompatibilityTransformer'
import Preview from './Preview';
import Menu from './Menu';

const PortalBuilderContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  background: #f9f9fc;
  height: 100%;
  width: 100%;
  display: flex;
`;

const PortalBuilder = ({ record, onSave = () => { }, ...restProps }: any) => {
  // transform old portals to new format.
  const portalRecord = backwardsCompatibilityTransformer(record);

  const [menuCollapsed, setMenuCollapsed] = useState(false);
  const [previewRecord, setPreviewRecord] = useState(portalRecord);
  const [locale, setLocale] = useState('en');

  const onSubmit = () => onSave({ version: 1, ...previewRecord });

  const handleChange = (values: any) => {
    if (!isEqual(values, previewRecord)) {
      setPreviewRecord(values);
    }
  };

  return (
    <PortalBuilderContainer>
      <Preview record={previewRecord} menuCollapsed={menuCollapsed} setMenuCollapsed={setMenuCollapsed} locale={locale} setLocale={setLocale} />
      <Menu
        {...restProps}
        record={previewRecord}
        locale={locale}
        onSave={onSubmit}
        menuCollapsed={menuCollapsed}
        setMenuCollapsed={setMenuCollapsed}
        setPreviewRecord={handleChange}
      />
    </PortalBuilderContainer>
  );
};

export default PortalBuilder;
