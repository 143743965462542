interface RecordType {
    portal_config: any;
    [key: string]: any;
}


const backwardsCompatibilityTransformer = (record: RecordType) => {
    const { portal_config } = record

    // If portal has new locale feature, just return portal
    if (portal_config.version >= 1) return record

    // Else if it's an old portal, transform it to the new format.
    let updatedConfig = {
        version: 1,
        main_content: {
            connect_button: {
                background_color: portal_config.primary || '#4269E1',
            },
            legal: {
                terms_checkbox_required: portal_config.termsCheckbox || true
            }
        },
        logo: {
            image: portal_config.logo || ''
        },
        locale: {
            en: {
                header_text: "",
                title: portal_config.title || 'Free Wifi',
                content_text: portal_config.subtitle || '',
                connect_button_label: portal_config.doLogIn || 'Connect',
                accept_terms_text: 'Accept ',
                terms_and_conditions_text: portal_config.termsTitle || 'Terms and Conditions',
                terms_and_conditions: portal_config.terms || '',
                privacy_policy_name: 'Privacy Policy',
                privacy_policy: '',
                footer_text: portal_config.footerText || '',
                and: 'and',
            },
            fr: {
                header_text: "",
                title: "WiFi gratuit",
                content_text: "",
                connect_button_label: "Connexion",
                accept_terms_text: "Accepter ",
                terms_and_conditions_name: "Modalités",
                terms_and_conditions: "",
                privacy_policy_name: "Politique de confidentialité",
                privacy_policy: "",
                footer_text: "",
                and: 'et'
            }
        }
    }

    return { ...record, portal_config: updatedConfig }
}

export default backwardsCompatibilityTransformer 
