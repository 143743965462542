import createFields from './utils/createFields';
import createInputs from './utils/createInputs';
import fetchQueuedRetry from './utils/fetchQueuedRetry';
import { parseSwaggerDocumentation } from '~/Admin/data/api-doc-parser/swagger';

export interface ApiSchemaType {
  entrypoint: string;
  resources?: any[] | null;
  title?: string | null;
}

export interface ParamsType {
  filter: {};
  pagination: {
    page: number;
    perPage: number;
  };
  sort: {
    field: string;
    order: string;
  };
  id?: string;
  target?: any;
  data?: any;
  select?: any;
}

export interface ManyParamsType extends ParamsType {
  batchRequests?: boolean;
  batchSize?: number;
  ids: string[];
}

export interface ResponseType {
  status: number;
  headers: any;
  json: any;
}

export default class DataProvider {
  specUrl: any;
  apiSchema: ApiSchemaType;
  introspecting: boolean;
  memoApi: any;
  auth: any;
  resources: any;
  cache: boolean;
  cacheDuration: number;
  dispatch: any;
  /**
   * Overrides are of format: ```
   * { [resource]: instanceof DataProvider }
   * ```
   * and are automatically used for any public method of DataProvider class.
   */
  overrides: {
    [resource: string]: DataProvider;
  };

  PARSER: any = parseSwaggerDocumentation;
  HTTP_CLIENT: any = fetchQueuedRetry;
  CREATE_METHOD = 'POST';
  READ_METHOD = 'GET';
  UPDATE_METHOD = 'PATCH';
  DELETE_METHOD = 'DELETE';
  READ_ONLY = false;

  constructor({
    specUrl,
    auth,
    overrides = {},
    cache = true,
    cacheDuration = 5 * 60 * 1000 /* 5 min */,
    dispatch,
  }: any) {
    this.specUrl = specUrl;
    this.auth = auth;
    this.overrides = overrides;
    this.cache = cache;
    this.cacheDuration = cacheDuration;
    this.dispatch = dispatch;
    this.introspecting = false;
    this.memoApi = { data: {}, customRoutes: [] };

    this._handleOverrides(overrides);
  }

  getList(resource: string, params: ManyParamsType) {
    return this._request(resource, params, 'getList');
  }

  getMany(resource: string, params: ManyParamsType) {
    return this._request(resource, params, 'getMany');
  }

  getOne(resource: string, params: ParamsType) {
    return this._request(resource, params, 'getOne');
  }

  getManyReference(resource: string, params: ParamsType) {
    return this._request(resource, params, 'getManyReference');
  }

  update(resource: string, params: ParamsType) {
    // TODO: we need to enforce this on the backend as well
    if (!this.isReadOnly(resource) && this.auth.roles.includes('editor')) {
      return this._request(resource, params, 'update');
    }
  }

  updateMany(resource: string, params: ManyParamsType) {
    if (!this.isReadOnly(resource) && this.auth.roles.includes('editor')) {
      return this._request(resource, params, 'updateMany');
    }
  }

  create(resource: string, params: ParamsType) {
    if (!this.isReadOnly(resource) && this.auth.roles.includes('editor')) {
      return this._request(resource, params, 'create');
    }
  }

  delete(resource: string, params: ParamsType) {
    if (!this.isReadOnly(resource) && this.auth.roles.includes('admin')) {
      return this._request(resource, params, 'delete');
    }
  }

  deleteMany(resource: string, params: ManyParamsType) {
    if (!this.isReadOnly(resource) && this.auth.roles.includes('admin')) {
      return this._request(resource, params, 'deleteMany');
    }
  }

  /**
   * Loads and parses external spec for later usage.
   */
  introspect() {
    if (!this.introspecting) {
      this.introspecting = true;
    } else {
      // For some reason introspect gets called 20+ times on a table but the result isn't used anywhere. :shrug:
      // If it's already introspecting, resolve with the last result.
      return Promise.resolve(this.memoApi);
    }

    return this.apiSchema
      ? Promise.resolve({ data: this.apiSchema })
      : this.PARSER(this.specUrl, this.auth)
        .then(({ api }: any) => {
          this.memoApi = { data: api, customRoutes: [] };
          this.apiSchema = api;
          this.introspecting = false;
          return { data: api, customRoutes: [] };
        })
        .catch((error: any) => {
          this.introspecting = false;
          if (error.status) {
            throw new Error(`Cannot fetch documentation: ${error.status}`);
          }
          throw error;
        });
  }

  /**
   * Build request body for create/update requests. Return value is passed to HTTP_CLIENT as body
   */
  // @ts-ignore
  buildRequestBody(resource: string, params: ParamsType | ManyParamsType, method: string) {
    return JSON.stringify(params.data);
  }

  /**
   * Build request options. Return value is passed to HTTP_CLIENT as options for request.
   * This method should handle building any/all headers, http method, and body to request.
   */
  buildRequestOptions(resource: string, params: ParamsType | ManyParamsType, method: string): any {
    if (method.startsWith('get')) {
      return {
        method: this.READ_METHOD,
        headers: new Headers({
          Authorization: `Bearer ${this.auth.authToken}`,
          'Content-Type': 'application/json',
        }),
      };
    }

    if (method.startsWith('update')) {
      return {
        method: this.UPDATE_METHOD,
        body: this.buildRequestBody(resource, params, method),
        headers: new Headers({
          Authorization: `Bearer ${this.auth.authToken}`,
          'Content-Type': 'application/json',
        }),
      };
    }

    if (method === 'create') {
      return {
        method: this.CREATE_METHOD,
        body: this.buildRequestBody(resource, params, method),
        headers: new Headers({
          Authorization: `Bearer ${this.auth.authToken}`,
          'Content-Type': 'application/json',
        }),
      };
    }

    if (method.startsWith('delete')) {
      return {
        method: this.DELETE_METHOD,
        headers: new Headers({
          Authorization: `Bearer ${this.auth.authToken}`,
          'Content-Type': 'application/json',
        }),
      };
    }
  }

  /**
   * Build request URL. Method is the DataProvider method name for current request.
   */
  // @ts-ignore
  buildUrl(resource: string, params: ParamsType | ManyParamsType, method: string) {
    throw new Error('Not Implemented');
  }

  /**
   * Transform the response from the api server.
   * `react-admin` specifies the response format here: https://marmelab.com/react-admin/DataProviders.html#response-format
   */
  // @ts-ignore
  transformResponse(resource: string, params: ParamsType | ManyParamsType, method: string, response: ResponseType) {
    const { json } = response;
    if (method.startsWith('get')) {
      let total = json.length;
      if (params.pagination) {
        // use pagination to render controls without knowing total
        const { page, perPage } = params.pagination;
        if (total < perPage) {
          // on the last page, calculate real total
          total = perPage * page + json.length;
        } else {
          // otherwise, always make total "seen + 1 page"
          total = perPage * (page + 1);
        }
      }
      // report cache validity to react-admin
      if (this.cache) {
        const validUntil = new Date();
        validUntil.setTime(validUntil.getTime() + this.cacheDuration);
        return {
          data: json,
          total,
          validUntil,
        };
      }
      return {
        data: json,
        total,
      };
    }

    if ('create' === method || method.startsWith('update')) {
      return { data: json };
    }

    if (method === 'delete') {
      return { data: { id: params.id } };
    }

    if (method === 'deleteMany') {
      return { data: { id: (params as ManyParamsType).ids } };
    }
  }

  /**
   * Creates field components for rendering in `LIST` and `SHOW` views.
   * The container's props are passed to allow custom handling in child classes.
   * We only use children if passed in default implementation.
   */
  createFields(resource: any, props: any, type: string = 'list') {
    if (props.children) return props.children;
    return createFields({ resource, type, spec: this.getSpec(resource), auth: this.auth });
  }

  /**
   * Creates input components for rendering in `CREATE` and `UPDATE` views
   * The container's props are passed to allow custom handling in child classes.
   * We only use children if passed in default implementation.
   */
  createInputs(resource: any, props: any, type: string = 'edit') {
    if (props.children) return props.children;
    return createInputs({ resource, type, spec: this.getSpec(resource), auth: this.auth });
  }

  /**
   * Retrieve spec resource mapping in easily accessible getter. Pass resource as arg to handle
   * overrides
   */
  // @ts-ignore
  getSpec(resource: string) {
    if (!this.apiSchema || !this.apiSchema.resources) return {};
    return this.apiSchema.resources.reduce((acc: any, curr: any) => {
      acc[curr.name] = curr;
      return acc;
    }, {});
  }

  /**
   * Is this dataProvider read-only? Pass resource as arg to handle overrides
   */
  // @ts-ignore
  isReadOnly(resource: string) {
    return this.READ_ONLY;
  }

  hasResource(resource: string) {
    return !!this.getSpec(resource)[resource];
  }

  protected _request(resource: string, params: ParamsType | ManyParamsType, method: string) {
    // Postgrest can max get 500 ids per request.
    // This batches up the requests in chunks
    if (
      (params as ManyParamsType).batchRequests &&
      (params as ManyParamsType).batchSize &&
      (params as ManyParamsType).ids.length > Number((params as ManyParamsType).batchSize)
    ) {
      // Array.chunk is a custom protoType added in ~/common/protoTypes.ts
      // @ts-ignore
      const chunkedIds = (params as ManyParamsType).ids.chunk(Number((params as ManyParamsType).batchSize));

      // @ts-ignore
      return (
        // @ts-ignore
        Promise.all(
          // @ts-ignore
          chunkedIds.map((idsChunk: string[]) => {
            return this.HTTP_CLIENT(
              this.buildUrl(
                resource,
                {
                  ...params,
                  ids: idsChunk,
                },
                method
              ),
              this.buildRequestOptions(resource, params, method)
            );
          })
        )
          // @ts-ignore
          .then((responses: ResponseType[]) => {
            // @ts-ignore
            let responseData: any[] = [];
            responses.forEach(response => {
              responseData = responseData.concat(response.json);
            });
            return this.transformResponse(resource, params, method, {
              ...responses[0],
              json: responseData,
            });
          })
          .catch(error => {
            console.error('DataProvider -> _request -> chunk promise error', error);
          })
      );
    }

    return this.HTTP_CLIENT(
      this.buildUrl(resource, params, method),
      this.buildRequestOptions(resource, params, method)
    ).then((response: ResponseType) => this.transformResponse(resource, params, method, response));
  }

  // need rt methods here to account for in overrides
  // @ts-ignore
  async subscribe(topic: string, cb: any) {
    throw Error('Not Implemented');
  }

  // @ts-ignore
  async unsubscribe(topic: string, subscriptionCallback: any) {
    throw Error('Not Implemented');
  }

  // @ts-ignore
  publish(topic: string, event: any) {
    throw Error('Not Implemented');
  }

  // @ts-ignore
  isRT(resource: string) {
    return false;
  }

  protected getPublicMethods() {
    return Object.getOwnPropertyNames(
      // IMPORTANT: use base class's prototype vs. dynamic to handle
      // multiple layers of inheritance
      DataProvider.prototype
      // remove "_" (private) methods as well as constructor, getter for resourceNames
    ).filter(k => !(k.startsWith('_') || ['constructor', 'resourceNames'].includes(k)));
  }

  private _handleOverrides(overrides: { [resource: string]: DataProvider }) {
    // wrap public methods with overrides
    // handle in one place to keep the rest of the code simple
    const publicMethods = this.getPublicMethods();
    // save reference just to be safe vs. dynamic lookups
    publicMethods.forEach(method => {
      // @ts-ignore
      // save original method for use in decorator
      const origMethod = this[method].bind(this);
      // @ts-ignore
      // wrap method with override capabilities
      this[method] = function (resource: string, ...args: any) {
        if (method === 'introspect') {
          // introspect should be called for all overrides
          // and main dataprovider every time
          return Promise.all(
            Object.values(overrides)
              // dedupe spec requests
              .filter(dp => dp && dp.specUrl !== this.specUrl)
              .map(dp => dp.introspect())
              .concat([origMethod()])
          ).then(resps => {
            Object.values(overrides)
              // dedupe spec requests
              .filter(dp => dp && dp.specUrl === this.specUrl)
              .forEach(dp => {
                // make sure to pass down resolved spec to overrides we've skipped
                dp.memoApi = this.memoApi;
                dp.apiSchema = this.apiSchema;
              });
            return Promise.resolve(resps);
          });
        }


        // @ts-ignore
        if (overrides[resource] && overrides[resource][method]) {
          // run overriden method for given resource
          // @ts-ignore
          return overrides[resource][method](resource, ...args);
        }

        // handle topic routing for realtime
        if (resource.startsWith('resource/')) {
          const topic = resource;
          const resourceName = resource.replace('resource/', '');
          // @ts-ignore
          if (overrides[resourceName] && overrides[resourceName][method]) {
            // @ts-ignore
            overrides[resourceName][method](topic, ...args)
          }
        }

        return origMethod(resource, ...args);
      };
    });
  }

  get resourceNames() {
    // retrieve resource names in easily accessible property
    if (!this.apiSchema || !this.apiSchema.resources) return [];
    return (
      this.apiSchema.resources
        .map(({ name }) => name)
        // automatically remove overridden resources from list
        .filter(name => !(this.overrides[name] instanceof DataProvider))
    );
  }
}
