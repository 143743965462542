import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReferenceField, TextField, BooleanField, DateField, ChipField } from 'react-admin';
import { ActiveField } from '~/ui-components/Fields';
import ResourceIcon from '~/ui-components/Icons/ResourceIcon';

interface FieldType {
  description?: string;
  id: string;
  name: string;
  range: any;
  reference: null;
  referenceTable: string;
  required: boolean;
  format?: string;
  type: string;
}

interface FieldOverridesType {
  [key: string]: {
    [key: string]: boolean;
  };
}

export const FIELD_OVERRIDES: FieldOverridesType = {
  probe: {
    remote_id: false,
  },
  company: {
    description: false,
    site_access_level_id: false,
    parent_company_uid: false,
  },
  site: {
    site_type_id: false,
    description: false,
    notes: false,
    parent_name: false,
    subscription_bundle_id: false,
  },
};

const ALWAYS_HIDDEN_FIELDS = ['search'];
const GENERIC_HIDDEN_FIELDS = [
  'created_by',
  'modified_by',
  'created_date',
  'modified_date',
  'utc_offset',
  'config',
  'allowed_company_ids',
];

const ChipIcon = styled(FontAwesomeIcon)`
  &&& {
    margin-left: 4px;
  }
`;

// @ts-ignore
export const filterFields = (field: FieldType, resource: string, auth: any, type: string) => {
  if (ALWAYS_HIDDEN_FIELDS.includes(field.name)) {
    return false;
  }
  if (auth.roles.includes('superadmin')) return true;

  if (FIELD_OVERRIDES[resource] && typeof FIELD_OVERRIDES[resource][field.name] === 'boolean') {
    return FIELD_OVERRIDES[resource][field.name];
  }

  if (GENERIC_HIDDEN_FIELDS.includes(field.name)) {
    return false;
  }

  return true;
};

const createReferenceField = ({ field, spec }: { field: FieldType; spec: any }) => {
  const { referenceTable } = field;

  if (!spec[referenceTable] || !field.name) {
    // not a valid Reference field
    return <TextField key={field.name} source={field.name} />;
  }

  // TODO: need to handle references across dataProviders
  const referenceFieldNames = spec[referenceTable].fields.map((referenceField: FieldType) => referenceField.name);
  let referenceSource = 'id'; // default source if nothing better exists.
  // if name exists on the other table use name.
  if (referenceFieldNames.includes('name')) {
    referenceSource = 'name';
  } else if (referenceFieldNames.includes('ssid_name')) {
    referenceSource = 'ssid_name';
  }

  return (
    <ReferenceField key={field.name} source={field.name} reference={referenceTable} link="show">
      <ChipField source={referenceSource} clickable icon={<ResourceIcon resource={referenceTable} />} />
    </ReferenceField>
  );
};

// @ts-ignore
export const createField = (field: FieldType, spec: any, type: string) => {
  // The id field is manually added in the List component. So it's always first.
  if (field.name === 'id') return null;

  if (field.referenceTable) {
    return createReferenceField({ field, spec });
  }

  if ((field.format && field.format.includes('enum')) || field.name === 'type') {
    return <ChipField key={field.name} source={field.name} variant="outlined" size="small" />;
  }

  if (field.name === 'created_by' || field.name === 'modified_by') {
    return (
      <ChipField
        key={field.name}
        source={field.name}
        variant="outlined"
        size="small"
        icon={<ChipIcon icon="user-circle" />}
      />
    );
  }

  if (field.name.includes('date')) {
    return (
      <DateField key={field.name} source={field.name} options={{ year: 'numeric', month: 'short', day: 'numeric' }} />
    );
  }

  if (field.name === 'active') {
    return <ActiveField key={field.name} source={field.name} />;
  }

  if (field.type === 'boolean') {
    return <BooleanField key={field.name} source={field.name} />;
  }

  return <TextField key={field.name} source={field.name} />;
};

const createFields = ({
  spec,
  resource,
  auth,
  type = 'list',
}: {
  spec: any;
  resource: string;
  auth: any;
  type?: string;
}) => {
  const { fields } = spec[resource];
  const tableFields = fields
    .filter((field: any) => filterFields(field, resource, auth, type))
    .map((field: FieldType) => createField(field, spec, type));

  if (fields.filter((field: any) => field.name === 'id').length) {
    // add id field to the beginning of the Array so it's always first.
    tableFields.unshift(<TextField key="id" source="id" cellClassName="id-cell" />);
  }
  if (resource === 'device_v3' && !auth.roles.includes('superadmin')) {
    // remove id collumn from devices table.
    tableFields.shift(<TextField key="id" source="id" cellClassName="id-cell" />);
  }
  if (resource === 'probe') {
    tableFields.push(
      <TextField key="mac_address" source="config.mac_address" />,
      <TextField key="serial_number" source="config.serial_number" />
    );
  }

  return tableFields;
};

export default createFields;
