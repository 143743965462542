import pluralize from 'pluralize';

//Capitalizes first letter of the first word
const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const resourceToWord = ({ resource, plural = false }: { resource: string; plural?: boolean }) => {
  const resourceLabel = resource.split('.').pop() || resource;
  const word = resourceLabel.replace('_v3', '').replace(/_/g, ' ');
  return capitalize(plural ? pluralize.plural(word) : word);
};

//Capitalizes every first letter of each word
const capitalizeTitle = (s: string) => {
  return s
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const resourceToTitle = ({ resource, plural = false }: { resource: string; plural?: boolean }) => {
  const resourceLabel = resource.split('.').pop() || resource;
  const word = resourceLabel.replace('_v3', '').replace(/_/g, ' ');
  return capitalizeTitle(plural ? pluralize.plural(word) : word);
};
