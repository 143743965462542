// @ts-nocheck
import cubejs from '@cubejs-client/core';
import WebSocketTransport from '@cubejs-client/ws-transport';
import { isBrowser } from '~/common/featureTests';

const API_URL = 'https://ivory-vole.gcp-northamerica-northeast1.cubecloudapp.dev/cubejs-api/v1 ';

export let cubejsApi: any;

export const initCubeJS = (authToken: string) => {
  if (!!cubejsApi) return cubejsApi;
  if (!isBrowser) return;

  // @ts-ignore
  cubejsApi = cubejs({
    transport: new WebSocketTransport({
      authorization: authToken,
      apiUrl: API_URL.replace('http', 'ws'),
      heartbeatInterval: 10,
    }),
  });

  return cubejsApi;
};