import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RouterIcon from '@material-ui/icons/Router';
import WebAssetIcon from '@material-ui/icons/WebAsset';

const StyledFAIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  font-size: 1.2rem;
`;

const ResourceIcon = ({ resource }: { resource: string }) => {
  let icon = 'th-list';

  if (resource.includes('wifi')) {
    icon = 'wifi';
  }
  if (resource.includes('dns')) {
    icon = 'server';
    // return <DnsIcon />;
  }
  if (resource.includes('device')) {
    return <RouterIcon />;
  }
  if (resource.includes('company')) {
    icon = 'building';
    // return <BusinessIcon />;
  }
  if (
    resource.includes('address') ||
    resource.includes('country') ||
    resource.includes('city') ||
    resource.includes('province') ||
    resource === 'site'
  ) {
    icon = 'map-marked-alt';
    // return <LocationOnIcon />;
  }
  if (resource.includes('captive_portal')) {
    return <WebAssetIcon />;
  }
  if (resource.includes('probe')) {
    icon = 'thermometer';
  }

  if (
    resource.includes('user') ||
    resource.includes('auth') ||
    resource.includes('account') ||
    resource.includes('contact')
  ) {
    icon = 'user';
  }

  // @ts-ignore
  return <StyledFAIcon icon={icon} />;
};

export const createResourceIcon = (resource: string) => () => {
  return <ResourceIcon resource={resource} />;
};

export default ResourceIcon;
