import React from 'react';
import styled from 'styled-components';
import { TopToolbar, useTranslate, DataProviderContext } from 'react-admin';
import ImporterCsv from './Importer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RefreshButton, ActionButton, CreateButton } from '~/ui-components/Button/ActionButtons';
import ResourceIcon from '~/ui-components/Icons/ResourceIcon';
import { Menu, MenuItem, Typography } from '@material-ui/core';
// import { resourceToWord } from '~/common/utils/resource';
import { DataProvider } from '~/Admin/data';
import Search from './Search';

const LeftContainer = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.palette.text.secondary};

  h6 {
    margin-left: 8px;
    margin-right: 16px;
  }
`;

const StyledActionContainer = styled(TopToolbar)`
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  > div {
    margin-left: 8px;
    &:first-of-type {
      margin-left: 0;
    }
  }
`;

const MenuIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const FiltersContainer = styled.div`
  .muibutton-sizesmall: {
    height: 36px;
  }
`;

const Actions = ({
  bulkActions,
  basePath,
  // currentSort,
  displayedFilters,
  // exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  setSearchQuery,
  title,
  defaultTitle,
}: any) => {
  const t = useTranslate();
  const dataProvider: DataProvider = React.useContext(DataProviderContext);
  const auth = dataProvider.auth;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleImportCSVClick = () => {
    handleClose();
    // @ts-ignore
    document.querySelector('#import-csv').click();
  };

  return (
    <StyledActionContainer className="data-grid-actions">
      <LeftContainer>
        <ResourceIcon resource={resource} />
        <Typography variant="h6">{title || defaultTitle}</Typography>
        {/* @ts-ignore object is possible null */}
        {!dataProvider.isReadOnly(resource) && auth.roles.includes('admin') && <CreateButton resource={resource} />}
      </LeftContainer>

      {/* BUG: implementing non-default search not working 😡 */}
      {/* TODO revert to default or figure out how to replace ENTIRE Layout... 🤦‍♂️ */}
      <Search setSearchQuery={setSearchQuery} resource={resource} />

      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}

      <FiltersContainer>
        {filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
          })}
      </FiltersContainer>

      <RefreshButton />
      {/* @ts-ignore object is possible null */}
      {!dataProvider.isReadOnly(resource) && auth.roles.includes('admin') && (
        <>
          <ActionButton tooltip="More Options" onClick={handleClick}>
            <FontAwesomeIcon icon="ellipsis-h" />
          </ActionButton>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleImportCSVClick}>
              <ImporterCsv resource={resource} />
              <MenuIcon icon="file-import" /> {t('action.import')} .csv
            </MenuItem>
          </Menu>
        </>
      )}
    </StyledActionContainer>
  );
};

export default Actions;
