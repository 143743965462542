import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faChevronRight,
  faEye,
  faSyncAlt,
  faEllipsisH,
  faPlus,
  faFileImport,
  faThermometer,
  faThList,
  faWifi,
  faBuilding,
  faServer,
  faMapMarkedAlt,
  faUser,
  faUserCircle,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faSyncAlt,
  faEllipsisH,
  faEye,
  faPlus,
  faFileImport,
  faThermometer,
  faThList,
  faWifi,
  faBuilding,
  faServer,
  faMapMarkedAlt,
  faUser,
  faUserCircle,
  faChevronLeft,
  faChevronRight,
  faSearch
);
