import { css } from 'styled-components'

const logoMixin = css`
.main-logo {
  max-height: ${(p: any) => p.record.portal_config.logo?.max_height || 60}px;
  max-width: 480px;
  width: auto;
  margin-bottom: 24px;
}
`

export default logoMixin