import React from 'react';
import styled from 'styled-components';
import { FormDataConsumer, useTranslate } from 'react-admin';
import StyleTab from './Tabs/Style';
import ContentTab from './Tabs/Content';
import PoliciesTab from './Tabs/Policies';
import SettingsTab from './Tabs/Settings';
import PortalToolbar from './Toolbar';
import FormTab from './FormTab';
import TabbedForm from './TabbedForm';

const StyledTabbedForm = styled(TabbedForm)`
  height: 100%;

  > div {
    padding: 0;
  }

  > div:nth-of-type(2) {
    height: calc(100% - 113px);
    overflow-y: scroll;
  }

  .form-tab {
    width: calc(100% / 3);
    min-width: auto;
  }
`;

const MenuContainer = styled.div`
  display: block;
  position: relative;
  background: white;
  width: 400px;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.12);
  box-shadow: ${props => props.theme.shadows[1]};
  .toolbar {
    position: absolute;
    background: white;
  }

  margin-left: auto;
  height: 100%;

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const MenuOverlay = styled.div`
  @media (max-width: 1040px) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    background: rgba(0, 0, 0, 0.3);
  }
`;

interface PortalBuilderMenuProps {
  onCancel: () => void;
  onSave?: () => any;
  menuCollapsed?: boolean;
  setMenuCollapsed: (arg: boolean) => void;
  setPreviewRecord: (arg: any) => any;
  [key: string]: any;
  wifiServiceId?: number;
  locale: string;
}

const PortalBuilderMenu = ({
  onCancel,
  wifiServiceId,
  onSave = () => { },
  menuCollapsed,
  setMenuCollapsed,
  setPreviewRecord = () => { },
  locale,
  ...props
}: PortalBuilderMenuProps) => {
  const t = useTranslate();
  if (menuCollapsed) return null;

  const handleOverlayClick = (e: any) => {
    e.preventDefault();
    if (e.target.id === 'menu-overlay') {
      setMenuCollapsed(true);
    }
  };

  return (
    <MenuOverlay onClick={handleOverlayClick} id="menu-overlay">
      <MenuContainer>
        <StyledTabbedForm
          {...props}
          toolbar={<PortalToolbar onSave={onSave} setMenuCollapsed={setMenuCollapsed} onCancel={onCancel} />}
        >
          {/* @ts-ignore */}
          <FormTab label={t('common.settings')} className="form-tab">
            <FormDataConsumer>{({ formData }: any) => setPreviewRecord(formData)}</FormDataConsumer>
            <SettingsTab wifiServiceId={wifiServiceId} />
          </FormTab>
          {/* @ts-ignore */}
          <FormTab label={t('cloudFi.portal_builder.settings.policies')} className="form-tab">
            <PoliciesTab />
          </FormTab>
          {/* @ts-ignore */}
          <FormTab label={t('common.content')} className="form-tab">
            <ContentTab locale={locale} />
          </FormTab>
          {/* @ts-ignore */}
          <FormTab label={t('common.style')} className="form-tab">
            <StyleTab />
          </FormTab>
        </StyledTabbedForm>
      </MenuContainer>
    </MenuOverlay>
  );
};

export default PortalBuilderMenu;
