import React from 'react';
import { DataProviderContext, useTranslate } from 'react-admin';
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { useDebouncedCallback } from 'use-debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataProvider } from '~/Admin/data';

const StyledTextField = styled(TextField)`
  && {
    .MuiFilledInput-input {
      padding: 4px 12px;
    }
    .MuiInputLabel-root {
      display: none;
    }
    .MuiInputBase-adornedStart {
      padding-left: 0;
      svg {
        position: absolute;
        left: 8px;
        color: ${props => props.theme.palette.grey[400]};
        font-size: 0.9rem;
      }
    }
    .MuiFilledInput-inputAdornedStart {
      padding-left: 28px;
    }
  }
`;

const isSearchableField = (field: any) => {
  if (field.name === 'created_at') return false;
  if (field.name === 'modified_at') return false;
  if (field.name === 'created_by') return false;
  if (field.name === 'modified_by') return false;

  if (field.format === 'text') return true;
  if (field.format === 'character varying' && field.type === 'string') return true;

  return false;
};

const Search = ({ setSearchQuery, resource }: any) => {
  const t = useTranslate();
  const dataProvider: DataProvider = React.useContext(DataProviderContext);
  const spec = dataProvider.getSpec(resource);
  const fields = spec[resource].fields;
  const searchableFields = fields.filter((field: string) => isSearchableField(field)).map((field: any) => field.name);

  const [debouncedCallback] = useDebouncedCallback(searchTerm => {
    if (searchableFields.length) {
      setSearchQuery({
        search: {
          fields: searchableFields,
          searchTerm,
        },
      });
    }
  }, 300);

  return (
    <StyledTextField
      variant="filled"
      label={t('action.search')}
      onChange={e => debouncedCallback(e.target.value)}
      placeholder={t('action.search')}
      InputProps={{
        startAdornment: <FontAwesomeIcon icon="search" />,
      }}
    />
  );
};

export default Search;
