import React from 'react';
// need to access context vs. useDataProvider as "use" returns a proxy
import { Edit, DataProviderContext, SimpleForm } from 'react-admin';
import { Typography } from '@material-ui/core';
import { resourceToWord } from '~/common/utils/resource';
import styled from 'styled-components';

import { RealTimeEdit } from '@react-admin/ra-realtime';
import { DataProvider } from '~/Admin/data';

const StyledHeader = styled(Typography)`
  text-transform: uppercase;
`;

const Header = ({ resource }: any) => (
  <StyledHeader variant="h6">{resourceToWord({ resource, plural: false })}</StyledHeader>
);

const EditView = ({ resource, undoable = false, ...props }: any) => {
  const dataProvider: DataProvider = React.useContext(DataProviderContext);
  let EditController = Edit;
  if (dataProvider.isRT(resource)) {
    EditController = RealTimeEdit;
  }
  return (
    <EditController resource={resource} undoable={undoable} {...props}>
      <SimpleForm>
        <Header resource={resource} />
        {dataProvider.createInputs(resource, props)}
      </SimpleForm>
    </EditController>
  );
};

export default EditView;
