import React from 'react';
import styled from 'styled-components';
import { SaveButton, Toolbar, useTranslate } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core';
// TODO replace with FontAwesome
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import CloseIcon from '@material-ui/icons/Close';
import useWindowSize from '~/common/hooks/useWindowSize';

const StyledToolbar = styled(Toolbar)`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background: white;
  display: flex;
  justify-content: center;
  button {
    margin: 0 8px;
  }
`;

const CloseMenuButtonContainer = styled.div`
  display: none;
  @media (max-width: 1040px) {
    display: block;
  }
`;

interface PortalToolbarPropsType {
  onCancel: () => void;
  setMenuCollapsed: (arg0: boolean) => void;
  [key: string]: any;
}

const sanitizeToolbarProps = ({ basePath, record, resource, saving, undoable, ...props }: any) => props;

const PortalToolbar = ({ onCancel, setMenuCollapsed, onSave, ...props }: PortalToolbarPropsType) => {
  const t = useTranslate();
  // @ts-ignore
  const size: { width: number } = useWindowSize();

  return (
    <StyledToolbar {...sanitizeToolbarProps(props)}>
      <SaveButton
        onClick={onSave}
        label={t('action.save')}
        redirect="list"
        size={size.width > 400 ? 'large' : 'medium'}
        undoable={false}
      />
      <Button
        aria-label={t('action.cancel')}
        variant="outlined"
        color="primary"
        onClick={onCancel}
        size={size.width > 400 ? 'large' : 'medium'}
      >
        <CloseIcon /> {t('action.cancel')}
      </Button>

      <CloseMenuButtonContainer>
        <Tooltip placement="top" title="Toggle Menu" arrow>
          <Button
            aria-label={t('common.menu')}
            variant="outlined"
            color="primary"
            size={size.width > 400 ? 'large' : 'medium'}
            onClick={() => setMenuCollapsed(true)}
          >
            <MenuOpenIcon /> {t('common.menu')}
          </Button>
        </Tooltip>
      </CloseMenuButtonContainer>
    </StyledToolbar>
  );
};

export default PortalToolbar;
