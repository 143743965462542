import React from 'react';
import styled from 'styled-components';
import { AppBar, UserMenu } from 'react-admin';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';

const StyledAppBar = styled(AppBar)`
  && {
    background: ${props => props.theme.palette.common.white};
    box-shadow: ${props => props.theme.shadows[1]};
  }
`;

const Spacing = styled.span`
  flex: 1;
`;

const Logo = styled.img`
  height: 100%;
  max-height: 30px;
  margin-left: 16px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const MyAppBar = (props: any) => {
  const theme = useTheme();

  // @ts-ignore - logos doesn't exist on Type 'Theme'
  const logoUrl = theme.logos.horizontal;

  return (
    <StyledAppBar {...props} userMenu={<UserMenu />} color="inherit">
      <Toolbar>
        <Typography id="react-admin-title" />
      </Toolbar>
      <Logo src="https://colony.go.colonynetworks.com/static/colony_logo_horizontal.svg" />
      <Spacing />
    </StyledAppBar>
  );
};

export default MyAppBar;
