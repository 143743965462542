// @ts-nocheck
import memoize from 'memoizee';
import queryString from 'query-string';
// lz-string for huuuuuge/fast url state: https://pieroxy.net/blog/pages/lz-string/index.html
import LZString from 'lz-string';
import omit from 'lodash.omit';

import { compare } from 'fast-json-patch';

import { Field } from '@api-platform/api-doc-parser/lib/Field';
import { Resource } from '@api-platform/api-doc-parser/lib/Resource';

import { ParamsType, ManyParamsType } from './DataProvider';

import RealtimeDataProvider from './RealtimeDataProvider';
import { format, parse, subHours } from 'date-fns';

import timezone from './masterTimezone';


const formatDate = date => format(date, 'yyyy-MM-dd');
const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
const cubeTimezone = timezone[userTimezone] ? timezone[userTimezone]["master_timezone"] : "UTC"

const defaultGlobalFilter = {
  dateRange: {
    query: [formatDate(subHours(new Date(), 24)), formatDate(new Date())]
  },
  map: null,
  value: null,
  deviceType: 'primary',
  filterType: 'company_id',
  site_id: null,
  company_id: null
};

const REFERENCE_DIMENSION_MAP = {
  allowed_company_id: 'company',
  company_id: 'company',
  site_id: 'site',
  device_id: 'device',
};

const RESOURCE_NAME_CUBE_MAP = {
  events: 'AlertsHistory',
};
// inverted as well
const CUBE_RESOURCE_NAME_MAP = Object.keys(RESOURCE_NAME_CUBE_MAP).reduce((ret, key) => {
  ret[RESOURCE_NAME_CUBE_MAP[key]] = key;
  return ret;
}, {});

// TODO: date filters
const handleFilters = (cube, { site_id, company_id, device_id }) => {
  if (company_id) {
    company_id = Array.isArray(company_id) ? company_id : [company_id];
  }
  if (site_id) {
    site_id = Array.isArray(site_id) ? site_id : [site_id];
  }
  if (device_id) {
    device_id = Array.isArray(device_id) ? device_id : [device_id];
  }

  return [
    site_id && site_id.length
      ? {
        dimension: `${cube}.site_id`,
        operator: 'equals',
        values: site_id.map(s => String(s))
      }
      : null,
    device_id && device_id.length
      ? {
        dimension: `${cube}.device_id`,
        operator: 'equals',
        values: device_id.map(s => String(s))
      }
      : null,
    company_id && company_id.length
      ? {
        dimension: `${cube}.allowed_company_id`,
        operator: 'contains',
        values: company_id.map(c => `|${c}|`)
      }
      : null
  ].filter(_ => _);
};

export const removeColonyFilters = filter => omit(filter, Object.keys(filter).filter(k => k.startsWith('$')));

// TODO: initialize cube here? do we even need the Provider component?
export default class CubejsDataProvider extends RealtimeDataProvider {
  READ_ONLY = true;

  constructor({ cubeApi, ...config }) {
    super(config);
    this.cubeApi = cubeApi;
    // track subscriptions per resource to retroactively unsubscribe later
    this.subscriptions = {};
    // manage callbacks per-resource, but correlate based on query
    this.callbacks = {};
    // save query responses for diffing purposes
    this.queryResponses = {}
    // make sure we don't try to dedupe this as there is no spec url
    this.specUrl = 'https://cubejs.colonynetworks.com';
  }

  // async subscribe(topic, cb) {
  //   console.log('SUBSCRIBE', topic, cb);
  //   const resource = topic.replace('resource/', '');
  //   this.subscriptions[resource] = this.subscriptions[resource] || []
  //   this.callbacks[resource] = this.callbacks[resource] || []
  //   this.callbacks[resource].push(cb)
  //   return Promise.resolve({ data: null });
  // }

  // async unsubscribe(topic) {
  //   console.log('UNSUBSCRIBE', topic);

  //   const resource = topic.replace('resource/', '');
  //   // unset attributes
  //   this.callbacks[resource] = undefined;
  //   if (this.subscriptions[resource]) {
  //     // unsubscribe on cubejs side
  //     this.subscriptions[resource].map(sub => sub.unsubscribe())
  //     this.subscriptions[resource] = undefined;
  //   }
  //   return Promise.resolve({ data: null });
  // }

  isRT() {
    // TODO: problems with (un)subscribe methods
    return false;
  }

  protected _mapResource(resource) {
    return RESOURCE_NAME_CUBE_MAP[resource] || resource;
  }

  private _buildQuery(resource: string, params: ParamsType | ManyParamsType, method: string) {
    resource = this._mapResource(resource);
    const { dimensions } = this.schema.cubesMap[resource];
    const defaultTimeDim = this.schema.defaultTimeDimensionNameFor(resource);

    const pagination = params.pagination ? params.pagination : { perPage: 10, page: 1 };
    const sort = params.sort ? params.sort : { field: undefined, order: undefined };
    const { $measures, $dimensions, $colony } = params.filter;
    const filter = params.filter ? removeColonyFilters(params.filter) : {};

    const { page, perPage } = pagination;
    const { field, order } = sort;

  
    const globalFilters =
      ($colony && $colony.global) || defaultGlobalFilter;
    const dateRange = globalFilters.dateRange || defaultGlobalFilter.dateRange;    
    const query = {
      measures: $measures ? $measures.map(m => `${resource}.${m}`) : [],
      timeDimensions: [
        dateRange.query && defaultTimeDim
          ? {
            dimension: defaultTimeDim,
            dateRange: dateRange.query
          }
          : null
      ].filter(_ => _),
      dimensions: $dimensions
        ? $dimensions.map(dim => `${resource}.${dim}`)
        : Object.keys(dimensions),
      filters: Object.keys(filter)
        .filter(k => filter[k] && k !== 'search')
        .map(dim => ({
          dimension: `${resource}.${dim}`,
          operator:
            dimensions[`${resource}.${dim}`].type === 'string'
              ? 'equals'
              : // TODO: fuzzy for strings, equals otherwise, contains doesn't exact match
              // ? 'contains'
              'equals',
          values: [String(filter[dim])] // always strings
        }))
        // add in any global filters
        .concat(handleFilters(resource, globalFilters)),
      limit: isNaN(perPage) ? undefined : perPage,
      offset: (page - 1) * perPage,
      order:
        field && order
          ? { [`${resource}.${field}`]: order.toLowerCase() }
          : {
            [defaultTimeDim]: 'desc'
          },
      timezone: cubeTimezone,
      ungrouped: !$measures || !$measures.length,
      renewQuery: false
    };
    return query;
  }

  async _transformResponse(resource: string, params: ParamsType | ManyParamsType, method: string, resultSet: any) {
    const filter = params.filter || {};
    
    const validUntil = new Date(resultSet.loadResponse.lastRefreshTime);
    validUntil.setTime(validUntil.getTime() + this.cacheDuration);
    
    // if count measure, use that for totals
    let total;
    const { measures } = this.schema.cubesMap[this._mapResource(resource)];
    const countMeasure = Object.values(measures || {}).find(
      ({ aggType }) => aggType === 'count'
      );

    if (filter.$total) {
      // if explicit total passed, use that vs. any other logic
      total = filter.$total;
    } else if (countMeasure) {
      const query = this._buildQuery(resource, params, method);
      const totalQuery = Object.assign({}, query);
      totalQuery.dimensions = []; // no dimensions, but keep rest of query to make sure proper filters
      totalQuery.measures = [countMeasure.name];
      totalQuery.ungrouped = false; // make sure we group
      totalQuery.limit = undefined; // no paging here as well
      totalQuery.offset = undefined;
      totalQuery.order = undefined;
      const totalResults = await this.cubeApi.load(totalQuery);
      if (
        totalResults.loadResponse &&
        totalResults.loadResponse.data &&
        totalResults.loadResponse.data.length &&
        totalResults.loadResponse.data[0][countMeasure.name]
      ) {
        total = totalResults.loadResponse.data[0][countMeasure.name];
      }
    } else {
      // by default, always one more page until data is less than limit
      total =
        data.length < query.limit
          ? data.length + query.offset + 1
          : query.limit * 2 + query.offset;
    }
    // get react-admin data format from cube resultSet
    const data = resultSet.loadResponse.data
      .map(row =>
        // remove resource prefix from keys
        Object.keys(row).reduce(
          (acc, k) => ({
            ...acc,
            [k.split('.')[1]]: row[k]
          }),
          {}
        )
      )
      .map(row => ({
        ...row,
        id: row.id || LZString.compressToEncodedURIComponent(JSON.stringify(row))
      }));

    return {
      data,
      total,
      validUntil
    };
  }

  private _subscribeToQuery(resource, params, method) {
    const query = this._buildQuery(resource, params, method);
    return this.cubeApi.load(query, {
      subscribe: true,
      progressCallback: (...args) => console.log("Query progress: ", resource, query, args)
    }, async (err, resultSet) => {
      if (this.callbacks[resource]) {
        // make sure we don't trigger total requests here
        let previousIds = [];
        if (this.queryResponses[query] && this.queryResponses[query].data) {
          previousIds = this.queryResponses[query].data.map(({ id }) => id);
        }
        const response = await this._transformResponse(resource, { ...params, $total: 1 }, method, resultSet);
        this.queryResponses[query] = response;
        const ids = response.data.map(({ id }) => id);
        const jsonPatch = compare(previousIds, ids);
        // fast-compare arrays with jsonpatch, then map to react-admin change format
        // ignore creates/deletes for now as not really how ra-realtime works anyways
        const event = {
          topic: `resource/${resource}`,
          type: 'modified',
          payload: {
            ids: jsonPatch
              .filter(({ op }) => op === 'replace')
              .map(({ value }) => value)
          },
          date: new Date(),
        };
        this.callbacks[resource].forEach(cb => cb(event));
      }
    });
  }


  // using our own _request as isn't RESTful
  protected async _request(resource, params, method) {
    const query = this._buildQuery(resource, params, method);
    const resultSet = await this.cubeApi.load(query);

    if (this.subscriptions[resource]) {
      this.subscriptions[resource].push(
        this._subscribeToQuery(resource, params, method)
      );
    }
    const response = await this._transformResponse(resource, params, method, resultSet);
    return response;
  }

  // @ts-ignore
  getSpec(resource: string) {
    if (!this.schema || !this.cubeApi) return {};
    // only parse once per api instance
    if (!this.cubeApi.parsedSpec) {
      // TODO: parse schema using api-doc-parser
      const resources = Object.keys(this.schema.cubesMap).map(resource => {
        const { dimensions } = this.schema.cubesMap[resource];
        const fields = Object.values(dimensions).map(
          ({ name, title, type }) =>
            new Field(name.split('.')[1], {
              reference: null, // the default reference only works with Hydra.
              // @ts-ignore
              referenceTable: REFERENCE_DIMENSION_MAP[name.split('.')[1]],
              required: false, // never required
              type: type.replace('number', 'integer'), // make sure we swap out number for integer type
              description: title,
            })
        );
        return new Resource(resource, `${this.specUrl}/${resource}`, {
          id: null,
          title: resource,
          fields: fields,
          readableFields: fields,
          writableFields: [], // read only dataProvider
          parameters: [],
          getParameters: () => {
            return Promise.resolve();
          },
        });
      });
      this.cubeApi.parsedSpec = resources.reduce((acc: any, curr: any) => {
        // map back to nice resource name
        acc[CUBE_RESOURCE_NAME_MAP[curr.name] || curr.name] = curr;
        return acc;
      }, {});
    }
    return this.cubeApi.parsedSpec;
  }

  // override introspect as won't be parsed with api-doc-parser
  async introspect() {
    if (!this.schema && !this.introspecting) {
      this.introspecting = true;
      this.schema = await this.cubeApi.meta();
    }
    return Promise.resolve({ data: null });
  }
}
