// @ts-nocheck
import React, { useState } from 'react';
import styled from 'styled-components';
import { NumberInput, minValue, maxValue, number, useTranslate } from 'react-admin';
import CollapseableSection from '../CollapseableSection';
import { TabContainer, InputsContainer } from './styles';
import { ByteInput } from '~/ui-components';

const StyledNumberInput = styled(NumberInput)`
  text-transform: capitalize;
`;

const PoliciesTab = React.memo(() => {
  const t = useTranslate();
  const [openSections, setOpenSections]: any = useState({
    speed_limits: true,
    timeouts: false,
    data_limits: false,
  });

  const handleExpandToggle = (type: string) => {
    const newSections = { ...openSections };
    newSections[type] = !openSections[type];
    setOpenSections(newSections);
  };

  return (
    <TabContainer>
      <CollapseableSection
        open={openSections.speed_limits}
        label={t('cloudFi.portal_builder.policies.speed_limits')}
        id="speed_limits"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <ByteInput
            label={t('cloudFi.portal_builder.policies.bandwidth_upstream')}
            name="radius_policy.login_tier.free.bandwidth_up"
            source="radius_policy.login_tier.free.bandwidth_up"
          />
          <ByteInput
            label={t('cloudFi.portal_builder.policies.bandwidth_downstream')}
            name="radius_policy.login_tier.free.bandwidth_down"
            source="radius_policy.login_tier.free.bandwidth_down"
          />
        </InputsContainer>
      </CollapseableSection>
      {/* <CollapseableSection
        open={openSections.data_limits}
        label={t('cloudFi.portal_builder.policies.data_limits')}
        id="data_limits"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <ByteInput
            label={t('cloudFi.portal_builder.policies.data_budget_per_session')}
            name="radius_policy.login_tier.free.bytes_per_session"
            source="radius_policy.login_tier.free.bytes_per_session"
            validate={[minValue(0), number()]}
            suffix="Megabytes"
          />
        </InputsContainer>
      </CollapseableSection> */}
      <CollapseableSection
        open={openSections.timeouts}
        label={t('cloudFi.portal_builder.policies.timeouts')}
        id="timeouts"
        handleToggle={handleExpandToggle}
      >
        <InputsContainer>
          <StyledNumberInput
            label={t('cloudFi.portal_builder.policies.idle_timeout')}
            helperText={t('time.second', { smart_count: 2 })}
            source={`radius_policy.login_tier.free.idle_timeout`}
            validate={[minValue(0), maxValue(7200), number()]}
            initialValue="300"
          />
          <StyledNumberInput
            label={t('cloudFi.portal_builder.policies.session_timeout')}
            helperText={t('time.second', { smart_count: 2 })}
            source={`radius_policy.login_tier.free.session_timeout`}
            validate={[minValue(0), number()]}
            initialValue="3600"
          />
        </InputsContainer>
      </CollapseableSection>
    </TabContainer>
  );
});

export default PoliciesTab;
