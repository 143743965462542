import React from 'react';
import styled from 'styled-components';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  width: 100%;
  border-top: 1px solid #e6eaf3;
  border-bottom: ${(props: any) => (props.open ? 1 : 0)}px solid #e6eaf3;
  font-weight: 500;
  color: #77829a;
  background: #f9f9f9;

  &:hover {
    cursor: pointer;
    background: #f5f5f5;
  }
`;

const Label = styled.span`
  text-transform: capitalize;
`;

interface CollapseableSectionPropsType {
  open?: boolean;
  label: string;
  id: string | number;
  handleToggle: (type: string | number) => void;
  children: any;
}

const CollapseableSection = ({ open, label, id, handleToggle, children }: CollapseableSectionPropsType) => (
  <>
    <Section
      onClick={() => handleToggle(id)}
      // @ts-ignore
      open={open}
    >
      <Label>{label}</Label>
      {open ? <ExpandLess /> : <ExpandMore />}
    </Section>
    <Collapse in={open}>{children}</Collapse>
  </>
);

export default CollapseableSection;
