import React from 'react';
// need to access context vs. useDataProvider as "use" returns a proxy
import { Create, SimpleForm, DataProviderContext } from 'react-admin';
import { Typography } from '@material-ui/core';
import { resourceToWord } from '~/common/utils/resource';
import { DataProvider } from '~/Admin/data';
import styled from 'styled-components';

const StyledHeader = styled(Typography)`
  text-transform: uppercase;
`;

const Header = ({ resource }: any) => (
  <StyledHeader variant="h6">{resourceToWord({ resource, plural: false })}</StyledHeader>
);

const CreateView = ({ resource, ...props }: any) => {
  const dataProvider: DataProvider = React.useContext(DataProviderContext);

  return (
    <Create resource={resource} {...props}>
      <SimpleForm>
        <Header resource={resource} />
        {dataProvider.createInputs(resource, props, 'create')}
      </SimpleForm>
    </Create>
  );
};

export default CreateView;
