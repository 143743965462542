interface Array<T> {
  chunk(value: number): any[];
}

// Array.chunk(3) splits an array into chunks of 3 elements per array
Object.defineProperty(Array.prototype, 'chunk', {
  value: function(chunkSize: number) {
    var R = [];
    for (var i = 0; i < this.length; i += chunkSize) R.push(this.slice(i, i + chunkSize));
    return R;
  },
  configurable: true,
});
