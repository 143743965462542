import { css } from 'styled-components'

const headerMixin = css`
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 32px;
    height: ${(p: any) => p.record.portal_config.header?.height || 100}px;
    color: ${(p: any) => p.record.portal_config.header?.color || 'inherit'};
    font-family: ${(p: any) => p.record.portal_config.header?.font_family || 'inherit'};
    font-size: ${(p: any) => p.record.portal_config.header?.font_size || 'inherit'}px;
    font-weight: ${(p: any) => p.record.portal_config.header?.font_weight || 'inherit'};
    background-color: ${(p: any) => p.record.portal_config.header?.background_color || 'transparent'};
    background-image: url(${(p: any) => p.record.portal_config.header?.background_image || ''});
    background-repeat: no-repeat;
    background-size: ${(p: any) => p.record.portal_config.header?.background_size || 'cover'};
    background-position: ${(p: any) => p.record.portal_config.header?.background_position || 'top center'};
  }
`

export default headerMixin