import React from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
// HACK: need to access context vs. useDataProvider as "use" returns a proxy
import { List, Datagrid, EditButton, ShowButton, DataProviderContext } from 'react-admin';
import Actions from './Actions';
import Filter from './Filter';
import Pagination from '~/ui-components/Pagination';
import { DataProvider } from '~/Admin/data';
import { RealTimeList } from '@react-admin/ra-realtime';
import { BulkDeleteButton } from 'react-admin';

const StyledList = styled(List)`
  max-width: 100%;
  overflow: auto;

  & .MuiToolbar-regular {
    min-height: 0px;
  }

  > .MuiToolbar-root {
    min-height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'action-bar'
      'filters';

    form {
      margin-top: 0;
      margin-bottom: 0;
      grid-area: filters;
      padding: 0px 8px;

      .filter-field {
        margin-bottom: 8px;
      }
    }

    .data-grid-actions {
      padding: 14px 16px;
      align-items: center;
      grid-area: action-bar;
    }

    form {
      min-height: auto;
    }

  }

  > div {
    .MuiPaper-root {
      overflow: auto;
      box-shadow: none;
      border-radius: 0;
    }
    .MuiToolbar-root {
      display: flex;
      align-items: center;
      .MuiToolbar-root {
        padding: 0;
      }
    }
  }
`;

const RealTimeStyledList = styled(RealTimeList)`
  max-width: 100%;
  overflow: auto;

  > .MuiToolbar-root {
    min-height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-areas:
      'action-bar'
      'filters';

    form {
      margin-top: 0;
      margin-bottom: 0;
      grid-area: filters;
      padding: 0px 8px;

      .filter-field {
        margin-bottom: 8px;
      }
    }

    .data-grid-actions {
      padding: 14px 16px;
      align-items: center;
      grid-area: action-bar;
    }

    form {
      min-height: auto;
    }
  }

  > div {
    .MuiPaper-root {
      overflow: auto;
      box-shadow: none;
      border-radius: 0;
    }
    .MuiToolbar-root {
      display: flex;
      align-items: center;
      .MuiToolbar-root {
        padding: 0;
      }
    }
  }
`;

export const StyledDataGrid = styled(Datagrid)`
  .MuiTableCell-root {
    padding: 0px 24px 0px 16px;
    height: 40px;
  }
  .MuiTableCell-head {
    padding: 6px 24px 6px 16px;
  }

  td {
    .MuiButtonBase-root.MuiChip-clickable {
      .MuiChip-label {
        padding-left: 8px;
      }
      &:hover {
        background-color: ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.light};
      }

      svg {
        font-size: 0.8rem;
        margin-left: 8px;
      }
    }

    .MuiButtonBase-root {
      &:hover {
        background-color: ${props => props.theme.palette.primary.main};
        color: ${props => props.theme.palette.primary.contrastText};
      }
    }

    .MuiButton-label {
      span {
        display: none;
      }
    }
  }

  td,
  th {
    border-bottom: none;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .MuiCheckbox-root {
      padding: 0;
      background-color: #f8f7fe;
      width: 18px;
      height: 18px;
      border-radius: 2px;

      svg {
        fill: #dcd9e9;
      }
      &:hover {
        background-color: #f8f7fe;
        color: ${props => props.theme.palette.primary.main};

        svg {
          fill: ${props => props.theme.palette.primary.main};
        }
      }
    }
    .Mui-checked {
      svg {
        fill: ${props => props.theme.palette.primary.main};
      }
    }
  }

  tr:nth-child(even) {
    background: ${props => props.theme.palette.grey[50]};
  }

  th {
    background: ${props => props.theme.palette.grey[150]};
    color: ${props => props.theme.palette.text.accent};
    font-weight: bold;
    text-transform: capitalize;
  }
`;

const ListView = ({ datagridProps = {}, ...props }: any) => {
  const dataProvider: DataProvider = React.useContext(DataProviderContext);
  const [searchQuery, setSearchQuery] = React.useState({});
  let ListController = StyledList;
  if (dataProvider.isRT(props.resource)) {
    ListController = RealTimeStyledList;
  }
  return (
    <Paper>
      <ListController
        {...props}
        actions={props.actions === undefined ? <Actions setSearchQuery={setSearchQuery} /> : props.actions}
        // disable delete for non-admin/read-only resources
        bulkActionButtons={!dataProvider.isReadOnly(props.resource) && dataProvider.auth.roles.includes('admin') ? <BulkDeleteButton {...props} /> : null }
        filters={props.filters === undefined ? <Filter /> : props.filters}
        pagination={props.pagination === undefined ? <Pagination /> : props.pagination}
        sort={props.sort === undefined ? { field: null, order: null } : props.sort}
        filter={searchQuery ? { ...(props.filter || {}), ...searchQuery } : props.filter || {}}
      >
        <StyledDataGrid {...{ size: 'small', ...datagridProps }}>
          {dataProvider.createFields(props.resource, props)}
          {props.hasShow ? <ShowButton /> : null}
          {!dataProvider.isReadOnly(props.resource) && dataProvider.auth.roles.includes('editor') && <EditButton />}
        </StyledDataGrid>
      </ListController>
    </Paper>
  );
};

export default ListView;
