import React from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-admin';
import { Toolbar, Menu, Button, MenuItem, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resourceToWord } from '~/common/utils/resource';
import { numberFormatter } from '~/common/utils/formatters';

const StyledToolbar = styled(Toolbar)`
  && {
    padding-right: 16px;
    padding-left: 16px;
  }
`;

const Total = styled(Typography)`
  && {
    color: ${props => props.theme.palette.text.accent};
    margin-right: auto;
    font-size: 0.9rem;
  }
`;

const PaginationButton = styled(Button)`
  && {
    transition: all 0.2s;
    height: 32px;
    min-width: 32px;
    text-transform: lowercase;
    margin-right: 8px;
    padding: 0 8px;

    &:hover {
      border-color: ${props => props.theme.palette.primary.main};
      color: ${props => props.theme.palette.primary.main};
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const SkipIcon = styled(FontAwesomeIcon)`
  height: 32px;
  margin-right: 8px;
  color: ${props => props.theme.palette.text.accent};
  font-size: 0.8rem;
`;

const generatePageNumberButtons = (page: number, numberOfPages: number) => {
  const pageNumbers: { number?: number; active?: boolean; skip?: boolean }[] = [
    { number: 1, active: page === 1, skip: false },
  ];

  if (page - 3 > 1) {
    pageNumbers.push({ skip: true, number: page - 10 });
  }
  if (page - 2 > 1) {
    pageNumbers.push({ number: page - 2, active: false });
  }
  if (page - 1 > 1) {
    pageNumbers.push({ number: page - 1, active: false });
  }
  if (page !== 1) {
    pageNumbers.push({ number: page, active: true });
  }
  if (page + 1 < numberOfPages) {
    pageNumbers.push({ number: page + 1, active: false });
  }
  if (page + 2 < numberOfPages) {
    pageNumbers.push({ number: page + 2, active: false });
  }
  if (page + 3 < numberOfPages) {
    pageNumbers.push({ skip: true, number: page + 10 });
  }

  if (page !== numberOfPages) {
    pageNumbers.push({ number: numberOfPages, active: false });
  }

  return pageNumbers;
};

const Pagination = ({ page, perPage, setPerPage, total, setPage, resource }: any) => {
  const t = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const openRowsPerPageMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetRowsPerPage = (value: number) => {
    setPerPage(value);
    handleClose();
  };

  const numberOfPages = Math.ceil(total / perPage) || 1;

  // TODO - translate

  return (
    <StyledToolbar>
      <Total variant="body1">
        {numberFormatter.format(total)} {resourceToWord({ resource, plural: total > 1 })}
      </Total>
      <PaginationButton
        variant="outlined"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={openRowsPerPageMenu}
      >
        {perPage} / {t('navigation.per_page')}
      </PaginationButton>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => handleSetRowsPerPage(10)}>10 / {t('navigation.per_page')}</MenuItem>
        <MenuItem onClick={() => handleSetRowsPerPage(25)}>25 / {t('navigation.per_page')}</MenuItem>
        <MenuItem onClick={() => handleSetRowsPerPage(50)}>50 / {t('navigation.per_page')}</MenuItem>
        <MenuItem onClick={() => handleSetRowsPerPage(100)}>100 / {t('navigation.per_page')}</MenuItem>
      </Menu>

      {page > 1 && (
        // @ts-ignore
        <PaginationButton variant="outlined" key="prev" onClick={() => setPage(page - 1)}>
          <FontAwesomeIcon icon="chevron-left" />
        </PaginationButton>
      )}

      {generatePageNumberButtons(page, numberOfPages).map(pageNumber =>
        // @ts-ignore
        pageNumber.skip ? (
          <SkipIcon icon="ellipsis-h" key={`skip-${pageNumber.number}`} />
        ) : (
          <PaginationButton
            key={pageNumber.number}
            variant="outlined"
            color={pageNumber.active ? 'primary' : 'default'}
            onClick={() => setPage(pageNumber.number)}
          >
            {pageNumber.number}
          </PaginationButton>
        )
      )}

      {page !== numberOfPages && (
        // @ts-ignore
        <PaginationButton variant="outlined" onClick={() => setPage(page + 1)}>
          <FontAwesomeIcon icon="chevron-right" />
        </PaginationButton>
      )}
    </StyledToolbar>
  );
};

export default Pagination;
