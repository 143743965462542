import { stringify } from 'query-string';
import memoize from 'memoizee';

const permissionsCheckRaw = async ({
  specUrl,
  resource,
  name,
  authToken,
  query = {},
}: {
  specUrl: string;
  resource: string;
  name: string;
  authToken: string;
  query?: any;
}) => {
  const options = {
    headers: new Headers({
      Accept: 'application/vnd.pgrst.object+json',
      'Content-Type': 'application/json',
      Prefer: 'count=exact,return=representation',
      Authorization: `Bearer ${authToken}`,
    }),
  };

  const urlQuery = {
    ...query,
    limit: 1,
  };

  const response = await fetch(`${specUrl}/${resource}?${stringify(urlQuery)}`, options);
  const hasPermission = response.status === 206 || response.status === 200;

  return {
    name,
    hasPermission,
  };
};

const permissionsCheck = memoize(permissionsCheckRaw, {
  maxAge: 5 * 60 * 1000 /* 5 min */,
  preFetch: true /* fetch in the background vs. hanging for user */,
  // cache by obj value
  normalizer: function (args) {
    // args is arguments object as accessible in memoized function
    return JSON.stringify(args[0]);
  },
});

const authProvider = (keycloak: any, specUrl: string) => ({
  login: () => Promise.resolve(),
  logout: () => {
    return keycloak.logout({
      redirectUri: process.env.IDP_HINT === 'mbh' ? 'https://mybusinesshub.rogers.com/ocpLogin/one_sign_in' : undefined,
    });
  },
  checkError: (error: any) => {
    console.error('checkError', error);
  },
  checkAuth: () => Promise.resolve(),
  // @ts-ignore
  // console.log(keycloak) || (!keycloak || !keycloak.tokenParsed ? Promise.resolve() : keycloak.updateToken(30)),
  getPermissions: async () => {
    if (!keycloak || !keycloak.tokenParsed) {
      return Promise.resolve({
        products: {},
        roles: [],
      });
    }

    const roles = keycloak.tokenParsed.realm_access.roles;
    const authToken = keycloak.idToken;

    const productPermissionsResult = await Promise.all([
      permissionsCheck({
        specUrl,
        resource: 'wifi_service_v3',
        name: 'cloudFi',
        authToken,
        query: {
          active: 'eq.true',
        },
      }),
      permissionsCheck({
        specUrl,
        resource: `dns_service`,
        name: 'secureBlock',
        authToken,
        query: {
          active: 'eq.true',
        },
      }),
      permissionsCheck({
        specUrl,
        resource: `probe`,
        name: 'sitehealthPrimary',
        authToken,
        query: { 'config->>device_type': 'eq.primary', active: 'eq.true' },
      }),
      permissionsCheck({
        specUrl,
        resource: `probe`,
        name: 'sitehealthFailover',
        authToken,
        query: {
          or: '(config->>device_type.eq.failover,config.eq.null)',
          active: 'eq.true',
        },
      }),
      permissionsCheck({
        specUrl,
        resource: `service_firewall`,
        name: 'security',
        authToken,
        query: {
          active: 'eq.true',
        },
      }),
    ]);

    const productPermissions = productPermissionsResult.reduce((acc: any, curr: any) => {
      acc[curr.name] = curr.hasPermission;
      return acc;
    }, {});

    productPermissions.signalTest = keycloak.tokenParsed.colony_has_signaltest;

    return Promise.resolve({ authToken, roles, products: productPermissions });
  },
});

export default authProvider;
