import { css } from 'styled-components'

const portalMixin = css`
#kc-header-wrapper {
  display: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  padding: 0;
  margin: 0;
  font-family: montserrat, sans-serif;
}

h1 {
  margin: 0;
}

.body {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${(p: any) => p.record.portal_config.portal?.background_color || '#f6f7f9'};
  background-image: url(${(p: any) => p.record.portal_config.portal?.background_image || ''});
  background-repeat: no-repeat;
  background-size: ${(p: any) => p.record.portal_config.portal?.background_size || 'cover'};
  background-position: ${(p: any) => p.record.portal_config.portal?.background_position || 'top center'};
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px;
  flex: 1;
  background-image: url();
}

.kc-feedback-text {
  position: absolute;
  top: 8px;
  left: 8px;
  background: #fff;
  border-radius: 4px;
  padding: 8px 16px;
  box-shadow: rgba(99, 99, 99, 0.05) 0px 2px 8px 0px;
}
`

export default portalMixin