import { stringify } from 'query-string';

import { parseOpenApi3Documentation } from './api-doc-parser/openapi3';
import DataProvider, { ParamsType, ManyParamsType } from './DataProvider';

export default class ColonyDataProvider extends DataProvider {
  PARSER = parseOpenApi3Documentation;
  UPDATE_METHOD = 'PUT';

  buildUrl(resource: string, params: ParamsType | ManyParamsType, method: string) {
    const { entrypoint } = this.apiSchema;
    let query = {};
    if (['getOne', 'update', 'delete'].includes(method)) {
      // methods targetting a single ID, no query
      return `${entrypoint}/${resource}/${params.id}`;
    }
    if (method.endsWith('Many')) {
      // methods targetting a many IDs, , no query
      const ids = (params as ManyParamsType).ids.join(',');
      query = {
        id: ids,
        select: params.select,
      };
      return `${entrypoint}/${resource}?${stringify(query)}`;
    }

    if (method === 'create') {
      return `${entrypoint}/${resource}`;
    }

    if (method === 'getList') {
      const { page, perPage } = params.pagination;
      const { search, q, $colony, ...otherFilters }: any = params.filter;
      const { field, order } = params.sort;
      query = {
        ...otherFilters,
        limit: isNaN(perPage) ? undefined : perPage,
        offset: page > 1 ? (page - 1) * perPage : undefined,
        order: field && order ? `${field}.${order.toLowerCase()}` : undefined,
        search: search || q ? `${(search || q).replace(/#/g, encodeURIComponent('#'))}*` : undefined,
      };
    }
    if (method === 'getManyReference') {
      const { page, perPage } = params.pagination;
      const { search, q, ids, $colony, ...otherFilters }: any = params.filter;
      const { field, order } = params.sort;
      query = {
        ...otherFilters,
        id: ids.join(','),
        limit: isNaN(perPage) ? undefined : perPage,
        offset: page > 1 ? (page - 1) * perPage : undefined,
        order: field && order ? `${field}.${order.toLowerCase()}` : undefined,
      };
    }
    return `${entrypoint}/${resource}?${stringify(query)}`;
  }
}
