import styled from 'styled-components';

export const TabContainer = styled.div`
  > div:first-of-type {
    border-top: none;
  }
  > div:last-of-type {
    border-bottom: 1px solid #e6eaf3;
  }

  overflow: scroll;
  height: 100%;
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 24px;
`;
