// @ts-ignore
const Keycloak: any = typeof window !== 'undefined' ? require('keycloak-js') : null;

let keycloak: any = null;
if (Keycloak) {
  // Setup Keycloak instance as needed
  // Pass initialization options as required or leave blank to load from 'keycloak.json'
  // @ts-ignore - implicit any type of Constructor
  keycloak = new Keycloak('/static/keycloak.json');
  // @ts-ignore
  window.keycloak = keycloak;
}

export default keycloak;
