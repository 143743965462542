import React from 'react';
import { Admin } from 'react-admin';
import { KeycloakProvider } from '@react-keycloak/web';
import { createBrowserHistory } from 'history';

import { init as initFeatures } from '@colonynetworks/colony-features';

import AuthContext from './keycloak/AuthContext';
import authProvider from './keycloak/authProvider';
import { initCubeJS } from './cubejs';
import { PostgrestDataProvider, initDataProvider } from './data';
import Layout from './Layout';

// @ts-ignore
import keycloak from './keycloak';
import ColonyResource from './Defaults/ColonyResource';
import Providers from './Providers';
import { LoadingScreen } from '~/ui-components';

const history = createBrowserHistory();

export interface AdminPropsType {
  specUrl: string;
  children: any;
  theme?: any;
  dataProviderType?: any;
  i18nProvider: any;
  setCubejsApi: any;
  Navbar?: (props: any) => JSX.Element;
  cache?: boolean;
  cacheDuration?: number;
}

interface TokensType {
  idToken: string;
  refreshToken?: string;
  token: string;
}

const idpHint = process.env.IDP_HINT;

const defaultI18nProvider = {
  translate: (key: string) => key,
  getLocale: () => 'en'
}

const ColonyAdmin = ({
  specUrl,
  children,
  theme,
  dataProviderType = PostgrestDataProvider,
  i18nProvider = defaultI18nProvider,
  Navbar,
  setCubejsApi = () => { },
  cache = true,
  cacheDuration = 5 * 60 * 1000 /* 5min */,
  ...restProps
}: AdminPropsType) => {
  const [cubeApi, __setCubeApi] = React.useState(null);
  const [loginCalled, setLoginCalled] = React.useState(false);
  const auth: any = React.useContext(AuthContext);
  const dataProvider = initDataProvider({
    dataProviderType,
    specUrl,
    auth,
    children,
    cache,
    cacheDuration,
    cubeApi,
  });

  const onKeycloakEvent = (event: string, error: any) => {
    if (event === 'onReady') {
      // @ts-ignore
      if (keycloak && !loginCalled && !auth.authToken) {
        // on first load, automatically attempt a keycloak login.
        // @ts-ignore
        keycloak.login({ idpHint });
        setLoginCalled(true);
      }
      // @ts-ignore
    } else if (keycloak && event === 'onTokenExpired') {
      // @ts-ignore
      keycloak.login({ idpHint });
    }
    if (error) console.error(error);
  };

  const onKeycloakTokens = (tokens: TokensType) => {
    // @ts-ignore
    if (keycloak && keycloak.tokenParsed && keycloak.tokenParsed.realm_access.roles) {
      // @ts-ignore
      auth.setRoles(keycloak.tokenParsed.realm_access.roles);
      // @ts-ignore
      auth.setUsername(keycloak.tokenParsed.preferred_username);
      // @ts-ignore
      try {
        initFeatures(keycloak.tokenParsed);
      } catch (e) {
        console.error('ERROR INITALIZING BULLET TRAIN:');
        console.error(e);
      }
    }

    const authToken = tokens.token;
    const cubejsApi = initCubeJS(authToken);
    // call passed in handler
    setCubejsApi(cubejsApi);
    // set local state
    __setCubeApi(cubejsApi);
    // @ts-ignore
    auth.setAuthToken(authToken);
  };

  // At this point in time, react-admin isn't using translations yet, so useTranslate doesn't work.
  // This is why I'm directly using the i18nProvider here for translation.
  const loading = <LoadingScreen message={i18nProvider.translate('status.page_loading')} />;

  return (
    <KeycloakProvider
      initConfig={{
        flow: 'implicit',
        onLoad: 'check-sso',
        checkLoginIframe: false,
        // enableLogging: true,
      }}
      // @ts-ignore
      keycloak={keycloak}
      LoadingComponent={loading}
      onEvent={onKeycloakEvent}
      onTokens={onKeycloakTokens}
    >
      {dataProvider ? (
        <Admin
          authProvider={authProvider(keycloak, specUrl)}
          dataProvider={dataProvider}
          i18nProvider={i18nProvider}
          theme={theme}
          layout={Layout({ Navbar })}
          history={history}
          {...restProps}
        >
          {/* IMPORTANT: "null"s  mess with react-admin, need to use arrays for no-op */}
          {children || []}
          {/* @ts-ignore */}
          {dataProvider.resourceNames.map(name => (
            <ColonyResource key={name} name={name} list={!auth.roles.includes('viewer') ? null : undefined} />
          ))}
        </Admin>
      ) : (
          loading
        )}
    </KeycloakProvider>
  );
};

const AdminWrapper = ({ themeOverrides, theme, ...props }: any) => (
  <Providers themeOverrides={themeOverrides}>
    {({ providersTheme, ...providerProps }: any) => (
      <ColonyAdmin theme={providersTheme} {...props} {...providerProps} />
    )}
  </Providers>
);

export default AdminWrapper;
