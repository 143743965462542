import React from 'react';
import styled from 'styled-components';
import WifiLoadingSpinner from './WifiLoadingSpinner';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LoadingText = styled.p`
  color: rgba(0, 0, 0, 0.65);
  font-size: 1.2rem;
  font-weight: bold;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const LoadingScreen = ({ message = '' }) => (
  <Container>
    <WifiLoadingSpinner />
    <LoadingText>{message}</LoadingText>
  </Container>
);

export default LoadingScreen;
